// formUtils.ts

import { FormValues, RatioAnalysisFormValues, RiskMatrixFormValues } from "./creditRequestInterfaces";

const getCalenderYearString = (calenderYear:boolean, FYCount: number)=>{
    return calenderYear ?
    JSON.stringify(Array.from({ length: FYCount }, (_, ind) => (
        `1 Jan ${2024 - ind} - 31 Dec ${2024 - ind}`
     ))) :
     JSON.stringify(Array.from({ length: FYCount }, (_, ind) => (
        `1 Apr ${2023 - ind} - 31 Mar ${2024 - ind}`
     )));
}

const roundToTwoDecimalPlace = (val: any) => {
      const num = parseFloat(val); // Convert string to number
      if (!isNaN(num)) {
        return num.toFixed(2); // Round to 2 decimal places
      } else {
        return val; // Return original string if conversion fails
      }   
};


const stringifyArray = (array: any[]) => {
    const roundedArray = array.map(str => roundToTwoDecimalPlace(str));
    return `[${roundedArray.join(', ')}]`;
};
    
export const formatFormData = (values: FormValues, calenderYear: boolean, FYCount: number) => {
    const {
        shareCapital,
        reserveAndSurplus,
        totalEquity,
        longTermBorrowings,
        netDeferredTaxLiabilities,
        otherLongTermLiabilities,
        totalNonCurrentLiabilities,
        shortTermBorrowings,
        tradePayables,
        otherCurrentLiabilities,
        totalCurrentLiabilities,
        inventory,
        tradeReceivables,
        cashAndBankBalances,
        shortTermLoanAndAdvances,
        otherCurrentAssets,
        totalCurrentAssets,
        totalNetFixedAssets,
        netRevenue,
        otherIncome,
        operatingProfits, 
        netProfits,

        operatingCosts,
        costOfMaterialsConsumes,
        employeeBenefitExpenses,
        financeCosts,
    } = values;

    const assessmentYearInfoString = getCalenderYearString(calenderYear, FYCount);
    return {
        financialSpreading: {
            isCalenderYearAssessment: calenderYear,
            assessmentYearInfo: assessmentYearInfoString,
            shareCapital: stringifyArray(shareCapital),
            reserveAndSurplus: stringifyArray(reserveAndSurplus),
            totalEquity: stringifyArray(totalEquity),
            longTermBorrowings: stringifyArray(longTermBorrowings),
            netDeferredTaxLiabilities: stringifyArray(netDeferredTaxLiabilities),
            otherLongTermLiabilities: stringifyArray(otherLongTermLiabilities),
            totalNonCurrentLiabilities: stringifyArray(totalNonCurrentLiabilities),
            shortTermBorrowings: stringifyArray(shortTermBorrowings),
            tradePayables: stringifyArray(tradePayables),
            otherCurrentLiabilities: stringifyArray(otherCurrentLiabilities),
            totalCurrentLiabilities: stringifyArray(totalCurrentLiabilities),
            inventory: stringifyArray(inventory),
            tradeReceivables: stringifyArray(tradeReceivables),
            cashAndBankBalances: stringifyArray(cashAndBankBalances),
            shortTermLoanAndAdvances: stringifyArray(shortTermLoanAndAdvances),
            otherCurrentAssets: stringifyArray(otherCurrentAssets),
            totalCurrentAssets: stringifyArray(totalCurrentAssets),
            totalNetFixedAssets: stringifyArray(totalNetFixedAssets),
            netRevenue: stringifyArray(netRevenue),
            otherIncome: stringifyArray(otherIncome),
            operatingProfits: stringifyArray(operatingProfits), 
            netProfits: stringifyArray(netProfits),

            // Added form fields
            operatingCosts: stringifyArray(operatingCosts),
            costOfMaterialsConsumes: stringifyArray(costOfMaterialsConsumes),
            employeeBenefitExpenses: stringifyArray(employeeBenefitExpenses),
            financeCosts: stringifyArray(financeCosts),
        }
    };
};
export const formatUpdateFormData = (values: FormValues, calenderYear: boolean, FYCount: number) => {
    const {
        shareCapital,
        reserveAndSurplus,
        totalEquity,
        longTermBorrowings,
        netDeferredTaxLiabilities,
        otherLongTermLiabilities,
        totalNonCurrentLiabilities,
        shortTermBorrowings,
        tradePayables,
        otherCurrentLiabilities,
        totalCurrentLiabilities,
        inventory,
        tradeReceivables,
        cashAndBankBalances,
        shortTermLoanAndAdvances,
        otherCurrentAssets,
        totalCurrentAssets,
        totalNetFixedAssets,
        netRevenue,
        otherIncome,
        operatingProfits, 
        netProfits,

        // Added form fields
        operatingCosts,
        costOfMaterialsConsumes,
        employeeBenefitExpenses,
        financeCosts,
    } = values;

    const assessmentYearInfoString = getCalenderYearString(calenderYear, FYCount);
    return {
     
            isCalenderYearAssessment: calenderYear,
            assessmentYearInfo: assessmentYearInfoString,
            shareCapital: stringifyArray(shareCapital),
            reserveAndSurplus: stringifyArray(reserveAndSurplus),
            totalEquity: stringifyArray(totalEquity),
            longTermBorrowings: stringifyArray(longTermBorrowings),
            netDeferredTaxLiabilities: stringifyArray(netDeferredTaxLiabilities),
            otherLongTermLiabilities: stringifyArray(otherLongTermLiabilities),
            totalNonCurrentLiabilities: stringifyArray(totalNonCurrentLiabilities),
            shortTermBorrowings: stringifyArray(shortTermBorrowings),
            tradePayables: stringifyArray(tradePayables),
            otherCurrentLiabilities: stringifyArray(otherCurrentLiabilities),
            totalCurrentLiabilities: stringifyArray(totalCurrentLiabilities),
            inventory: stringifyArray(inventory),
            tradeReceivables: stringifyArray(tradeReceivables),
            cashAndBankBalances: stringifyArray(cashAndBankBalances),
            shortTermLoanAndAdvances: stringifyArray(shortTermLoanAndAdvances),
            otherCurrentAssets: stringifyArray(otherCurrentAssets),
            totalCurrentAssets: stringifyArray(totalCurrentAssets),
            totalNetFixedAssets: stringifyArray(totalNetFixedAssets),
            netRevenue: stringifyArray(netRevenue),
            otherIncome: stringifyArray(otherIncome),
            operatingProfits: stringifyArray(operatingProfits), 
            netProfits: stringifyArray(netProfits),
        
            // Added form fields
            operatingCosts: stringifyArray(operatingCosts),
            costOfMaterialsConsumes: stringifyArray(costOfMaterialsConsumes),
            employeeBenefitExpenses: stringifyArray(employeeBenefitExpenses),
            financeCosts: stringifyArray(financeCosts),
    };
};

export const formatRatioAnalysisFormData = (values: RatioAnalysisFormValues, calenderYear: boolean, FYCount: number) => {
    const {
        revenueGrowthPercentage,
        netProfit,
        inventoryTurnoverRatio,
        tradeReceivableTurnoverRatio,
        tradePayableTurnoverRatio,
        netFixedAssets,
    } = values;

    const assessmentYearInfoString = getCalenderYearString(calenderYear, FYCount);
    return {
        ratioAnalysis: {
            isCalenderYearAssessment: calenderYear,
            assessmentYearInfo: assessmentYearInfoString,
            revenueGrowthPercentage: stringifyArray(revenueGrowthPercentage),
            netProfit: stringifyArray(netProfit),
            inventoryTurnoverRatio: stringifyArray(inventoryTurnoverRatio),
            tradeReceivableTurnoverRatio: stringifyArray(tradeReceivableTurnoverRatio),
            tradePayableTurnoverRatio: stringifyArray(tradePayableTurnoverRatio),
            netFixedAssets: stringifyArray(netFixedAssets),            
        }
    };
};

export const formatUpdateRatioAnalysisFormData = (values: RatioAnalysisFormValues, calenderYear: boolean, FYCount: number) => {
    const {
        revenueGrowthPercentage,
        netProfit,
        inventoryTurnoverRatio,
        tradeReceivableTurnoverRatio,
        tradePayableTurnoverRatio,
        netFixedAssets,
    } = values;

    const assessmentYearInfoString = getCalenderYearString(calenderYear, FYCount);
    const getFieldForYears = (values: any[]) => values.filter((val:any, ind: number)=> ind<FYCount);
    return {
        
            isCalenderYearAssessment: calenderYear,
            assessmentYearInfo: assessmentYearInfoString,
            revenueGrowthPercentage: stringifyArray(getFieldForYears(revenueGrowthPercentage)),
            netProfit: stringifyArray(getFieldForYears(netProfit)),
            inventoryTurnoverRatio: stringifyArray(getFieldForYears(inventoryTurnoverRatio)),
            tradeReceivableTurnoverRatio: stringifyArray(getFieldForYears(tradeReceivableTurnoverRatio)),
            tradePayableTurnoverRatio: stringifyArray(getFieldForYears(tradePayableTurnoverRatio)),
            netFixedAssets: stringifyArray(getFieldForYears(netFixedAssets)),            
    
    };
};

export const formatRiskMatrixFormData = (
    values: RiskMatrixFormValues
) => {
    const {
        cibilScore,
        totalLiabilities,
        totalShareholderEquity,
        debtToEquityRatio,
        totalDebtCapitalRatio,
        totalDebtAndTotalEquity,
        debtToCapitalRatio,
        totalDebtEbitda,
        ebidta,
        debtToEbidtaRatio,
        revenueCoe,
        operatingExp,
        currentDebtObligations,
        debtToServiceCoverageRatio,
        operatingIncomeEbidta,
        interestExpenses,
        interestCoverageRatio,
        currentAssets,
        currentLiabilities,
        currentRatio,
        cashFromOperations,
        capitalExpenditures,
        freeCashFlow,
    } = values;

    return {
        riskMatrix: {
            cibilScore,
            totalLiabilities,
            totalShareholderEquity,
            debtToEquityRatio,
            totalDebtCapitalRatio,
            totalDebtAndTotalEquity,
            debtToCapitalRatio,
            totalDebtEbitda,
            ebidta,
            debtToEbidtaRatio,
            revenueCoe,
            operatingExp,
            currentDebtObligations,
            debtToServiceCoverageRatio,
            operatingIncomeEbidta,
            interestExpenses,
            interestCoverageRatio,
            currentAssets,
            currentLiabilities,
            currentRatio,
            cashFromOperations,
            capitalExpenditures,
            freeCashFlow,
        }
    };
};
export const formatUpdateRiskMatrixFormData = (
    values: RiskMatrixFormValues
) => {
    const {
        cibilScore,
        totalLiabilities,
        totalShareholderEquity,
        debtToEquityRatio,
        totalDebtCapitalRatio,
        totalDebtAndTotalEquity,
        debtToCapitalRatio,
        totalDebtEbitda,
        ebidta,
        debtToEbidtaRatio,
        revenueCoe,
        operatingExp,
        currentDebtObligations,
        debtToServiceCoverageRatio,
        operatingIncomeEbidta,
        interestExpenses,
        interestCoverageRatio,
        currentAssets,
        currentLiabilities,
        currentRatio,
        cashFromOperations,
        capitalExpenditures,
        freeCashFlow,
    } = values;

    return {
        
        cibilScore: roundToTwoDecimalPlace(cibilScore),
        totalLiabilities: roundToTwoDecimalPlace(totalLiabilities),
        totalShareholderEquity: totalShareholderEquity,
        debtToEquityRatio: debtToEquityRatio,
        totalDebtCapitalRatio: totalDebtCapitalRatio,
        totalDebtAndTotalEquity: roundToTwoDecimalPlace(totalDebtAndTotalEquity),
        debtToCapitalRatio: debtToCapitalRatio,
        totalDebtEbitda: roundToTwoDecimalPlace(totalDebtEbitda),
        ebidta: roundToTwoDecimalPlace(ebidta),
        debtToEbidtaRatio: roundToTwoDecimalPlace(debtToEbidtaRatio),
        revenueCoe: roundToTwoDecimalPlace(revenueCoe),
        operatingExp: roundToTwoDecimalPlace(operatingExp),
        currentDebtObligations: roundToTwoDecimalPlace(currentDebtObligations),
        debtToServiceCoverageRatio: roundToTwoDecimalPlace(debtToServiceCoverageRatio),
        operatingIncomeEbidta: roundToTwoDecimalPlace(operatingIncomeEbidta),
        interestExpenses: roundToTwoDecimalPlace(interestExpenses),
        interestCoverageRatio: roundToTwoDecimalPlace(interestCoverageRatio),
        currentAssets: roundToTwoDecimalPlace(currentAssets),
        currentLiabilities: roundToTwoDecimalPlace(currentLiabilities),
        currentRatio: roundToTwoDecimalPlace(currentRatio),
        cashFromOperations: roundToTwoDecimalPlace(cashFromOperations),
        capitalExpenditures: roundToTwoDecimalPlace(capitalExpenditures),
        freeCashFlow: roundToTwoDecimalPlace(freeCashFlow),
        
    };
};

export const formatIndianRupee = (amount: any) => {
    // Convert amount} to string for easier manipulation
    let numStr = String(parseFloat(amount)?.toFixed(2));
    
    // Split the amount} into integer and decimal parts (if any)
    let parts = numStr.split('.');
    let integerPart = parts[0];
    let isNegative = false;
    if(integerPart[0]==='-'){
        isNegative = true;
        integerPart = integerPart.slice(1);
    }
    let decimalPart = parts.length > 1 ? '.' + parts[1] : '';
    // let decimalPart = parts.length > 1 ? '.' + getTwoSignificantDigits(parts[1]) : '';


    // Insert commas after every 3 digits from the right
    let integerFormatted = '';
    let count = 0;
    let onesCovered = false;
    for (let i = integerPart.length - 1; i >= 0; i--) {
        count++;
        integerFormatted = integerPart[i] + integerFormatted;
        
        if (onesCovered && count === 2 && i > 0) {
            integerFormatted = ',' + integerFormatted;
            count = 0;
        }
        else if(!onesCovered && count === 3 && i > 0){
            integerFormatted = ',' + integerFormatted;
            count = 0;
            onesCovered = true;
        }
    }

    // Combine integer part with decimal part and return
    const numberPart = integerFormatted + decimalPart;
    return isNegative ? '-' + numberPart : numberPart ;
}

export const formatRatioNumber = (ratio : any) => {
    return (isNaN(ratio) || !Number.isFinite(ratio) || ratio === 0) ? 0 :
        ratio % 1 === 0 ? ratio.toFixed(0) :
        ratio?.toFixed(2);
}

export const formatPercentageNumber = (percent : any) => {
    return (isNaN(percent) || !Number.isFinite(percent) || percent === 0 )? `0%` :
        percent % 1 === 0 ? `${percent?.toFixed(0)}%` :
        `${percent?.toFixed(2)}%`;
}


