import { Button, Input, Modal, Spin, Typography, Upload } from "antd";
import { ReactComponent as DocumentIcon } from '../utils/SVGs/document_icon.svg';
import { ReactComponent as CrossIcon } from '../../../../utils/icons/Cross_Icon.svg'; 
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../../../app/hooks";
import { authSelector } from "../../../../features/auth/authSlice";
import { useState } from "react";
import axios from "axios";


interface ReviseRemarkModalProps {
    remark: any;
    setRemark: (remark: string) => void;
    reviseRemarkModal: boolean;
    setReviseRemarkModal: (reviseRemarkModal: boolean) => void;
    handleRemark: () => void;
    reviseRemarkLoader: boolean;
    handleUpload: () => void;
    requirementId: any;
    setAllDocDetails: (allDocDetails:any) => void;
    allDocDetails:any;
    prospectId:any;
    allowedActionsForProspect: Set<string>;
}


const ReviseRemarkModal: React.FC<ReviseRemarkModalProps> = ({ remark, setRemark, reviseRemarkModal, setReviseRemarkModal, handleRemark, 
    reviseRemarkLoader,handleUpload,requirementId, setAllDocDetails, allDocDetails, prospectId, allowedActionsForProspect }) => {
    const [fileList, setFileList] = useState<any[]>([]);
    const auth = useAppSelector(authSelector);
    const [uploadLoader, setUploadLoader] = useState<boolean>(false);

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;
const antIconUpload = <LoadingOutlined style={{ fontSize: 16, color: "black", marginLeft: 6 }} spin />;

const handleUploadRequest = async (file : any) => {
    setUploadLoader(true);
    const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/uploadRevisionProofDoc?prospectId=${prospectId}`;
    const formData = new FormData();
    formData.append('file', file.file);
    try {
        const response = await axios.post(url, formData, {
            headers: {
                'Authorization': `Bearer ${auth.accessToken}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            console.log(response)
            if(response.status===200)
            {
                const docURL=response?.data?.DocumentUrl;
                const tempDetail={
                    fileUID:file.file.uid,
                    docURL:docURL
                }
                setAllDocDetails([...allDocDetails,tempDetail])
            }
            else
            {
                console.log("error in uploading file")
            }
            setUploadLoader(false);
        })
    } catch (error) {
        setUploadLoader(false);
        console.error('Upload error:', error);
    }
};

const handleDelete = (file:any) => {
    setUploadLoader(false);
    const tempAllDocDetail = [];
    for(let i=0; i<allDocDetails.length; i++ ){
        if(allDocDetails[i]?.fileUID!==file?.uid){
            tempAllDocDetail.push(allDocDetails[i]);
        }
    }
    setAllDocDetails(tempAllDocDetail);

    const tempFileList = [];
    for(let i=0; i<fileList.length; i++ ){
        if(fileList[i]?.uid!==file?.uid){
            tempFileList.push(fileList[i]);
        }
    }
    setFileList(tempFileList);

}

return <Modal
        title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040"}}>Revise with Remark</Typography>}
        centered
        open={reviseRemarkModal}
        afterClose={() => {setRemark(""); setFileList([])}}
        onOk={() => {setReviseRemarkModal(false); setAllDocDetails([])}}
        onCancel={() => {setReviseRemarkModal(false); setAllDocDetails([])}}
        className="preview-modal"
        footer={<>
            <div className="edit-section">
                <Button
                    danger
                    ghost
                    icon={<CrossIcon />}
                    onClick={_ => setReviseRemarkModal(false)}
                    className="button-override danger"
                    >
                    Cancel
                </Button>
                {
                    allowedActionsForProspect?.has('reviseSampleQuote') &&
                    <Button
                        type="primary"
                        disabled={remark == ""}
                        icon={reviseRemarkLoader ? <Spin indicator={antIcon} /> : <DocumentIcon />}
                        onClick={() => !reviseRemarkLoader && handleRemark()}
                        className="button-override"
                        >
                        Revise Sample Quote
                    </Button>
                }
                
            </div>
        </>}
    >
        <Typography style={{marginBottom:"10px"}}>Enter Remarks</Typography>
        <Input onChange={(e: any) => setRemark(e.target.value)} value={remark} placeholder="Enter Remark" />
        <Typography style={{margin:"10px 0px"}}>Upload Proof <span style={{color:'#B2B2B2',marginLeft:'4px'}}>(Optional)</span></Typography>
        <Upload 
            accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
            customRequest={handleUploadRequest}
            beforeUpload={(file) => {
                setFileList([...fileList, file]);
                {handleUploadRequest({file})}
                return false
            }}
            fileList={fileList}
            onRemove={handleDelete}
            listType="picture"
            multiple
        >
            <Button
            icon={uploadLoader ? <Spin indicator={antIconUpload}/> : <UploadOutlined />} 
            style={{width:"472px"}} 
            >Upload Proof</Button>
        </Upload>
    </Modal>    
}

export default ReviseRemarkModal;
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;