import { CheckOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Input, Space, Typography } from "antd"
import { useEffect, useState } from "react"
import { postRequest } from "../../../utils/handler/apiHandler"
import moment from "moment"

export default function DocumentRemarks({ docType, selectedDocType,getDocTypes,setSelectedDocType }: any) {
    const [addNewRemark, setAddNewRemark] = useState(false)
    const [remarks, setRemarks] = useState<any>(selectedDocType.remark)
    const [updating, setUpdating] = useState(false)


    const handleUpdateRemarks = async () => {
        setUpdating(true)

        const docParams = [
            {
                clientAgreementDocumentId: selectedDocType.id,
                validity: selectedDocType.validity || null, // Default or from selectedDocType
                remarks: remarks || null, // Default or from selectedDocType
                uploadedDocUrl: []
            }];
        await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/uploadAgreementDocDetails`, docParams).then((res: any) => {
            console.log(res)
            getDocTypes()
            setAddNewRemark(false)
            // setAddNewDoc(false)
            // handleShowDocs(selectedDocType.id)
        }).catch(e => {
            console.log(e)
        })
        setUpdating(false)
    }

    useEffect(()=>{
        const updatedDoc = docType.find((i:any)=>i.id == selectedDocType.id)
        setSelectedDocType(updatedDoc)
    }, [docType])
    
    return (
        addNewRemark ? <>
            <Space style={{width: "100%", textAlign: "left" }} size="large" direction="vertical">
                <Input.TextArea defaultValue={remarks} onChange={(e)=>setRemarks(e?.target?.value)} rows={4} />
                <div style={{textAlign: "right"}}>
                    <Button loading={updating} onClick={handleUpdateRemarks} disabled={remarks == "" || !remarks || updating} type="primary" icon={<CheckOutlined />}>Save</Button>
                </div>
            </Space>
        </>
            :
            <>
                {
                    !selectedDocType.remark ?
                        <Space size="large" direction="vertical">
                            <img width={255} src={`${__dirname}assets/empty-remarks.png`} />
                            <Typography style={{ color: "#616161" }}>No remarks added currently</Typography>
                            <Button onClick={() => setAddNewRemark(true)} type="primary" icon={<PlusOutlined />}>Add New Remark</Button>
                        </Space> :
                        <div style={{width: "100%", textAlign: "left"}}>
                            <div>
                            <Typography.Text strong>Remarks Added At - {moment(selectedDocType.remarksUpdatedAt).format('h:mm A, DD MMM YYYY')}</Typography.Text>
                            </div>
                            <br/>
                            <Input.TextArea style={{background: "rgb(246, 250, 255)", border: "none", color: "black"}} disabled value={selectedDocType.remark} rows={6}  />
                            
                            <div style={{textAlign: "right", marginTop: 20}}>
                                <Button onClick={() => setAddNewRemark(true)} type="primary" icon={<EditOutlined />}>Edit</Button>
                            </div>
                        </div>
                }
            </>
    )
}