import React, { useState, useEffect } from 'react';
import { useLocation} from 'react-router';
import { ColumnsType } from 'antd/es/table';
import { Table, Spin, Tag, Typography } from 'antd';
import { ReactComponent as EyeIcon } from './../../../../utils/icons/view_icon.svg';
import { ReactComponent as DownloadIcon } from '../../SVGs/download_icon.svg';
import { getRequest, handleFileDownload } from '../../../../utils/handler/apiHandler';
import dayjs from 'dayjs';
import { getFormattedDate } from '../../../../utils/helpers/utils';
import ViewSanctionDetailsModal from './ViewSanctionDetailsModal';
import currencyFormatter from '../../../../utils/NumberFormater';
import { fetchCreditAssessmentRequestTableData } from '.././utils/creditAssessmentRequestApiFunctions';
import SortUpperFadedIcon from '../../Prospect/utils/SVGs/SortUpperFadedIcon';
import SortLowerFadedIcon from '../../Prospect/utils/SVGs/SortLowerFadedIcon';
import { ReactComponent as SortFilterIcon } from './../../../../utils/icons/Vector.svg'; // Importing SVG file
import CreditHistoryView from './CreditHistoryView';
import { Tooltip } from '@mui/material';
import UnionFilterIcon from '../../SVGs/UnionFilterIcon';
import CustomSelectFilter from '../../Prospect/utils/CustomSelectFilter';

const CreditHistory: React.FC = () =>  {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const prospectId = useLocation().pathname.split("/").pop();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [sanctionData, setSanctionData] = useState<any>(null);
    const [requestedAtOrder, setRequestedAtOrder] = useState<any>(null);
    const [pageCount, setPageCount] = useState<number>(0);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [viewModal, setViewModal] = useState<boolean>(false);
    const [logId, setLogId] = useState<any>(null);

    const [selectedStatus, setSelectedStatus] = useState<any>([]);
    const [statusList, setStatusList] = useState<any>([]);


    const filterIcon = () => (
        <UnionFilterIcon fill={selectedStatus.length===0 ? '#2C2C2C' : FILTER_ICON_COLOR} style={{ marginLeft: "12px" }} />
    );

    useEffect(() => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/historyCreditRequestLog/${prospectId}`).then(res => {
            setData(res.data.data);
        });

        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/creditStatus`).then(res => {
            setStatusList(res.data);
        });
    }, []);

    useEffect(() => {
        const filterQueryString = getFilterQueryString();
        fetchCreditAssessmentRequestTableData(filterQueryString, setData, setPageCount, setTotalRecord, setLoading);
      }, [requestedAtOrder, selectedStatus]);

    useEffect(() => {
        const filterQueryString = getFilterQueryString();
        console.log(filterQueryString, data);
    }, [data]);



    const getRequestedAtOrder = () => `requestedAt:${requestedAtOrder === 'ascending' ? 'asc' : 'desc'}`;

    function getFilterQueryString(): string {
        let filterQuery = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/historyCreditRequestLog/${prospectId}?page=${currentPage-1}&size=${pageSize}`;
        let sortQuery = '&sort=';
        if(requestedAtOrder !== null){
          sortQuery += `${getRequestedAtOrder()}`
        }
        if(sortQuery!=='&sort=')
          filterQuery += `${sortQuery}`

        if(selectedStatus.length > 0){
            filterQuery += `&statusIds=${selectedStatus}`
          }

        return filterQuery;
      }

    // Filter Start
    const FILTER_ICON_COLOR = '#1777FF';
    const sortTotalInvestmentFilterIcon = () => (
        requestedAtOrder === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
          : requestedAtOrder === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
          : <SortFilterIcon  style={{ marginLeft: "12px", marginTop:'5px' }} />
      );
      
    const handleAmountOrder = () => {
        setRequestedAtOrder(requestedAtOrder === null ? 'ascending' : requestedAtOrder ==='ascending' ? 'descending' : null );
      };

    const handleViewHistory = (record: any) => {
        setViewModal(true);
        setLogId(record?.logId);
        console.log(record?.logId);
    }

    const handleDownloadSanction = (record: any) => {
        handleFileDownload(record?.sanctionDocId, `Sanction_${prospectId}`, "pdf")
    }

    const getColumns = () : ColumnsType<any> => [
        {
          dataIndex: "creditRequestId",
          key: "creditRequestId",
          title: "Requested No.",
        },
        {
            dataIndex: 'requestedAt',
            key: 'requestedAt',
            render: (value, record) => <Typography>{getFormattedDate(record?.requestedAt)}</Typography>,
            sortDirections: ['ascend', 'descend'],
            title: (
              <div onClick={handleAmountOrder} style={{ cursor: 'pointer', display:'flex' }}>
                <span style={{marginRight:'0.5rem'}}>Requested At</span> 
                {sortTotalInvestmentFilterIcon()}
              </div>
            ),
        },
        {
            title: "Requested By",
            render: (value, record) => <Typography>{record?.requestedBy && JSON.parse(record?.requestedBy)["name"]}</Typography>
        },
        {
            title: 'Status',
            dataIndex: 'statusName',
            key: 'statusName',
            render: (value, record) => <Tag style={{ color: record?.statusColor, backgroundColor: record?.statusBackgroundColor, border: 'none', fontWeight: 500 }}>{record?.statusName}</Tag>,
            width: 160,
            filterIcon: filterIcon, 
            filterDropdown: (
              <div style={{ padding: 8, width: 180 }}>
                <CustomSelectFilter
                  itemsList={statusList}
                  selectedItems={selectedStatus}
                  setSelectedItems={setSelectedStatus}
                  placeholder="Status"
                  setMainCurrentPage={setCurrentPage}
                />
              </div>
            ),
            onFilter: (value: any, record: any) => record.stage.id === value,
          },
        {
            title: "Action",
            render: (value, record) => <div>
                <Tooltip title="View">
                    <EyeIcon style={{marginRight: 10, color: "#1677Ff", cursor: "pointer"}} onClick={() => handleViewHistory(record)} />
                </Tooltip>

                <Tooltip title="Download Sanction Letter">
                    {record.sanctionDocId && <DownloadIcon onClick={() => handleDownloadSanction(record)} style={{marginRight: 10, color: "#1677ff", cursor: "pointer"}} />}
                </Tooltip>
            </div>
        },
    ];

    return ( 
        <div style={{ backgroundColor: '#F6FAFF', height: '100%'}}>
            <div>
                <Spin spinning={loading} size="large">
                    <Table
                        columns={getColumns()}
                        dataSource={data || []}
                        size="middle"
                        components={{
                        header: {
                            wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
                        },
                        }}
                    />
                </Spin>
            </div>
            <ViewSanctionDetailsModal showModal={showModal} setShowModal={setShowModal} data={sanctionData} />
            <CreditHistoryView showModal={viewModal} setShowModal={setViewModal} logId={logId} />
        </div>
    );
};

export default CreditHistory;