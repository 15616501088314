import React from 'react';
import { Result, Button } from 'antd';

const NoAccess = () => {
  return (
    <Result
      status="403"
      title="No Access"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button type="primary" onClick={() => window.history.back()}>
          Go Back
        </Button>
      }
    />
  );
};

export default NoAccess;
