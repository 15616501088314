import { useEffect, useState } from "react";
import { getRequest } from "../../../../../utils/handler/apiHandler";
import { Menu, SubMenu } from "../types";

const useActionEffects = (setLoading: (isLoading:boolean) => void)=>{
    const [menuData, setMenuData] = useState<Menu[]>([]);
    const [submenuData, setSubmenuData] = useState<SubMenu[]>([]);
    
    useEffect(() => {
        const fetchMenu = async () => {
          try {
            const res = await getRequest(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/menus/parentmenus`);
            if (res.status === 404 || res.status === 400) {
              return;
            }
            setMenuData(res.data);
          } catch (error) {
            console.error('Error fetching menus:', error);
          }
        };
    
        fetchMenu();
      }, []);

    const fetchSubmenuData = async (parentId: string) => {
        setLoading(true);
        try {
          const res = await getRequest(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/menus/getSubMenusByParentId?parentId=${parentId}`);
          if (res.status === 404 || res.status === 400) {
            setSubmenuData([]);
            setLoading(false);
            return;
          }
          const submenus = res.data;
          setSubmenuData(submenus);
         
        }  catch (error) {
          console.error('Error fetching submenu data:', error);
          setMenuData([]);
        } finally {
          setLoading(false);
        }
      };  
    
    return {
        menuData, setMenuData, submenuData, fetchSubmenuData
    }
}
export default useActionEffects;