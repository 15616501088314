import { Card, Col, Divider, Modal, Row, Tag, Typography } from "antd";
import { getRequest } from "../../../../utils/handler/apiHandler";
import { useEffect, useState } from "react";
import CreditAssessmentDetailsLayout from "./CreditAssessmentDetailsLayout";
import { convert } from 'html-to-text';
import getHtmlToText from '../../../../utils/helpers/htmlToFormattedText';
import currencyFormatter from "../../../../utils/NumberFormater";
import dayjs from "dayjs";
import CreditRequestPreview from "./CreditRequestPreview";
import CreditDetailPreview from "./CreditDetailPreview";
import getFormattedText from "../../../../utils/helpers/htmlToFormattedText";

interface CreditHistoryViewProps {
    logId: any,
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
}

const CreditHistoryView: React.FC<CreditHistoryViewProps> = ({ logId, showModal, setShowModal }) => {
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        logId && getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/viewCreditRequestLog?creditRequestLogId=${logId}`).then(res => {
            setData(res.data);
        });
    }, [logId]);

    useEffect(() => {
        console.log(data?.creditRequestData)
    }, [data]);

    return (
        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040"}}>Credit Assessment Details</Typography>}
            centered
            open={showModal}
            onOk={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
            closable={true}
            className="preview-modal"
            footer={false}
            width={1200}
        >   
        <div style={{maxHeight: 600, overflow: "scroll"}}>
           <Typography style={{fontWeight: 500}}>
            Assessment Details 
            <Tag 
                bordered={false} 
                style={{marginLeft: 10, color: data?.statusData?.statusColor, backgroundColor: data?.statusData?.statusBackgroundColor}}>
                {data?.statusData?.statusName}
            </Tag>
            </Typography>

            {
            (data && data?.statusData?.statusChangeReason) &&
            <Card style={{marginTop: 10}} bordered={false}>
                <p style={{fontWeight: 500}}>
                {data?.statusData?.statusId === 9 && "Decision Pending Remark"}
                {data?.statusData?.statusId === 5 && "Rejected Remark"}
                {data?.statusData?.statusId === 7 && "Bypass Remark"}
                {data?.statusData?.statusId === 8 && "Cancelation Remark"}
                {data?.statusData?.statusId === 10 && "Bypass Reject Remark"}
                </p>
                {getFormattedText(data?.statusData?.statusChangeReason)}
            </Card>
            }


            {
                (data?.statusData?.statusId === 6) && <Card style={{marginTop: 10}} bordered={false}>
                    <Row style={{display: "flex"}}>
                        <Col style={{marginRight: "10%"}}>
                            <Row style={{fontWeight: 500, fontSize: 12, color: "#1F1F1F"}}>Credit Limit</Row>
                            <Row>{currencyFormatter(data?.creditRequestData?.creditSanction?.creditLimit)}</Row>
                        </Col>
                        <Col style={{marginRight: "10%"}}>
                            <Row style={{fontWeight: 500, fontSize: 12, color: "#1F1F1F"}}>Refundable Security Deposit</Row>
                            <Row>{data?.creditRequestData?.creditSanction?.refundableSecurityType}</Row>
                        </Col>
                        <Col style={{marginRight: "10%"}}>
                            <Row style={{fontWeight: 500, fontSize: 12, color: "#1F1F1F"}}>LR Payment Frequency</Row>
                            <Row>{data?.creditRequestData?.creditSanction?.lrFrequencyType}</Row>
                        </Col>
                        <Col style={{marginRight: "10%"}}>
                            <Row style={{fontWeight: 500, fontSize: 12, color: "#1F1F1F"}}>Sanction Letter Validity</Row>
                            <Row>{dayjs(data?.creditRequestData?.creditSanction?.validFrom).format("DD/MM/YY")} - {dayjs(data?.creditRequestData?.creditSanction?.validTo).format("DD/MM/YY")}</Row>
                        </Col>
                    </Row>
                    <Divider style={{marginTop: 15, marginBottom: 15}} />
                    <Typography style={{fontWeight: 500, fontSize: 12, color: "#1F1F1F"}}>Credit conditions imposed</Typography>
                    <Typography>{getHtmlToText(data?.creditRequestData?.creditSanction?.creditCondition)}</Typography>
                </Card>
            }
            {data && <CreditDetailPreview data={data?.creditRequestData} />}
        </div>
        </Modal>
    );
}

export default CreditHistoryView;