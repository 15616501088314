import { Button, Col, Divider, Modal, Row, Spin, Tag, Typography } from "antd";
import { ReactComponent as CrossIcon } from '../../../../utils/icons/Cross_Icon.svg'; 
import { ReactComponent as DocumentIcon } from '../utils/SVGs/document_icon.svg';
import PreviewData from "./PreviewData";
import { LoadingOutlined } from "@ant-design/icons";
import _ from "lodash";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface PreviewRequirementModalProps {
    data: any;
    requirementPreviewModal: boolean;
    setRequirementPreviewModal: (showVersionHistoryModal: boolean) => void;
    handleRequestSampleQuote: () => void;
    requestLoader: boolean;
    allowedActionsForProspect:  Set<string>;
}
const PreviewRequirementModal: React.FC<PreviewRequirementModalProps>= ({ data, requirementPreviewModal, setRequirementPreviewModal, 
        handleRequestSampleQuote, requestLoader, allowedActionsForProspect }) => {
    return (
        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040"}}>Review Requirement</Typography>}
            centered
            open={requirementPreviewModal}
            onOk={() => setRequirementPreviewModal(false)}
            onCancel={() => setRequirementPreviewModal(false)}
            width={"70%"}
            className="preview-modal"
            footer={<>
                <div className="edit-section">
                    <Button
                        danger
                        ghost
                        icon={<CrossIcon />}
                        onClick={_ => setRequirementPreviewModal(false)}
                        className="button-override danger"
                        >
                        Cancel
                    </Button>
                    {
                        allowedActionsForProspect?.has('requestSampleQuote') && 
                        <Button
                            type="primary"
                            // ghost
                            icon={requestLoader ? <Spin indicator={antIcon} /> : <DocumentIcon />}
                            onClick={_ => !requestLoader && handleRequestSampleQuote()}
                            className="button-override"
                            >
                            Request Sample Quote
                        </Button>
                    }
                    
                </div>
            </>}
        >
        <Typography style={{marginBottom: 20}}>Review the data of this requirement before requesting sample quote.</Typography>
            <PreviewData data={data} />
            <div style={{display: "flex", flexWrap: "wrap"}}>
            {
                ((data?.accessories || data?.services) && ((_.isArray(JSON.parse(data?.accessories)) && JSON.parse(data?.accessories).length !== 0) || (_.isArray(JSON.parse(data?.services)) && JSON.parse(data?.services).length !== 0))) &&
                <Divider orientation="left" orientationMargin={0}><span style={{fontSize: 14, fontWeight: 500, margin: 0}}>Accessories & Services</span></Divider>
            }
            { (data?.accessories && JSON.parse(data?.accessories).length !== 0) && <div className="requirement-card-info" style={{width: "49%"}}>
                <Typography className="description-label">Accessories</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>
                    <span>{
                        JSON.parse(data?.accessories).map((e: any) => {
                            return <Tag style={{marginBottom: 5}}>{e.name}</Tag>
                        })
                    }</span>
                </Typography>
            </div> }
            { (data?.services && JSON.parse(data?.services).length !== 0) &&  <div className="requirement-card-info" style={{width: "49%"}}>
                <Typography className="description-label">Services</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>
                    <span>{
                        JSON.parse(data?.services).map((e: any) => {
                            return <Tag style={{marginBottom: 5}}>{e.name}</Tag>
                        })
                    }</span>
                </Typography>
            </div> }
            {data.remark && <div className="requirement-card-info" style={{width: "100%"}}>
                <Typography className="description-label">Remark</Typography>
                <Typography style={{fontSize: 14}}>{data.remark}</Typography>
            </div>}
            </div>
        </Modal>
    )
}

export default PreviewRequirementModal;