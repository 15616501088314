import { Table, Spin, Tooltip, Select, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Option } from "antd/es/mentions";
import { RequestedQuoteList } from "../QuoteUtils/QuoteListResponseType";
import { handlePageChange, handlePageSizeChange } from "../../Prospect/utils/paginationFunctions";
import { LeftOutlined, MinusOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { ReactComponent as EyeIcon } from './../../../../utils/icons/view_icon.svg'; // Importing SVG file
import { getRequest } from "../../../../utils/handler/apiHandler";
import { getFormattedDate } from "../../../../utils/helpers/utils";
import '../../../../styles/Pages.sass'
import CustomSearchSelectFilter from "../../Prospect/utils/CustomSearchSelectFilter";
import { fetchProspectNamesDataFunction,  fetchBuyingCityDataFunction, fetchOwnerNamesDataFunction, fetchRequirementDataFunction, fetchRequestNumDataFunction, fetchTenureData, fetchVariantDataFunction, fetchModelDataFunction, fetchMakeDataFunction } from "../../Prospect/utils/apiFunctions";
import { ReactComponent as SortFilterIcon } from './../../../../utils/icons/Vector.svg';
import SortUpperFadedIcon from '../../Prospect/utils/SVGs/SortUpperFadedIcon';
import SortLowerFadedIcon from '../../Prospect/utils/SVGs/SortLowerFadedIcon';
import CustomSelectFilter from "../../Prospect/utils/CustomSelectFilter";
import UnionFilterIcon from "../../SVGs/UnionFilterIcon";

const INITIAL_SEARCH_MODEL_HEIGHT: number= 100;

interface InprogressSampleQuoteListProps {
  allowedActionsForSampleQuote: Set<string>;
}

const InprogressSampleQuoteList: React.FC<InprogressSampleQuoteListProps> = ({allowedActionsForSampleQuote}) => {
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageCount, setPageCount] = useState<number>(0);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const defaultData: RequestedQuoteList[] = [];
    const [data, setData] = useState<RequestedQuoteList[]>(defaultData);
    const tableRef = useRef<HTMLDivElement>(null);
    const [tableHeight, setTableHeight] = useState<number>(600);
    const [selectedRequestNumList, setSelectedRequestNumList] = useState<any[]>([]);
    const [selectedProspectsList, setSelectedProspectsList] = useState<any[]>([]);
    const [selectedRequirementList, setSelectedRequirementList] = useState<any[]>([]);
    const [selectedOwnerList, setSelectedOwnerList] = useState<any[]>([]);
    const [selectedBuyingCityList, setSelectedBuyingCityList] = useState<any[]>([]);
    const [selectedMakeList, setSelectedMakeList] = useState<any[]>([]);
    const [selectedModelList, setSelectedModelList] = useState<any[]>([]);
    const [selectedVariantList, setSelectedVariantList] = useState<any[]>([]);
    const [displayRequestNumFilterScreen, setDisplayRequestNumFilterScreen] = useState<boolean>(false);
    const [displayProspectSearchFilterScreen, setDisplayProspectSearchFilterScreen] = useState<boolean>(false);
    const [displayRequirementFilterScreen, setDisplayRequirementFilterScreen] = useState<boolean>(false);
    const [displayProspectOwnerFilterScreen, setDisplayProspectOwnerFilterScreen] = useState<boolean>(false);
    const [displayBuyingCityFilterScreen, setDisplayBuyingCityFilterScreen] = useState<boolean>(false);
    const [displayMakeFilterScreen, setDisplayMakeFilterScreen] = useState<boolean>(false);
    const [displayModelFilterScreen, setDisplayModelFilterScreen] = useState<boolean>(false);
    const [displayVariantFilterScreen, setDisplayVariantFilterScreen] = useState<boolean>(false);
    const [displayTenureFilterScreen, setDisplayTenureFilterScreen] = useState<boolean>(false);
    const [requestNumPageSize, setRequestNumPageSize] = useState<number>(10);
    const [prospectPageSize, setProspectPageSize] = useState<number>(10);
    const [requirementPageSize, setRequirementPageSize] = useState<number>(10);
    const [ownerPageSize, setOwnerPageSize] = useState<number>(10);
    const [buyingCityPageSize, setBuyingCityPageSize] = useState<number>(10);
    const [makePageSize, setMakePageSize] = useState<number>(10);
    const [modelPageSize, setModelPageSize] = useState<number>(10);
    const [variantPageSize, setVariantPageSize] = useState<number>(10);
    const [requestNumList, setRequestNumList] = useState<any[]>([]);
    const [prospectsList, setProspectsList] = useState<any[]>([]);
    const [requirementList, setRequirementList] = useState<any[]>([]);
    const [ownersList, setOwnersList] = useState<any[]>([]);
    const [buyingCityList, setBuyingCityList] = useState<any[]>([]);
    const [makeList, setMakeList] = useState<any[]>([]);
    const [modelList, setModelList] = useState<any[]>([]);
    const [variantList, setVariantList] = useState<any[]>([]);
    const [requestNumCurrentPage, setRequestNumCurrentPage] = useState<number>(1);
    const [prospectCurrentPage, setProspectCurrentPage] = useState<number>(1);
    const [requirementCurrentPage, setRequirementCurrentPage] = useState<number>(1);
    const [ownerCurrentPage, setOwnerCurrentPage] = useState<number>(1);
    const [buyingCityCurrentPage, setBuyingCityCurrentPage] = useState<number>(1);
    const [makeCurrentPage, setMakeCurrentPage] = useState<number>(1);
    const [modelCurrentPage, setModelCurrentPage] = useState<number>(1);
    const [variantCurrentPage, setVariantCurrentPage] = useState<number>(1);
    const [requestNumPageCount, setRequestNumPageCount] = useState<number>(0);
    const [prospectPageCount, setProspectPageCount] = useState<number>(0);
    const [requirementPageCount, setRequirementPageCount] = useState<number>(0);
    const [ownerPageCount, setOwnerPageCount] = useState<number>(0);
    const [buyingCityPageCount, setBuyingCityPageCount] = useState<number>(0);
    const [makePageCount, setMakePageCount] = useState<number>(0);
    const [modelPageCount, setModelPageCount] = useState<number>(0);
    const [variantPageCount, setVariantPageCount] = useState<number>(0);
    const [requestNumModalHeight, setRequestNumModalHeight] = useState<number>(INITIAL_SEARCH_MODEL_HEIGHT);
    const [prospectModalHeight, setProspectModalHeight] = useState<number>(INITIAL_SEARCH_MODEL_HEIGHT);
    const [requirementModalHeight, setRequirementModalHeight] = useState<number>(INITIAL_SEARCH_MODEL_HEIGHT);
    const [ownerModalHeight, setOwnerModalHeight] = useState<number>(INITIAL_SEARCH_MODEL_HEIGHT);
    const [buyingCityModalHeight, setBuyingCityModalHeight] = useState<number>(INITIAL_SEARCH_MODEL_HEIGHT);
    const [makeModalHeight, setMakeModalHeight] = useState<number>(INITIAL_SEARCH_MODEL_HEIGHT);
    const [modelModalHeight, setModelModalHeight] = useState<number>(INITIAL_SEARCH_MODEL_HEIGHT);
    const [variantModalHeight, setVariantModalHeight] = useState<number>(INITIAL_SEARCH_MODEL_HEIGHT);
    const [tenureList, setTenureList] = useState<any[]>([]);
    const [selectedTenure, setSelectedTenure] = useState<string[]>([]);
    const [citySortingOrder, setCitySortingOrder] = useState<'ascending' | 'descending' | null>(null);
    const [pickedOnSortingOrder, setPickedOnSortingOrder] = useState<'ascending' | 'descending' | null>(null);
    const [etaSortingOrder, setEtaSortingOrder] = useState<'ascending' | 'descending' | null>(null);
    const FILTER_ICON_COLOR = '#1777FF';

    const getCitySortOrder = () => `buyingCity:${citySortingOrder === 'ascending' ? 'asc' : 'desc'}`;
    const getPickedOnSortOrder = () => `pickedOn:${pickedOnSortingOrder === 'ascending' ? 'asc' : 'desc'}`;
    const getEtaSortOrder = () => `eta:${etaSortingOrder === 'ascending' ? 'asc' : 'desc'}`;

    const filterIcon = () => (
      <UnionFilterIcon fill={selectedTenure.length===0 ? '#2C2C2C' : FILTER_ICON_COLOR} style={{ marginLeft: "10px" }} />
    );


    const citySortFilterIcon = () => (
      citySortingOrder === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "1px", marginTop:'7px' }} />
        : citySortingOrder === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "1px", marginTop:'7px' }} />
        : <SortFilterIcon  style={{ marginLeft: "1px", marginTop:'7px' }} />
    );

    const pickedOnSortFilterIcon = () => (
        pickedOnSortingOrder === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "1px", marginTop:'7px' }} />
        : pickedOnSortingOrder === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "1px", marginTop:'7px' }} />
        : <SortFilterIcon  style={{ marginLeft: "1px", marginTop:'7px' }} />
    );

    const etaSortFilterIcon = () => (
        etaSortingOrder === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "1px", marginTop:'7px' }} />
        : etaSortingOrder === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "1px", marginTop:'7px' }} />
        : <SortFilterIcon  style={{ marginLeft: "1px", marginTop:'7px' }} />
    );

    useEffect(() => {
        fetchInProgressQuoteTableData();
    }, [currentPage, pageSize, selectedRequestNumList, selectedProspectsList, selectedRequirementList, selectedBuyingCityList, selectedOwnerList, citySortingOrder, pickedOnSortingOrder, etaSortingOrder, selectedTenure, selectedMakeList, selectedModelList, selectedVariantList]);

    const fetchInProgressQuoteTableData = async (): Promise<void> => {
      try {
          setLoading(true);
          let getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/inProgressRequestedQuotes?page=${currentPage-1}&size=${pageSize}`;
          if(selectedRequestNumList.length > 0){
            getApi = getApi + `&requestIds=${selectedRequestNumList}`
          }
          if(selectedProspectsList.length > 0){
            getApi = getApi + `&prospectIds=${selectedProspectsList}`
          }
          if(selectedRequirementList.length > 0){
            getApi = getApi + `&requirementIds=${selectedRequirementList}`
          }
          if(selectedOwnerList.length > 0){
            getApi = getApi + `&prospectOwnerIds=${selectedOwnerList}`
          }
          if(selectedBuyingCityList.length > 0){
            getApi = getApi + `&cityIds=${selectedBuyingCityList}`
          }
          if(selectedMakeList.length > 0){
            getApi = getApi + `&makeList=${selectedMakeList}`
          }
          if(selectedModelList.length > 0){
            getApi = getApi + `&modelList=${selectedModelList}`
          }
          if(selectedVariantList.length > 0){
            getApi = getApi + `&variantList=${selectedVariantList}`
          }
          if(selectedTenure.length > 0){
            getApi = getApi + `&tenureIds=${selectedTenure}`
          }
          if(citySortingOrder !== null){
            getApi = getApi + `&sort=${getCitySortOrder()}`
          }
          if(pickedOnSortingOrder !== null){
            getApi = getApi + `&sort=${getPickedOnSortOrder()}`
          }
          if(etaSortingOrder !== null){
            getApi = getApi + `&sort=${getEtaSortOrder()}`
          }
          const res = await getRequest(getApi);
          if (res.status === 200) {
            setData(res.data.data);
            if ('pageCount' in res.data) {
              setPageCount(res.data.pageCount);
            }
            if ('totalRecord' in res.data) {
              setTotalRecord(res.data.totalRecord);
            }
          } else {
            console.error('Error fetching Requested Quotes Table List:', res.msg);
          }
        } catch (error) {
          console.error('Error fetching Requested Quotes data:', error);
        } finally {
          setLoading(false);
        }
      };

    useEffect(()=>{
        if(selectedProspectsList.length===0)  
          setProspectsList([])
      }, [displayProspectSearchFilterScreen]);

    useEffect(()=>{
        if(selectedProspectsList.length===0)  
          setProspectsList([])
      }, [displayProspectSearchFilterScreen]);

      useEffect(() => {
        fetchTenureData(setTenureList);
      },[]);
  

    const getColumns = () : ColumnsType<any> => [
        {
          title: (
            <div style={{ display:'flex'}}>
              <span style={{marginRight:'5px'}}>Request No.</span> 
              <SearchOutlined onClick={() => handleRequestNumSearchIconClick()} style={{ display:'flex', cursor: 'pointer', color: selectedProspectsList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
            </div>
          ),
          dataIndex: 'requestNumber',
          render: (text : number) => (
            <div className="table-text-style">{text}</div>
          ),
          key: 'requestNumber',
          width: '12%',
        },
        {
            title: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{}}>Prospect Name</span>
                <SearchOutlined onClick={() => handleProspectSearchIconClick()} style={{ marginLeft: '12px', cursor: 'pointer', color: selectedProspectsList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
              </div>
            ),
            dataIndex: 'prospectName',
            render: (text: string, record: any) => (
                <a href={`/dashboard/prospect/${record?.prospectId}`} className="table-text-style" style={{color : '#2C66E3', textDecoration : 'underline', width: 130, whiteSpace: 'wrap' }}>
                  {text}
                </a>
            ),
            key: 'prospectName',
            width: '18%',
        },
        {
          title: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{marginRight:'0.5rem'}}>Prospect Owner</span> 
              <SearchOutlined onClick={() => handleProspectOwnerSearchIconClick()} style={{ marginLeft: '5px', cursor: 'pointer', color: selectedProspectsList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
            </div>
          ),
            dataIndex: 'prospectOwner',
            render: (text: string) => (
                <div className="table-text-style" style={{ width: 100, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                  {text}
                </div>
            ),
            key: 'prospectOwner',
            width: '15%',
        },{
          title: (
            <div onClick={() => {}} style={{ cursor: 'pointer', display:'flex' }}>
              <span style={{marginRight:'0.5rem'}}>Buying City</span> 
              <SearchOutlined onClick={() => handleBuyingCitySearchIconClick()} style={{ marginLeft: '12px', cursor: 'pointer', color: selectedProspectsList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
            </div>
          ),
          dataIndex: 'buyingCity',
          render: (text : string) => (
            <div className="table-text-style">{text}</div>
          ),
          // sortDirections: ['ascend', 'descend'],
          key: 'buyingCity',
          width: '14%',
      },{
            title: (
              <div onClick={handlePickedOnSort} style={{ cursor: 'pointer', display:'flex' }}>
                <span style={{marginRight:'0.5rem'}}>Picked on</span> 
                {pickedOnSortFilterIcon()}
              </div>
            ),
            dataIndex: 'pickedOn',
            render: (obj : string) => {
              const formattedDate = getFormattedDate(obj);
              return(
                <div className="table-text-style">{formattedDate}</div>
              ); 
          },
            key: 'pickedOn',
            width: '16%',
        },{
            title: 'Picked by',
            dataIndex: 'pickedBy',
            render: (text: string) => (
                <div className="table-text-style" style={{ width: 100, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                  {text}
                </div>
            ),
            key: 'pickedBy',
            width: '13%',
        },{
            title: (
              <div onClick={handleEtaSort} style={{ cursor: 'pointer', display:'flex' }}>
                <span style={{marginRight:'0.5rem'}}>ETA</span> 
                {etaSortFilterIcon()}
              </div>
            ),
            dataIndex: 'eta',
            render: (text : string) => {
                const date = new Date(text);
                date.toISOString()
                const formattedDate = text  === null ? "NA" : getFormattedDate(date.toISOString()).split(",")[1];
                return(
                    <div className="table-text-style">{formattedDate}</div>
                )
            },
            sortDirections: ['ascend', 'descend'],
            key: 'eta',
            width: '13%',
        },
        {
          title: (
            <div style={{ display:'flex'}}>
              <span style={{marginRight:'5px'}}>Make</span> 
              <SearchOutlined onClick={() => handleMakeSearchIconClick()} style={{ display:'flex', cursor: 'pointer', color: selectedProspectsList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
            </div>
          ),
          dataIndex: 'make',
          render: (text : number, record: any) => {
            const make = record?.make != null ? JSON.parse(record?.make).name : "";
            return( <div className="table-text-style">{make}</div> )},
          key: 'make',
          width: '10%',
        },
        {
          title: (
            <div style={{ display:'flex'}}>
              <span style={{marginRight:'5px'}}>Model</span> 
              <SearchOutlined onClick={() => handleModelSearchIconClick()} style={{ display:'flex', cursor: 'pointer', color: selectedProspectsList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
            </div>
          ),
          dataIndex: 'model',
          render: (text : number, record: any) => {
            const model = record?.model!= null ? JSON.parse(record?.model).name : "";
            return( <div className="table-text-style">{model}</div> )},
          key: 'model',
          width: '12%',
        },
        {
          title: (
            <div style={{ display:'flex'}}>
              <span style={{marginRight:'5px'}}>Variant</span> 
              <SearchOutlined onClick={() => handleVariantSearchIconClick()} style={{ display:'flex', cursor: 'pointer', color: selectedProspectsList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
            </div>
          ),
          dataIndex: 'variant',
          render: (text : number, record: any) => {
            const variant = record?.variant!= null ? JSON.parse(record?.variant).name : "";
            return( <div className="table-text-style">{variant}</div> )},
          key: 'variant',
          width: '10%',
        },
        {
          title: 'Tenure',
          dataIndex: 'tenure',
          filterIcon: filterIcon, 
          filterDropdown: (
            <div style={{ padding: 8, width: 180 }}>
              <CustomSelectFilter
                itemsList={tenureList}
                selectedItems={selectedTenure}
                setSelectedItems={setSelectedTenure}
                placeholder="Tenure"
                setMainCurrentPage={setCurrentPage}
              />
            </div>
          ),
          render: (text : number, record: any) => {
            const tenure = record?.tenure!= null ? JSON.parse(record?.tenure).name : "";
            return( <div className="table-text-style">{tenure}</div> )},
          key: 'tenure',
          width: '12%',
        },
        {
          title: (
            <div style={{ display:'flex'}}>
              <span style={{marginRight:'5px'}}>Mileage</span> 
            </div>
          ),
          dataIndex: 'mileage',
          render: (text : number, record: any) => {
            return( <div className="table-text-style">{record?.mileage}</div> )},
          key: 'mileage',
          width: '10%',
        },
        {
            title: 'Action',
            key: 'action',
            width: '14%',
            align: "left",
            render: (text, record) => (
              <div style={{display: "flex", justifyContent: "flex-start"}}>
                {allowedActionsForSampleQuote?.has('viewDetails') ?
                  <Tooltip placement="bottom" title={"View Details"}>
                    <a onClick={() => navigate(`/dashboard/quote/${record?.requestNumber}`)} 
                      className="table-text-style" style={{color : '#2C66E3'}}>
                      <EyeIcon style={{color : '#2C66E3', marginRight:'0.6rem'}}/>
                      View Details
                    </a>
                  </Tooltip> :
                  <Tooltip placement="bottom" title={"No Access"}>
                    <EyeIcon style={{marginRight:'0.6rem', color : 'grey'}} />
                    <span style={{ color : 'grey'}}>View Details</span>
                  </Tooltip>
                }
              </div>
      
            ),
          },
    ];

    const handleFetchRequestNum = async (searchKey: string, isInfiniteScroll: boolean) => {
      try {
        const data = await fetchRequestNumDataFunction(
          searchKey,
          requestNumCurrentPage,
          requestNumPageSize,
          setRequestNumList,
          setRequestNumCurrentPage,
          setRequestNumPageCount,
          isInfiniteScroll
        );
      } catch (error) {
        console.error('Error fetching Request No.:', error);
      }
    };

    const handleFetchProspectNames = async (searchKey: string, isInfiniteScroll: boolean) => {
        try {
          const data = await fetchProspectNamesDataFunction(
            searchKey,
            prospectCurrentPage,
            prospectPageSize,
            setProspectsList,
            setProspectCurrentPage,
            setProspectPageCount,
            isInfiniteScroll
          );
        } catch (error) {
          console.error('Error fetching prospect names:', error);
        }
      };

      const handleFetchRequirementNames = async (searchKey: string, isInfiniteScroll: boolean) => {
        try {
          const data = await fetchRequirementDataFunction(
            searchKey,
            requirementCurrentPage,
            requirementPageSize,
            setRequirementList,
            setRequirementCurrentPage,
            setRequirementPageCount,
            isInfiniteScroll
          );
        } catch (error) {
          console.error('Error fetching requirement:', error);
        }
      };
  
      const handleFetchProspectOwnerNames = async (searchKey: string, isInfiniteScroll: boolean) => {
        try{
          const data = await fetchOwnerNamesDataFunction(
            searchKey,
            ownerCurrentPage,
            ownerPageSize,
            setOwnersList,
            setOwnerCurrentPage,
            setOwnerPageCount,
            isInfiniteScroll
         );
        } catch (error) {
          console.error('Error fetching prospect owners:', error);
        }
      };
  
      const handleFetchBuyingCityNames = async (searchKey: string, isInfiniteScroll: boolean) => {
        try {
          const data = await fetchBuyingCityDataFunction(
            searchKey,
            buyingCityCurrentPage,
            buyingCityPageSize,
            setBuyingCityList,
            setBuyingCityCurrentPage,
            setBuyingCityPageCount,
            isInfiniteScroll
          );
        } catch (error) {
          console.error('Error fetching buying city names:', error);
        }
      };

      const handleFetchMake = async (searchKey: string, isInfiniteScroll: boolean) => {
        try {
          const data = await fetchMakeDataFunction(
            searchKey,
            makeCurrentPage,
            makePageSize,
            setMakeList,
            setMakeCurrentPage,
            setMakePageCount,
            isInfiniteScroll
          );
        } catch (error) {
          console.error('Error fetching makers:', error);
        }
      };
  
      const handleFetchModel = async (searchKey: string, isInfiniteScroll: boolean) => {
        try {
          const data = await fetchModelDataFunction(
            searchKey,
            modelCurrentPage,
            modelPageSize,
            setModelList,
            setModelCurrentPage,
            setModelPageCount,
            isInfiniteScroll
          );
        } catch (error) {
          console.error('Error fetching variants:', error);
        }
      };
  
      const handleFetchVariant = async (searchKey: string, isInfiniteScroll: boolean) => {
        try {
          const data = await fetchVariantDataFunction(
            searchKey,
            variantCurrentPage,
            variantPageSize,
            setVariantList,
            setVariantCurrentPage,
            setVariantPageCount,
            isInfiniteScroll
          );
        } catch (error) {
          console.error('Error fetching models:', error);
        }
      };

      const handleRequestNumSearchIconClick = () => {
        setDisplayRequestNumFilterScreen(!displayRequestNumFilterScreen);
      };

      const handleProspectSearchIconClick = () => {
        setDisplayProspectSearchFilterScreen(!displayProspectSearchFilterScreen);
      };

      const handleRequirementSearchIconClick = () => {
        setDisplayRequirementFilterScreen(!displayRequirementFilterScreen);
      };
      
      const handleProspectOwnerSearchIconClick = () =>{
        setDisplayProspectOwnerFilterScreen(!displayProspectOwnerFilterScreen);
      }
      
      const handleBuyingCitySearchIconClick = () => {
        setDisplayBuyingCityFilterScreen(!displayBuyingCityFilterScreen);
      }

      const handleMakeSearchIconClick = () => {
        setDisplayMakeFilterScreen(!displayMakeFilterScreen);
      }
  
      const handleModelSearchIconClick = () => {
        setDisplayModelFilterScreen(!displayModelFilterScreen);
      }
  
      const handleVariantSearchIconClick = () => {
        setDisplayVariantFilterScreen(!displayVariantFilterScreen);
      }

      const handlePageSizeChangeLocal = (current: number, size: number) => {
          handlePageSizeChange(current, size, setPageSize, setCurrentPage);
      };
  
      const handlePageChangeLocal = (page: number) => {
          handlePageChange(page, setCurrentPage);
      };
  
      const handleCancelModal = () => {
        setDisplayRequestNumFilterScreen(false);
        setDisplayProspectSearchFilterScreen(false);
        setDisplayRequirementFilterScreen(false);
        setDisplayProspectOwnerFilterScreen(false);
        setDisplayBuyingCityFilterScreen(false);
        setDisplayMakeFilterScreen(false);
        setDisplayModelFilterScreen(false);
        setDisplayVariantFilterScreen(false);
        setDisplayTenureFilterScreen(false);
      }
  
      const handleCitySort = () => {
        setCitySortingOrder(citySortingOrder === null ? 'ascending' : citySortingOrder ==='ascending' ? 'descending' : null );
      };
  
      const handlePickedOnSort = () => {
        setPickedOnSortingOrder(pickedOnSortingOrder === null ? 'ascending' : pickedOnSortingOrder==='ascending' ? 'descending' : null );
      };

      const handleEtaSort = () => {
        setEtaSortingOrder(etaSortingOrder === null ? 'ascending' : etaSortingOrder==='ascending' ? 'descending' : null );
      };
  
      const rotatedMinusIconStyle = {
          transform: 'rotate(90deg)',
      };


    return ( 
        <div style={{ backgroundColor: '#F6FAFF', height: '100%'}}>
              {
                displayRequestNumFilterScreen &&
                (
                  <Modal
                    visible={displayRequestNumFilterScreen}
                    onCancel={() => handleCancelModal()}
                    footer={null}
                    width={250}
                    bodyStyle={{ }}
                    style={{padding: "0px", width: "100px", maxHeight: 260}}
                    wrapClassName="modal-wrapper-request-number" 
                    mask={false} // Prevents the background from being faded
                    closable={false}
                  >
                    <CustomSearchSelectFilter
                      placeholder="Enter the Request No."
                      fetchData={handleFetchRequestNum}
                      selectedItems={selectedRequestNumList}
                      setSelectedItems={setSelectedRequestNumList}
                      items={requestNumList}
                      setItems={setRequestNumList}
                      setDisplayProspectSearchFilterScreen={setDisplayRequestNumFilterScreen}
                      setCurrentPage={setRequestNumCurrentPage}
                      currentPage={requestNumCurrentPage}
                      totalPageCount={requestNumPageCount}
                      setMainCurrentPage={setCurrentPage}
                      modalHeight={requestNumModalHeight}
                      setModalHeight={setRequestNumModalHeight}
                      searchKey="id"
                      minSearchLength={1}
                    />
                  </Modal>
                )
              }
              {
                  displayProspectSearchFilterScreen &&
                      (
                          <Modal
                          visible={displayProspectSearchFilterScreen}
                          onCancel={() => handleCancelModal()}
                          footer={null}
                          width={250}
                          bodyStyle={{ }}
                          style={{padding: "0px", width: "100px", maxHeight: 260}}
                          wrapClassName="modal-wrapper-prospect" 
                          mask={false} // Prevents the background from being faded
                          closable={false}
                          >
                          <CustomSearchSelectFilter
                              placeholder="Enter 3 letters to search"
                              fetchData={handleFetchProspectNames}
                              selectedItems={selectedProspectsList}
                              setSelectedItems={setSelectedProspectsList}
                              items={prospectsList}
                              setItems={setProspectsList}
                              setDisplayProspectSearchFilterScreen={setDisplayProspectSearchFilterScreen}
                              setCurrentPage={setProspectCurrentPage}
                              currentPage={prospectCurrentPage}
                              totalPageCount={prospectPageCount}
                              setMainCurrentPage={setCurrentPage}
                              modalHeight={prospectModalHeight}
                              setModalHeight={setProspectModalHeight}
                              searchKey="id"
                              minSearchLength={3}
                          />
                          </Modal>
                      )
              }
              {
              displayRequirementFilterScreen &&
                (
                  <Modal
                    visible={displayRequirementFilterScreen}
                    onCancel={() => handleCancelModal()}
                    footer={null}
                    width={250}
                    bodyStyle={{ }}
                    style={{padding: "0px", width: "100px", maxHeight: 260}}
                    wrapClassName="modal-wrapper-requirement" 
                    mask={false} // Prevents the background from being faded
                    closable={false}
                  >
                    <CustomSearchSelectFilter
                      placeholder="Enter the Requirement No."
                      fetchData={handleFetchRequirementNames}
                      selectedItems={selectedRequirementList}
                      setSelectedItems={setSelectedRequirementList}
                      items={requirementList}
                      setItems={setRequirementList}
                      setDisplayProspectSearchFilterScreen={setDisplayRequirementFilterScreen}
                      setCurrentPage={setRequirementCurrentPage}
                      currentPage={requirementCurrentPage}
                      totalPageCount={requirementPageCount}
                      setMainCurrentPage={setCurrentPage}
                      modalHeight={requirementModalHeight}
                      setModalHeight={setRequirementModalHeight}
                      searchKey="id"
                      minSearchLength={3}
                    />
                  </Modal>
                )
            }
            {
            displayProspectOwnerFilterScreen &&
            (
              <Modal
                visible={displayProspectOwnerFilterScreen}
                onCancel={() => handleCancelModal()}
                footer={null}
                width={250}
                bodyStyle={{ }}
                style={{padding: "0px", width: "100px", maxHeight: 260}}
                wrapClassName="modal-wrapper-owner" 
                mask={false} // Prevents the background from being faded
                closable={false}
              >
                <CustomSearchSelectFilter
                  placeholder="Enter 3 letters to search"
                  fetchData={handleFetchProspectOwnerNames}
                  selectedItems={selectedOwnerList}
                  setSelectedItems={setSelectedOwnerList}
                  items={ownersList}
                  setItems={setOwnersList}
                  setDisplayProspectSearchFilterScreen={setDisplayProspectOwnerFilterScreen}
                  setCurrentPage={setOwnerCurrentPage}
                  currentPage={ownerCurrentPage}
                  totalPageCount={ownerPageCount} // here
                  setMainCurrentPage={setCurrentPage}
                  modalHeight={ownerModalHeight}
                  setModalHeight={setOwnerModalHeight}
                  searchKey="ssoId"
                  minSearchLength={3}
                />
              </Modal>
            )
          }
            {
            displayBuyingCityFilterScreen &&
              (
                <Modal
                  visible={displayBuyingCityFilterScreen}
                  onCancel={() => handleCancelModal()}
                  footer={null}
                  width={250}
                  bodyStyle={{ }}
                  style={{padding: "0px", width: "100px", maxHeight: 260}}
                  wrapClassName="modal-wrapper-buyingCity" 
                  mask={false} // Prevents the background from being faded
                  closable={false}
                >
                  <CustomSearchSelectFilter
                    placeholder="Enter 3 letters to search"
                    fetchData={handleFetchBuyingCityNames}
                    selectedItems={selectedBuyingCityList}
                    setSelectedItems={setSelectedBuyingCityList}
                    items={buyingCityList}
                    setItems={setBuyingCityList}
                    setDisplayProspectSearchFilterScreen={setDisplayBuyingCityFilterScreen}
                    setCurrentPage={setBuyingCityCurrentPage}
                    currentPage={buyingCityCurrentPage}
                    totalPageCount={buyingCityPageCount}
                    setMainCurrentPage={setCurrentPage}
                    modalHeight={buyingCityModalHeight}
                    setModalHeight={setBuyingCityModalHeight}
                    searchKey="id"
                    minSearchLength={3}
                  />
                </Modal>
              )
          }
          {
            displayMakeFilterScreen &&
              (
                <Modal
                  visible={displayMakeFilterScreen}
                  onCancel={() => handleCancelModal()}
                  footer={null}
                  width={250}
                  bodyStyle={{ }}
                  style={{padding: "0px", width: "100px", maxHeight: 260}}
                  wrapClassName="modal-wrapper-make" 
                  mask={false} // Prevents the background from being faded
                  closable={false}
                >
                  <CustomSearchSelectFilter
                    placeholder="Enter 2 letters to search"
                    fetchData={handleFetchMake}
                    selectedItems={selectedMakeList}
                    setSelectedItems={setSelectedMakeList}
                    items={makeList}
                    setItems={setMakeList}
                    setDisplayProspectSearchFilterScreen={setDisplayMakeFilterScreen}
                    setCurrentPage={setMakeCurrentPage}
                    currentPage={makeCurrentPage}
                    totalPageCount={makePageCount}
                    setMainCurrentPage={setCurrentPage}
                    modalHeight={makeModalHeight}
                    setModalHeight={setMakeModalHeight}
                    searchKey="name"
                    minSearchLength={2}
                  />
                </Modal>
              )
          }
          {
            displayModelFilterScreen &&
              (
                <Modal
                  visible={displayModelFilterScreen}
                  onCancel={() => handleCancelModal()}
                  footer={null}
                  width={250}
                  bodyStyle={{ }}
                  style={{padding: "0px", width: "100px", maxHeight: 260}}
                  wrapClassName="modal-wrapper-model" 
                  mask={false} // Prevents the background from being faded
                  closable={false}
                >
                  <CustomSearchSelectFilter
                    placeholder="Enter 2 letters to search"
                    fetchData={handleFetchModel}
                    selectedItems={selectedModelList}
                    setSelectedItems={setSelectedModelList}
                    items={modelList}
                    setItems={setModelList}
                    setDisplayProspectSearchFilterScreen={setDisplayModelFilterScreen}
                    setCurrentPage={setModelCurrentPage}
                    currentPage={modelCurrentPage}
                    totalPageCount={modelPageCount}
                    setMainCurrentPage={setCurrentPage}
                    modalHeight={modelModalHeight}
                    setModalHeight={setModelModalHeight}
                    searchKey="name"
                    minSearchLength={2}
                  />
                </Modal>
              )
          }
          {
            displayVariantFilterScreen &&
              (
                <Modal
                  visible={displayVariantFilterScreen}
                  onCancel={() => handleCancelModal()}
                  footer={null}
                  width={250}
                  bodyStyle={{ }}
                  style={{padding: "0px", width: "100px", maxHeight: 260}}
                  wrapClassName="modal-wrapper-variant" 
                  mask={false} // Prevents the background from being faded
                  closable={false}
                >
                  <CustomSearchSelectFilter
                    placeholder="Enter 2 letters to search"
                    fetchData={handleFetchVariant}
                    selectedItems={selectedVariantList}
                    setSelectedItems={setSelectedVariantList}
                    items={variantList}
                    setItems={setVariantList}
                    setDisplayProspectSearchFilterScreen={setDisplayVariantFilterScreen}
                    setCurrentPage={setVariantCurrentPage}
                    currentPage={variantCurrentPage}
                    totalPageCount={variantPageCount}
                    setMainCurrentPage={setCurrentPage}
                    modalHeight={variantModalHeight}
                    setModalHeight={setVariantModalHeight}
                    searchKey="name"
                    minSearchLength={2}
                  />
                </Modal>
              )
          }
            <div ref={tableRef} style={{ height: 0.80 * tableHeight }}>
                <Spin spinning={loading} size="large">
                    <Table
                        columns={getColumns()}
                        dataSource={data || []}
                        pagination={{
                        pageSize: pageSize,
                        position: ["bottomRight"],
                        showSizeChanger: false,
                        showTotal: (total, range) => (
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop:'-4px' }}>
                            <div style={{fontSize:'1rem'}}>
                                <span style={{fontSize : 14, fontWeight : 400}}>Showing</span>
                                <Select defaultValue={10} style={{ width: 60, margin: '0 5px' }} onChange={(value) => handlePageSizeChangeLocal(1, value)}>
                                    <Option value="10">10</Option>
                                    <Option value="20">20</Option>  
                                    <Option value="50">50</Option>
                                    <Option value="100">100</Option>
                                </Select>
                                <span style={{fontSize : 15, fontWeight : 400}}>per page</span>
                                <span style={{fontSize : 15, fontWeight : 400, marginRight:'10px', marginLeft:'10px', color:'grey'}}><MinusOutlined style={rotatedMinusIconStyle} /></span>
                                <span style={{fontSize : 14, fontWeight : 400}}>{`Results: ${range[0]}-${range[1]} of ${total}`}</span>
                            </div>
                            </div>
                        ),
                        onChange: handlePageChangeLocal,
                        current: currentPage,
                        total: totalRecord,
                        itemRender: (current, type, originalElement) => {
                            if (type === 'prev') {
                            return <LeftOutlined style={{marginLeft:'4rem',fontSize : 12, fontWeight : 500}}/>;
                            }
                            if (type === 'next') {
                            return  <RightOutlined  style={{fontSize : 12, fontWeight : 500}}/>;
                            }
                            if (type === 'page') {
                            return (
                                <span style={{cursor: 'pointer', fontSize : 12, fontWeight : 500 }} onClick={() => handlePageChangeLocal(current)}>
                                {current}
                                </span>
                            );
                            }
                            if (type === 'jump-next' || type === 'jump-prev') {
                            return <a  style={{color: 'black', fontSize : 12, fontWeight : 500 }}><strong> ... </strong></a>;
                            }
                            return originalElement;
                        },
                        }}            
                        scroll={{ x: 1700, y: 0.80 * tableHeight }}
                        size="middle"
                        components={{
                        header: {
                            wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
                        },
                        }}
                    />
                </Spin>
            </div>
        </div>
    );
}
export default InprogressSampleQuoteList;