import React, { useEffect, useRef, useState } from "react"
import { Button, Checkbox, Col, Form, Input, InputNumber, Radio, Row, Select, Typography } from "antd";
import { ReactComponent as AddIcon } from '../../SVGs/add_icon.svg';
import { ReactComponent as DeleteIcon } from '../../SVGs/delete_icon.svg';
import { getRequest, postRequest } from "../../../../utils/handler/apiHandler";
import currencyFormatter from "../../../../utils/NumberFormater";
const { Option } = Select;
const { TextArea } = Input;

interface CreditRequestFormProps {
    segment: string;
    form: any;
    addParent: boolean;
    setAddParent: (addParent: boolean) => void;
    setSelectedBusinessSegment: (businessSegment: any) => void;
}

const CreditRequestForm: React.FC<CreditRequestFormProps> = ({ segment, form, addParent, setAddParent, setSelectedBusinessSegment }) => {
    const [businessSegment, setBusinessSegment] = useState([]);
    const [legalBusiness, setLegalBusiness] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [tenure, setTenure] = useState([]);
    const [fleetSize, setFleetSize] = useState(0);
    const [investmentPerVehicle, setInvestmentPerVehicle] = useState(0);

    useEffect(() => {
        getBusinessSegment();
        form.setFieldsValue({ 
            addParent: false,
        });
    }, []);
    form.setFieldsValue({ businessSegmentObj: (businessSegment.filter(e => e["name"] === segment)[0]) });

    
    const getBusinessSegment = () => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/segments`).then(res => {
            setBusinessSegment(res.data);
        })
    };

    const getLegalBusinessName = async () => {
        await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/legalEntities`).then(res => {
            const data = res.data;
            data.push({
                id: null,
                name: "Other"
            })
            setLegalBusiness(data);
        })
    };

    const getVehicleCategory = () => {
        postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/vehicles`, {}).then(res => {
            setVehicles(res.data);
        })
    };

    const getTenure = () => {
        postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/tenures`, {}).then(res => {
            setTenure(res.data);
        })
    };

    const optionsToRender = (options: any[], identifier: any) => {
        if(options && options.length > 0)
        return options?.map((value: any) => (
            <Option key={value.id} value={value[identifier]}>
            {value[identifier]}
            </Option>
        ));
    };

    const toggleParent = () => {
        setAddParent(!addParent);
        !addParent && form.setFieldsValue({ "parentName": null })
    }

    const handleBusinessSegmentChange = (e: any) => {
        const businessSegmentObj = businessSegment.filter(segment => segment["name"] === e)[0];
        setSelectedBusinessSegment(businessSegmentObj);
        form.setFieldsValue({ 
            businessSegment: e, 
            businessSegmentObj: businessSegmentObj,
        })
    }

    return (
        <React.Fragment>
        <Form
        form={form}
        requiredMark={false}
        layout="vertical"
        name="credit_request_form"
        >
            <Col>
                <Row style={{display: "flex", gap: 10}}>
                    <Form.Item
                        style={{minWidth:'32%'}}
                        label={<div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                            <p className="description-label">Legal Business Name</p> 
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Checkbox checked={addParent} onClick={toggleParent} style={{transform: "scale(0.8)", marginRight: 5}}/>
                                <Typography style={{fontSize: 12, fontWeight: 400}}>Add Parent</Typography>
                            </div>
                        </div>}
                        name="legalBusinessName"
                        rules={[{ required: true, message: 'Please enter Legal Business Name!' }]}
                    >
                        <Input className="input-override" placeholder="Legal Business Name" />
                    </Form.Item>
 
                    {addParent && <Col style={{width: "32%", whiteSpace: "initial", textAlign: "start", marginTop: 8}}>
                    <Typography style={{ color: "#242424", fontSize: 12, fontWeight: 500, paddingBottom: 8}}>
                        Parent Entity
                    </Typography>
                    <div style={{display: "flex", width: "100%"}}>
                        
                        {
                            <Form.Item
                                name="parentName"
                                style={{margin: 0, fontSize: 12, width: "100%"}}
                                rules={[{ required: addParent, message: 'Please enter Parent Name!' }]}
                            >
                                <Select className="select-input-override" placeholder="Select Parent" onClick={getLegalBusinessName} onChange={e => form.setFieldsValue({ "parentEntityObj": (legalBusiness.filter(businessName => businessName["name"] === e)[0])} )}>
                                    {optionsToRender(legalBusiness, "name")}
                                </Select>
                            </Form.Item>
                        }
                        {
                            form.getFieldsValue(true).parentName === "Other" && 
                            <Form.Item
                                name="otherParentName"
                                style={{margin: 0, fontSize: 12, width: "150%"}}
                                rules={[{ required: addParent, message: 'Please enter Parent Name!' }]}
                            >
                                <Input className="input-override" onChange={e => form.setFieldsValue({ "parentEntityObj": {id: null, name: e.target.value}} )} />
                            </Form.Item>
                        }
                    </div>
                    </Col>}
                    


                    <div style={{minWidth:'32%'}}>
                    <Typography className="description-label" style={{padding: "0 0 8px", marginBottom: "4px", lineHeight: 2}}>Business Segment</Typography>
                    <div style={{display: "flex"}}>
                    <Form.Item
                        style={{width:'100%'}}
                        name="businessSegment"
                        rules={[{ required: true, message: 'Please enter Business Segment!' }]}
                    >
                        <Select 
                            className="select-input-override" 
                            placeholder="Select Business Segment"
                            onClick={getBusinessSegment}
                        >
                            {optionsToRender(businessSegment, "name")}
                        </Select>
                    </Form.Item>
                    {
                        form.getFieldsValue(true).businessSegment === "Other"
                        ? <Form.Item
                            className="secondary-field"
                            name='otherBusinessSegment'
                            style={{margin: 0, fontSize: 12, width: "150%"}}
                            rules={[{ required: true, message: 'Please enter Other Business Segment!' }]}
                        >
                            <Input style={{marginLeft: 5}} className="input-override" onChange={e=> form.setFieldsValue({ otherBusinessSegment: e.target.value})} placeholder="Other Business Segment" />
                        </Form.Item>
                        : null
                    }
                    </div>
                    </div>
                </Row>
                <Row style={{display: "flex", gap: 10}}>
                    <Form.Item
                        style={{minWidth:'32%'}}
                        label={ <p className="description-label">Commercial Physical Meeting</p> }
                        name="commercialPhysicalMeeting"
                        // rules={[{ required: true, message: 'Please enter Commercial Physical Meeting!' }]}
                    >
                        <Radio.Group name="radiogroup" defaultValue={false}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        style={{minWidth:'32%'}}
                        label={ <p className="description-label">Current Year Fleet Requirement</p> }
                        name="currentYearFleetRequirement"
                        rules={[{ required: true, message: 'Please enter Current Year Fleet Requirement!' }]}
                    >
                        <InputNumber style={{width: "100%"}} className="input-number-override" placeholder="Current Year Fleet Requirement" />
                    </Form.Item>
                </Row>
                <Row style={{display: "flex", gap: 10}}>
                    <Form.Item
                        style={{minWidth:'49%'}}
                        label={ <p className="description-label">Entity Description <span className='optional-label'>(Optional)</span></p> }
                        name="entityDescription"
                    >
                        <TextArea rows={3} placeholder="Entity Description" />
                    </Form.Item>
                    <Form.Item
                        style={{minWidth:'49%'}}
                        label={ <p className="description-label">Additional Remarks <span className='optional-label'>(Optional)</span></p> }
                        name="addRemark"
                    >
                        <TextArea rows={3} placeholder="Additional Remarks" />
                    </Form.Item>
                </Row>
            </Col>

            {/* Dynamic field for credit requirement */}
            <Form.List name="requirementDetails" initialValue={[{}]}>
            {(fields, { add, remove }) => (
                <>
                    <Row justify="space-between" align="middle">
                        <Typography style={{fontSize: 14, fontWeight: 600}}>Requirement Details</Typography>
                        <Button type="link" style={{fontSize: 12, fontWeight: 400}} icon={<AddIcon />} 
                            onClick={async()=>{try { console.log(await form.validateFields()); add()}catch(err){}}} >
                            Add Requirement</Button>
                    </Row>
                    <Row style={{display: "flex", flexWrap: "nowrap", fontWeight: 500, backgroundColor: "#CEE6FA", padding: 10, gap: 10}}>
                        <Col style={{width: "18%"}}>Vehicle Category</Col>
                        <Col style={{width: "18%"}}>No. of Vehicle</Col>
                        <Col style={{width: "18%"}}>Tenure <span style={{fontSize: 12, fontWeight: 700, opacity: 0.4, marginLeft: 5}}>(In Month)</span></Col>
                        <Col style={{width: "18%"}}>Interest rate offered <span style={{fontSize: 12, fontWeight: 700, opacity: 0.4, marginLeft: 5}}>(%)</span></Col>
                        <Col style={{width: "20%"}}>Investment value per vehicle</Col>
                        <Col style={{width: "12%"}}>Total value</Col>
                    </Row>

                    <div style={{maxHeight: 200, overflow: "scroll"}}>
                    {fields.map(({ key, name, ...restField }) => (
                        <Row className="deleteIconHover" key={key} style={{display: "flex", flexWrap: "nowrap", minHeight: 50, gap: 10, padding: 10, borderBottom: "var(--Number, 1px) solid #E8E8E8"}}>
                            <Col style={{width: "18%"}}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'vehicleCategory']}
                                    style={{margin: 0}}
                                    rules={[{ required: true, message: "" }]}
                                >
                                    <Select 
                                        className="select-input-override" 
                                        placeholder="Vehicle Category" 
                                        onClick={getVehicleCategory} 
                                        onChange={e => form.setFieldValue(["requirementDetails", name, "vehicleCategoryObj"], (vehicles.filter(vehicle => vehicle["name"] === e)[0]))}>
                                        {optionsToRender(vehicles, "name")}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col style={{width: "18%"}}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'noOfVehicle']}
                                    style={{margin: 0}}
                                    rules={[{ required: true, message: "" }]}
                                >
                                    <InputNumber style={{width: "100%"}} className="input-number-override" placeholder="No. of Vehicle" onChange={(e: any) => setFleetSize(e)} />
                                </Form.Item>
                            </Col>
                            <Col style={{width: "18%"}}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'leaseTerm']}
                                    style={{margin: 0}}
                                    rules={[{ required: true, message: "" }]}
                                >
                                    <Select 
                                        className="select-input-override" 
                                        placeholder="Tenure in month" 
                                        onClick={getTenure}
                                        onChange={e => form.setFieldValue(["requirementDetails", name, "leaseTermObj"], (tenure.filter(t => t["inMonth"] === e)[0]))}
                                    >
                                        {optionsToRender(tenure, "inMonth")}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col style={{width: "18%"}}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'interestRateOffered']}
                                    style={{margin: 0}}
                                >
                                    <InputNumber style={{width: "100%"}} className="input-number-override" placeholder="Interest rate offered (%)" />
                                </Form.Item>
                            </Col>
                            <Col style={{width: "20%"}}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'investmentPerVehicle']}
                                    style={{margin: 0}}
                                    rules={[{ required: true, message: "" }]}
                                >
                                    <InputNumber style={{width: "100%"}} className="input-number-override" placeholder="Investment value per vehicle" onChange={(e: any) => setInvestmentPerVehicle(e)} />
                                </Form.Item>
                            </Col>
                            <Col style={{width: "12%"}}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'totalValue']}
                                    style={{margin: 0}}
                                >
                                    <Typography style={{fontSize: 14, fontWeight: 500}}>
                                    {currencyFormatter(~~form.getFieldValue(["requirementDetails", name, "noOfVehicle"]) * ~~form.getFieldValue(["requirementDetails", name, "investmentPerVehicle"]))}
                                    </Typography>
                                </Form.Item>
                            </Col>
                            {
                                form.getFieldValue("requirementDetails").length > 1 && <DeleteIcon onClick={_=> remove(name)} className="deleteIcon"/>
                            }
                        </Row>
                    ))}
                    </div>
                </>
            )}
            </Form.List>
        </Form>
        </React.Fragment>
    )
}

export default CreditRequestForm;