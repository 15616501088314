import { ArrowRightOutlined, DeleteFilled, DeleteOutlined, EyeInvisibleFilled, EyeOutlined, LoadingOutlined, MinusCircleOutlined, PlusOutlined, RightOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Space, Spin, Switch, Tooltip, Typography, Upload } from "antd";
import { useEffect, useState } from "react";
import { modalButtonStyle } from "./OnboardNewClient";
import axios from "axios";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";
import dayjs from "dayjs";
import { postRequest, putRequest } from "../../../utils/handler/apiHandler";
import ViewDocumentModal from "./ViewDocumentModal";



export const useCases = ["Perquisite",
    "Salary Sacrifice",
    "Pool Cabs",
    "ETS",
    "Logistics",
    "Ride hailing",
    "Others"]
export default function ContractDetailsForm({ setCurrentStep, docTypes, prospectId, prospectDetails, getDocTypes, disabled }: any) {
    const [mlaFileList, setMlaFileList] = useState<any>({
        files: [],
        urls: []
    });
    const [mfmsFileList, setMfmsFileList] = useState<any>({
        files: [],
        urls: []
    });;

    const [uploadLoader, setUploadLoader] = useState<any>({
        mla: false,
        mfms: false
    });
    const [form] = Form.useForm();
    const antIconUpload = <LoadingOutlined style={{ fontSize: 16, color: "black", marginLeft: 6 }} spin />;
    const auth = useAppSelector(authSelector);
    const [delayPaymentType, setDelayPaymentType] = useState<string | null>(null)
    const [securityDepositType, setSecurityDepositType] = useState<string | null>(null)
    const [downPaymentType, setDownPaymentType] = useState<string | null>(null)
    const [submitting, setSubmitting] = useState(false)
    const [showMonth, setShowMonths] = useState(false)
    const [showUseCaseName, setShowUseCaseName] = useState(false)
    const [previewFile, setPreviewFile] = useState<null | {
        "fileName": string,
        "type": string,
        "url": string,
    }>(null)
    const [ongoingUploads, setOngoingUploads] = useState(0);

    const handleDelete = (file: any) => {
        console.log("file", file)
        console.log("fileLIST", mlaFileList.files)

        const tempFileList = [];
        const tempUrls = [];

        for (let i = 0; i < mlaFileList.files.length; i++) {
            if (mlaFileList.files[i]?.uid !== file?.uid) {
                tempFileList.push(mlaFileList.files[i]);
                tempUrls.push(mlaFileList.urls[i]);
            }
        }
        console.log(tempFileList, tempUrls)
        setMlaFileList({
            files: tempFileList,
            urls: tempUrls
        });

    }

    const handleDeleteMfms = (file: any) => {

        const tempFileList = [];
        const tempUrls = [];

        for (let i = 0; i < mfmsFileList.files.length; i++) {
            if (mfmsFileList.files[i]?.uid !== file?.uid) {
                tempFileList.push(mfmsFileList.files[i]);
                tempUrls.push(mfmsFileList.urls[i]);
            }
        }
        setMfmsFileList({
            files: tempFileList,
            urls: tempUrls
        });

    }

    const selectBefore = (
        <Select placeholder="Select" onChange={(value) => { setDelayPaymentType(value); form.setFieldValue("delayPaymentChargesValue", null) }} style={{ width: 120, color: "black" }}
            defaultValue={disabled ? prospectDetails?.delayPaymentType : null}         >
            <Select.Option style={{ color: "black" }} value="%">%</Select.Option>
            <Select.Option style={{ color: "black" }} value="Fixed Value">Fixed Value</Select.Option>

        </Select>
    );

    const downPaymentSelectBefore = (
        <Select placeholder="Select" onChange={(value) => { setDownPaymentType(value); form.setFieldValue("downPaymentValue", null) }} style={{ width: 120, color: "black" }}
            defaultValue={disabled ? prospectDetails?.downPaymentType : null}         >
            <Select.Option style={{ color: "black" }} value="%">%</Select.Option>
            <Select.Option style={{ color: "black" }} value="Fixed Value">Fixed Value</Select.Option>

        </Select>
    );

    const selectBeforeSecutiry = (
        <Select placeholder="Select" onChange={(value) => { setSecurityDepositType(value); form.setFieldValue("securityDepositCharges", null) }} style={{ width: 100, color: "black" }}
            defaultValue={disabled ? prospectDetails?.securityDepositType : null}
        >
            <Select.Option style={{ color: "black" }} value="LR">LR</Select.Option>
            <Select.Option style={{ color: "black" }} value="TIV">TIV</Select.Option>
            <Select.Option style={{ color: "black" }} value="Fixed Value">Fixed Value</Select.Option>
        </Select>
    )

    const changeLoaderState = (loading: boolean, type: string) => {
        setUploadLoader((prevState: any) => ({
            ...prevState,
            [type]: loading,
        }));
    };

    const handleSubmit = async () => {
        const formValues = form.getFieldsValue()

        console.log("formValues", formValues)

        const { mfms, mla, ...rest } = formValues;
        const updateParams = {
            ...rest,
            contractExecutionDate: rest.contractExecutionDate ? dayjs(rest.contractExecutionDate).format("YYYY-MM-DD") : null,
            contractValidity: rest.contractValidity ? dayjs(rest.contractValidity).format("YYYY-MM-DD") : null,
            delayPaymentType: delayPaymentType,
            downPaymentType: downPaymentType,
            securityDepositType: securityDepositType,
            primaryUseCase: showUseCaseName ? formValues.otherPrimaryUseCase : formValues.primaryUseCase,
        };

        const agreementDocs = {
            mfms,
            mla
        };

        console.log(updateParams);         // Object without services and securityDepositCharges
        console.log(agreementDocs);

        console.log(mlaFileList, mfmsFileList)

        const mlaData = docTypes.filter((i: any) =>
            i.name == "MLA"
        )[0]


        const mfmsData = docTypes.filter((i: any) =>
            i.name == "MFMS"
        )[0]

        console.log("mlaData", mlaData)
        console.log("mfmsData", mfmsData)

        let cancelMla = false;
        let cancelMfms = false;

        const docsParams = []

        if (mlaData?.id && mlaFileList.urls[0]) {
            docsParams.push({
                "clientAgreementDocumentId": mlaData.id,
                "validity": mlaData.validity ? dayjs(mlaData.validity).format("YYYY-MM-DD") : null,
                "remarks": mlaData.remark,
                "uploadedDocUrl": mlaFileList.urls.map((i: any, key: number) => {
                    const fileName = i.substring(i.lastIndexOf('/') + 1).split('.')[0]; // Get file name without extension
                    const fileType = i.split('.').pop();
                    return {
                        "fileName": fileName,
                        "url": i,
                        "type": fileType,
                    }
                })
            })
        } else {
            cancelMla = true
        }

        if (mfmsData?.id && mfmsFileList.urls[0]) {
            docsParams.push({
                "clientAgreementDocumentId": mfmsData.id,
                "validity": mfmsData.validity ? dayjs(mfmsData.validity).format("YYYY-MM-DD") : null,
                "remarks": mfmsData.remark,
                "uploadedDocUrl": mfmsFileList.urls.map((i: any, key: number) => {
                    const fileName = i.substring(i.lastIndexOf('/') + 1).split('.')[0]; // Get file name without extension
                    const fileType = i.split('.').pop();
                    return {
                        "fileName": fileName,
                        "url": i,
                        "type": fileType
                    }
                })
            })
        } else {
            cancelMfms = true
        }

        let cancelParams = [];
        if (cancelMfms && mfmsData?.id) {
            cancelParams.push(mfmsData.id)
        }
        if (cancelMla && mlaData?.id) {
            cancelParams.push(mlaData.id)
        }


        console.log("docsParams", docsParams, cancelMla, cancelMfms, cancelParams, updateParams)
        setSubmitting(true)
        await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/uploadAgreementDocDetails`, docsParams).then((res: any) => {
            console.log(res)
        })

        if (cancelParams.length > 0) {
            await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/cancelAgreementDoc?agreementDocIds=${cancelParams.join(',')}`, {}).then((res: any) => {
                console.log(res)
            })
        }

        await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/updateDetails/${prospectDetails?.clientOnBoardingId}`, updateParams).then(res => {
            console.log(res)
            setCurrentStep(5)
            // addDocTypes()
        }).catch((e) => {
            console.log(e)
        })
        setSubmitting(false)

    }

    const handleUploadRequest = async (file: any, type: string) => {
        console.log("filexx", file)
        const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/uploadAgreementDoc?prospectId=${prospectId}&isAddressDoc=false`;
        const formData = new FormData();
        formData.append('file', file.file);
        setOngoingUploads((prev) => prev + 1);
        changeLoaderState(true, type);
        try {

            const response = await axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log(response)
                console.log("response?.data", response?.data)

                if (response.status === 200) {
                    if (type == "mla") {
                        // setMlaFileList([{ ...file, name: response.data.DocumentUrl }]);
                        setMlaFileList((prevState: any) => {
                            return {
                                files: [...prevState.files, file.file],
                                urls: [...prevState.urls, response.data.DocumentUrl]
                            }
                        })
                    } else if (type = "mfms") {
                        // setMfmsFileList([{ ...file, name: response.data.DocumentUrl }])
                        setMfmsFileList((prevState: any) => {
                            return {
                                files: [...prevState.files, file.file],
                                urls: [...prevState.urls, response.data.DocumentUrl]
                            }
                        })
                    }
                    console.log([file])

                }
                else {
                    console.log("error in uploading file")
                }

            })
        } catch (error) {
            console.error('Upload error:', error);

        } finally {
            // Decrement ongoing uploads counter
            setOngoingUploads((prev) => {
                const newOngoingUploads = prev - 1;
                if (newOngoingUploads === 0) {
                    // Disable loader when all uploads complete
                    changeLoaderState(false, type);
                }
                return newOngoingUploads;
            });
        }
    };

    useEffect(() => {
        getDocTypes()
    }, [])

    useEffect(() => {
        if (disabled) {
            form.setFieldsValue({
                contractNumber: prospectDetails?.contractNumber,
                contractExecutionDate: prospectDetails?.contractExecutionDate && dayjs(prospectDetails?.contractExecutionDate),
                contractValidity: prospectDetails?.contractValidity && dayjs(prospectDetails?.contractValidity),
                termOfContract: prospectDetails?.termOfContract,
                agreementType: prospectDetails?.agreementType,
                lockInPeriod: prospectDetails?.lockInPeriod,
                lockInPeriodMonths: prospectDetails?.lockInPeriodMonths,
                billingType: prospectDetails?.billingType,
                primaryUseCase: useCases.includes(prospectDetails?.primaryUseCase) ? prospectDetails?.primaryUseCase : "Others",
                otherPrimaryUseCase: useCases.includes(prospectDetails?.primaryUseCase) ? null : prospectDetails?.primaryUseCase,
                exitClauseDescription: prospectDetails?.exitClauseDescription,
                exitClause: prospectDetails?.exitClause,
                roadTax: prospectDetails?.roadTax,
                services: prospectDetails?.services,
                insurance: prospectDetails?.insurance,
                foreclosurePenalty: prospectDetails?.foreclosurePenalty,
                delayPaymentChargesValue: prospectDetails?.delayPaymentChargesValue,
                securityDepositCharges: prospectDetails?.securityDepositCharges,
                securityDepositType: prospectDetails?.securityDepositType,
                downPaymentType: prospectDetails?.downPaymentType,
                downPaymentValue: prospectDetails?.downPaymentValue,
                delayPaymentType: prospectDetails?.delayPaymentType,
                processingFeesValue: prospectDetails?.processingFeesValue,
                buyBack: prospectDetails?.buyBack,
            })
            setDelayPaymentType(prospectDetails?.delayPaymentType)
            setDownPaymentType(prospectDetails?.downPaymentType)
            setSecurityDepositType(prospectDetails?.securityDepositType)
            setShowUseCaseName(!useCases.includes(prospectDetails?.primaryUseCase))
            setShowMonths(prospectDetails?.lockInPeriod == "Managed" ? true : false)
        }
    }, [prospectDetails])



    return (
        <>

            <Form disabled={disabled} form={form} onFinish={handleSubmit} layout="vertical" name="client-details">
                <Typography.Text strong style={{ fontSize: 16 }}>Contract Details</Typography.Text>

                <div style={{
                    background: "white", padding: "20px 20px 0px 20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
                }}>
                    <Row gutter={12}>
                        <Col span={8}>
                            <Form.Item
                                label="Contract No." name="contractNumber">
                                <Input placeholder="Enter Contract No." />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="MLA File" name="mla">
                                {!disabled && <Upload
                                    style={{ width: "100%", display: "block" }}
                                    accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
                                    customRequest={(file) => handleUploadRequest(file, "mla")}
                                    beforeUpload={(file) => {
                                        { handleUploadRequest({ file }, "mla") }
                                        return false
                                    }}
                                    fileList={mlaFileList?.files}
                                    onRemove={handleDelete}
                                    listType="text"
                                    multiple={true}
                                    itemRender={(originNode, file, fileList, actions) => <div style={{ display: "flex", justifyContent: "space-between", marginTop: 8 }}>
                                        <div style={{ width: 150 }}>
                                            <Typography.Text ellipsis>
                                                <Tooltip title={file.name}>{file.name}</Tooltip>
                                            </Typography.Text>
                                        </div>
                                        <div style={{ display: "flex", gap: 10 }}>
                                            {["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(file.type as string) ? <Button
                                                // loading={uploadLoader.mla} 
                                                onClick={() => {
                                                    const itemIndex = fileList.findIndex((i) => i.uid === file.uid);
                                                    setPreviewFile({
                                                        "fileName": file.name,
                                                        "type": file.type as string,
                                                        "url": mlaFileList.urls[itemIndex],
                                                    })
                                                }} size="small" type="text" icon={<EyeOutlined />} /> :
                                                <Button
                                                    // loading={uploadLoader.mla}
                                                    size="small" type="text" icon={<EyeInvisibleFilled style={{ color: "grey" }} />} />
                                            }

                                            <Button disabled={uploadLoader.mla} onClick={actions.remove} size="small" type="text" style={{ cursor: "pointer" }}>
                                                <DeleteFilled style={{ color: "red" }} />
                                            </Button>
                                        </div>


                                    </div>}
                                >

                                    <Button
                                        disabled={uploadLoader.mla || uploadLoader.mfms} loading={uploadLoader.mla}
                                        type="default"
                                        //  disabled={mlaFileList.length > 0}
                                        style={{ width: "100%", display: "block", border: "1px solid rgba(44, 102, 227, 1)", color: "rgba(44, 102, 227, 1)" }}
                                        icon={uploadLoader.mla ? <Spin indicator={antIconUpload} /> : <UploadOutlined />}
                                    >Upload File</Button>
                                </Upload>}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="MFMS File" name="mfms">
                                {!disabled && <Upload
                                    style={{ width: "100%", display: "block" }}
                                    customRequest={(file) => handleUploadRequest(file, "mfms")}
                                    beforeUpload={(file) => {
                                        { handleUploadRequest({ file }, "mfms") }
                                        return false
                                    }}
                                    accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
                                    fileList={mfmsFileList?.files}
                                    onRemove={handleDeleteMfms}
                                    listType="text"
                                    multiple={true}
                                    itemRender={(originNode, file, fileList, actions) => <div style={{ display: "flex", justifyContent: "space-between", marginTop: 8 }}>
                                        <div style={{ width: 150 }}>
                                            <Typography.Text ellipsis>
                                                <Tooltip title={file.name}>{file.name}</Tooltip>
                                            </Typography.Text>
                                        </div>
                                        <div style={{ display: "flex", gap: 10 }}>
                                            {["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(file.type as string) ? <Button
                                                //  loading={uploadLoader.mfms} 
                                                onClick={() => {
                                                    const itemIndex = fileList.findIndex((i) => i.uid === file.uid);
                                                    setPreviewFile({
                                                        "fileName": file.name,
                                                        "type": file.type as string,
                                                        "url": mfmsFileList.urls[itemIndex],
                                                    })
                                                }} size="small" type="text" icon={<EyeOutlined />} /> :
                                                <Button
                                                    // loading={uploadLoader.mfms}
                                                    size="small" type="text" icon={<EyeInvisibleFilled style={{ color: "grey" }} />} />
                                            }

                                            <Button disabled={uploadLoader.mfms} onClick={actions.remove} size="small" type="text" style={{ cursor: "pointer" }}>
                                                <DeleteFilled style={{ color: "red" }} />
                                            </Button>
                                        </div>


                                    </div>}
                                >

                                    <Button
                                        type="default"
                                        disabled={uploadLoader.mfms || uploadLoader.mla} loading={uploadLoader.mfms}
                                        style={{ width: "100%", display: "block", border: "1px solid rgba(44, 102, 227, 1)", color: "rgba(44, 102, 227, 1)" }}
                                        icon={uploadLoader.mfms ? <Spin indicator={antIconUpload} /> : <UploadOutlined />}
                                    >Upload File</Button>
                                </Upload>}

                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Contract Execution Date" name="contractExecutionDate">
                                <DatePicker style={{ width: "100%" }} placeholder="Enter Contract Execution Date" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Contract Validity" name="contractValidity">
                                <DatePicker style={{ width: "100%" }} placeholder="Enter Contract Validity" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Term of Contract" name="termOfContract">
                                <Select placeholder={"Select Term of Contract"}>
                                    <Select.Option value="12">12</Select.Option>
                                    <Select.Option value="24">24</Select.Option>
                                    <Select.Option value="36">36</Select.Option>
                                    <Select.Option value="48">48</Select.Option>
                                    <Select.Option value="60">60</Select.Option>
                                    <Select.Option value="72">72</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Agreement Type" name="agreementType">
                                <Select placeholder="Select Agreement Type">
                                    <Select.Option value="leasing">Leasing</Select.Option>
                                    <Select.Option value="services (management only)">Services (Management only)</Select.Option>

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Lock In Period" name="lockInPeriod">
                                <Select placeholder="Select Lock In Period" onChange={(value) => value == "Managed" ? setShowMonths(true) : setShowMonths(false)}>
                                    <Select.Option value="Managed">Applicable</Select.Option>
                                    <Select.Option value="Not Managed">Not Applicable</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            {showMonth && <Form.Item
                                rules={[
                                    { pattern: /^[0-9]*$/, message: 'Only numbers are allowed!' },
                                    // {
                                    //     validator: (_, value) => {
                                    //         if (value && Number(value) > 12) {
                                    //             return Promise.reject(new Error('Value cannot be more than 12!'));
                                    //         }
                                    //         return Promise.resolve();
                                    //     }
                                    // }
                                ]}
                                label="Lock In Period Months" name="lockInPeriodMonths">
                                <InputNumber style={{ width: "100%" }} placeholder="Select Lock In Period Months" />
                            </Form.Item>}
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Billing Frequency" name="billingType">
                                <Select placeholder="Select Billing Frequency">
                                    <Select.Option value="Monthly">Monthly</Select.Option>
                                    <Select.Option value="Quarterly">Quarterly</Select.Option>
                                    <Select.Option value="Half Yearly">Half Yearly</Select.Option>
                                    <Select.Option value="Yearly">Yearly</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <div style={{ display: "flex", gap: 10 }}>
                                <div style={{ flex: 1 }}>
                                    <Form.Item label="Primary Use Case" name="primaryUseCase">
                                        <Select onSelect={(value) => value == "Others" ? setShowUseCaseName(true) : setShowUseCaseName(false)} placeholder="Select Primary Use Case">
                                            <Select.Option value="Perquisite">Perquisite</Select.Option>
                                            <Select.Option value="Salary Sacrifice">Salary Sacrifice</Select.Option>
                                            <Select.Option value="Pool Cabs">Pool Cabs</Select.Option>
                                            <Select.Option value="ETS">ETS</Select.Option>
                                            <Select.Option value="Logistics">Logistics</Select.Option>
                                            <Select.Option value="Ride hailing">Ride hailing</Select.Option>
                                            <Select.Option value="Others">Others</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                {showUseCaseName && <div>
                                    <Form.Item label=" " name="otherPrimaryUseCase">
                                        <Input placeholder="Enter Primary Use Case" />
                                    </Form.Item>
                                </div>}

                            </div>

                        </Col>
                        <Col span={8}>
                            <Form.Item label="Exit Clause" name="exitClause">
                                <Select placeholder="Select Exit Clause">
                                    <Select.Option value="OBV">OBV</Select.Option>
                                    <Select.Option value="Walkaway">Walkaway</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Exit Clause Description" name="exitClauseDescription">
                                <Input.TextArea rows={2} placeholder="Select Exit Clause Description" />
                            </Form.Item>
                        </Col>
                    </Row>

                </div>




                <Typography.Text strong style={{ fontSize: 16, paddingTop: 28, display: "block" }}>Contracted Services</Typography.Text>
                <div style={{
                    background: "white", padding: "20px 20px 0px 20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
                }}>
                    <Row gutter={12}>
                        <Col span={8}>
                            <Form.Item label="Road Tax" name="roadTax">
                                <Select placeholder="Select Road Tax">
                                    <Select.Option value="Managed">Managed</Select.Option>
                                    <Select.Option value="Not Managed">Not Managed</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Services" name="services">
                                <Select placeholder="Select Service">
                                    <Select.Option value="Managed">Managed</Select.Option>
                                    <Select.Option value="Not Managed">Not Managed</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Insurance" name="insurance">
                                <Select placeholder="Select Insurance">
                                    <Select.Option value="Managed">Managed</Select.Option>
                                    <Select.Option value="Not Managed">Not Managed</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                </div>

                <Typography.Text strong style={{ fontSize: 16, paddingTop: 28, display: "block" }}>Charges & Penalties</Typography.Text>
                <div style={{
                    background: "white", padding: "20px 20px 0px 20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
                }}>
                    <Row gutter={12}>

                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    {
                                        pattern: /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{1,2})?)$/,
                                        message: 'Must be a positive number less than 100 with up to two decimal points!',
                                    }
                                ]}
                                label="Foreclosure Penalty %" name="foreclosurePenalty">
                                <Input placeholder="Enter Foreclosure Penalty %" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    { pattern: delayPaymentType !== "%" ? /^\d*\.?\d*$/ : /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{1,2})?)$/, message: delayPaymentType !== "%" ? 'Only numbers are allowed!' : 'Must be a positive number less than 100 with up to two decimal points!' }
                                ]}
                                label="Delay Payment Charges" name="delayPaymentChargesValue">
                                <Input placeholder="Enter Delay Payment Charges" addonBefore={selectBefore} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    { pattern: securityDepositType !== "%" ? /^\d*\.?\d*$/ : /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{1,2})?)$/, message: securityDepositType !== "%" ? 'Only numbers are allowed!' : 'Must be a positive number less than 100 with up to two decimal points!' }
                                ]}
                                label="Security Deposit Value" name="securityDepositCharges">
                                <Input placeholder="Enter Security Deposit Value" addonBefore={selectBeforeSecutiry} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                rules={[
                                    { pattern: delayPaymentType !== "%" ? /^\d*\.?\d*$/ : /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{1,2})?)$/, message: downPaymentType !== "%" ? 'Only numbers are allowed!' : 'Must be a positive number less than 100 with up to two decimal points!' }
                                ]}
                                label="Down Payment Value" name="downPaymentValue">
                                <Input placeholder="Down Payment Value (%)" addonBefore={downPaymentSelectBefore} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Processing Fees Value" name="processingFeesValue">
                                <Input placeholder="Enter Processing Fees Value" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Buy Back" name="buyBack">
                                <Select placeholder="Select Buy Back">
                                    <Select.Option value="Applicable">Applicable</Select.Option>
                                    <Select.Option value="Not Applicable">Not Applicable</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>

                </div>

                {!disabled && <div style={{ textAlign: "right", marginTop: 28 }}>
                    <Button loading={submitting} disabled={submitting} htmlType="submit" size="large" type="primary">Proceed <RightOutlined /></Button>
                </div>}

            </Form>

            {disabled && <div style={{ textAlign: "right", marginTop: 28, paddingBottom: 20 }}>
                <Button onClick={() =>
                    setCurrentStep(5)
                } size="large" type="primary">Proceed <RightOutlined /></Button>
            </div>}
            <ViewDocumentModal setPreviewFile={setPreviewFile} previewFile={previewFile} />


        </>

    )
}