import React, { useState } from 'react';
import { Typography, Button } from 'antd';

export default function ReadMoreText({ text, maxLength = 100, changed }:any)  {
    const [isExpanded, setIsExpanded] = useState(false);

    // Check if text length exceeds the maxLength
    const shouldTruncate = text?.length > maxLength;

    // Truncate the text if necessary
    const truncatedText = text?.length > maxLength ? `${text?.substring(0, maxLength)}...` : text;

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div style={{ marginTop: 4 }}>

            <Typography.Text 
                style={{ 
                    color: changed ? "#FFA800" : "black",
                    display: "-webkit-box", 
                    WebkitBoxOrient: "vertical", 
                    overflow: "hidden", 
                    WebkitLineClamp: isExpanded || !shouldTruncate ? 'unset' : 3 // Show full text if expanded or if no truncation needed
                }}>
                {isExpanded ? text : truncatedText}
            </Typography.Text>
            
            {/* Render "Read More" button only if truncation is necessary */}
            {shouldTruncate && (
                <Button 
                    type="link" 
                    onClick={handleToggle}
                    style={{ marginTop: 2, padding: 0 }}
                >
                    {isExpanded ? "Less" : "More"}
                </Button>
            )}
        </div>
    );
};
