import { Button, Col, Row, Tooltip } from 'antd';
import React, { useState } from 'react';
import { getFormattedDate } from '../../../../../utils/helpers/utils';
import { ReactComponent as Upload } from '../.././utils/SVGs/Upload.svg';
import { ReactComponent as RemarkIcon } from '../.././utils/SVGs/RemarkIcon.svg';
import { ReactComponent as RedDotIcon } from '../.././utils/SVGs/RedDotIcon.svg';
import { ReactComponent as File } from '../.././utils/SVGs/File.svg';
import { ReactComponent as AddIcon } from '../.././utils/SVGs/add_icon.svg';
import { ReactComponent as PipeIcon } from '../../../../../utils/icons/Pipe_Icon.svg'; 
import { ReactComponent as CrossIcon } from '../.././utils/SVGs/cross_icon.svg';
import { ReactComponent as TickIcon } from '../.././utils/SVGs/tick_icon_stroke.svg';
import { CREDIT_EDITABLE_DOCUMENT_STATE, SALES_ROLE } from '../../../constants';
import { shallowEqual } from 'react-redux';
import { getDisableModifyButtonFlag } from './documentUtils';

interface DocumentItemProps {
  document: any;
  setShowUpload: (flag: boolean)=>void;
  setDocumentActionTargetId: (id: number) => void;  
  setShowViewDocs: (flag: boolean)=>void;
  handleRemarksNote: (record: any)=>void
  handleFeedbackNote: (record: any)=>void  
  handleRejectDocument: (record: any) => void;
  handleApproveDocument: (id: number) => void;
  docUploadStatus: any;
  userRole: string;
  allowedActionsForProspect: Set<string>;
}

function extractLettersAfterColon(inputString: string) {
  const colonIndex = inputString.indexOf(':');
  if (colonIndex === -1) {
      return ''; // Return an empty string if no colon is found
  }
  return inputString.slice(colonIndex + 1).trim();
}



const DocumentItem: React.FC<DocumentItemProps> = ({ document, setShowUpload, setDocumentActionTargetId,
   setShowViewDocs, handleRemarksNote, handleFeedbackNote, handleRejectDocument, handleApproveDocument, docUploadStatus,
   userRole, allowedActionsForProspect }) => {
  const [borderColor, setBorderColor] = useState<string>("null");
  const OTHER_DOC_ID: number = 6;
  const documentName = document?.name === 'Other' ? extractLettersAfterColon(document?.otherDescription) : document?.name;
  if(document?.color === null){
    if(borderColor !== "null"){
      setBorderColor("null");
    }
  }
  else if(document?.color != borderColor){
    setBorderColor(document?.color);     
  }

  const disableAddFiles = getDisableModifyButtonFlag(userRole, docUploadStatus?.name);
  return (

  <Row style={{backgroundColor: '#E6F2FD', borderRadius:'6px',  minHeight: 50, 
    border: borderColor === "null"
    ? 'none'
    : `${borderColor === '#FFA600' ? '2px' : '1px'} ${borderColor} solid` }} 
    justify="space-between" className='document-list-item-container'>
    
    <Col style={{ width: '10%'}}>
      <Tooltip title={documentName}>
        <div
          style={{
            fontSize: '14px',
            fontWeight: '400',
            color: '#404040',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace:'nowrap'
          }}
        >
          {documentName} 
        </div>
      </Tooltip>
    </Col>

    <Col style={{ width: '17%' }}>
      {document?.isFileUploaded === 'true' ? (
        <div style={{ display: 'flex', flexWrap: 'nowrap', marginLeft: '-0.8rem' }}>
          <Button
            type="primary"
            ghost
            onClick={() => {
              setShowViewDocs(true);
              setDocumentActionTargetId(parseInt(document.prospectDocId, 10));
            }}
            style={{ border: 'none', fontSize: '14px', fontWeight: 400 }}
          >
            <span style={{ marginRight: '0.4rem' }}>
              <File />
            </span>
            <span style={{ fontSize: '12px', fontWeight: '500' }}>View Docs</span>
          </Button>
          
          <div style={{ marginTop: '7px' }}><PipeIcon /></div>
          <Button
            disabled={disableAddFiles}
            type="primary"
            ghost
            onClick={() => {
              setShowUpload(true);
              setDocumentActionTargetId(parseInt(document.prospectDocId, 10));
            }}
            style={{ border: 'none', fontSize: '14px', fontWeight: 400 }}
          >
            <span style={{ marginRight: '0.4rem' }}>
              <AddIcon />
            </span>
            
            <span style={{ fontSize: '12px', fontWeight: '500' }}>Add Files</span>
          
          </Button>
        </div>
      ) : (
        <Button
          type="primary"
          disabled={disableAddFiles}
          ghost
          onClick={() => {
            setShowUpload(true);
            setDocumentActionTargetId(parseInt(document.prospectDocId, 10));
          }}
          style={{
            border: 'none',
            fontSize: '14px',
            fontWeight: 500,
            marginLeft: '-0.8rem'
          }}
        >
          <span style={{ marginRight: '0.4rem' }}>
            <Upload />
          </span>
          <span style={{ fontSize: '12px', fontWeight: '500' }}>Upload</span>
          
        </Button>
      )}
    </Col>

    <Col style={{ width: '8%'}}>
      <Button
        type="primary"
        ghost
        onClick={() => {
          setDocumentActionTargetId(parseInt(document.prospectDocId, 10));
          handleRemarksNote(document);
        }}
        style={{ border: 'none', fontSize: '14px', fontWeight: 500, marginLeft: '-0.8rem' }}
      >
        <span style={{ marginRight: '0.4rem' }}>
          <RemarkIcon />
          {
            (document?.remark!= null) &&
            <span style={{ position:'relative', top:'-8px', left:'-4px'}}>
                <RedDotIcon />
            </span>
          }
        </span>
        <span style={{ fontSize: '12px', fontWeight: '500' }}>Remarks</span>
      </Button>
    </Col> 
    
    <Col style={{ width: '8%' }}>
      <Button
        type="primary"
        ghost
        onClick={() => {
          setDocumentActionTargetId(parseInt(document.prospectDocId, 10));
          handleFeedbackNote(document);
        }}
        style={{ border: 'none', fontSize: '14px', fontWeight: 500, marginLeft: '-0.8rem' }}
      >
        <span style={{ marginRight: '0.4rem' }}>
          <RemarkIcon />
          {
          (document?.feedback!= null) &&
          <span style={{ position:'relative', top:'-8px', left:'-4px'}}>
              <RedDotIcon />
          </span>
          
        }
        </span>
        <span style={{ fontSize: '12px', fontWeight: '500' }}>Feedback</span>
      </Button>
    </Col>  
             
              
    <Col style={{ width: '15%',marginLeft:'10px', fontWeight:'400' }}>
      {getFormattedDate(document?.updatedAt)}
    </Col>

    <Col style={{ width: '12%', fontWeight:'400' }}>
      <Tooltip title={JSON.parse(document?.updatedBy)?.name}>
        <div
          style={{
            fontSize: '14px',
            fontWeight: '400',
            color: '#404040',
            width: '95%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace:'nowrap'
          }}
        >
          {JSON.parse(document?.updatedBy)?.name}
        </div>
      </Tooltip>
    </Col>  

    <Col style={{ width: '10%', fontWeight:'400' }}>
      <Tooltip title={JSON.parse(document?.requestedBy)?.name}>
        <div
          style={{
            fontSize: '14px',
            fontWeight: '400',
            color: '#404040',
            width: '95%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace:'nowrap'
          }}
        >
          {JSON.parse(document?.requestedBy)?.name}
        </div>
      </Tooltip>
    </Col> 

    {
      ( allowedActionsForProspect?.has('approveOrRejectDocumentReview') && CREDIT_EDITABLE_DOCUMENT_STATE.has(docUploadStatus?.name)   ) && 
      <Col style={{ width: '17%', display: 'flex' }}>
        {
        
        document?.isFileUploaded === "false" ? (
          <div style={{display:'flex', backgroundColor:'#E6F2FD',height:50,paddingTop:'10px', marginLeft:'-12px'}}>
            <Button
              icon={<CrossIcon />}
              disabled= {true}
              style={{
                height: '2rem',
                color: '#AFAFAF',
                backgroundColor: '#E6F2FD',
                fontWeight: '500',
                border: 'none',
                boxShadow: 'none'
              }}
              key="cancel"
            >
              <span style={{ fontSize: '12px', fontWeight: '500' }}>Reject</span>          
            </Button>
            <div style={{ marginTop: '7px' }}><PipeIcon /></div>
            
            <Button
              icon={<TickIcon color='#AFAFAF' />}
              disabled= {true}
              style={{
                height: '2rem',
                color: '#AFAFAF',
                backgroundColor: '#E6F2FD',
                fontWeight: '500', 
                border: 'none',
                boxShadow: 'none'
              }}
              key="confirm"
              // type="primary"
              // onClick={() => handleApproveDocument(document?.prospectDocId)}
            >
              <span style={{ fontSize: '12px', fontWeight: '500' }}>Approve</span>
            </Button>
          </div>
        ) :
        document?.approvalStatus === 'Reject' ? (
          <p style={{ color: '#FF4D4F',fontSize: '12px', fontWeight: '500', backgroundColor: 'rgba(255, 77, 79, 0.10)', padding: '0.4rem 0.8rem', borderRadius: '6px' }}>Rejected</p>
        ) : document?.approvalStatus === 'Approved' 
        ? (
          <p style={{ color: '#14BE43',fontSize: '12px', fontWeight: '500', backgroundColor: 'rgba(44, 227, 95, 0.10)', padding: '0.4rem 0.8rem', borderRadius: '6px' }}>Approved</p>
        ) : (
          <div style={{display:'flex', backgroundColor:'#E6F2FD',height:50,paddingTop:'10px', marginLeft:'-12px'}}>
            <Button
              icon={<CrossIcon />}
              style={{
                height: '2rem',
                color: '#FF4D4F',
                backgroundColor: '#E6F2FD',
                fontWeight: '500',
                border: 'none',
                boxShadow: 'none'
              }}
              key="cancel"
              onClick={() => handleRejectDocument(document)}
            >
              <span style={{ fontSize: '12px', fontWeight: '500' }}>Reject</span>          
            </Button>
            <div style={{ marginTop: '7px' }}><PipeIcon /></div>
            <Button
              icon={<TickIcon color='#1777FF' />}
              style={{
                height: '2rem',
                color: '#1777FF',
                backgroundColor: '#E6F2FD',
                fontWeight: '500',
                boxShadow: 'none'
              }}
              key="confirm"
              type="primary"
              onClick={() => handleApproveDocument(document?.prospectDocId)}
            >
              <span style={{ fontSize: '12px', fontWeight: '500' }}>Approve</span>
            </Button>
          </div>
        )}
      </Col>
    }          
    
  </Row>   
); 

}

export default DocumentItem;