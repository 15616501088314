import { useNavigate } from "react-router-dom";
import { Table, Spin, Tooltip, Select, Tag, Typography, Row, Button, Col, Input, Space, Checkbox } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { SorterResult } from "antd/es/table/interface";
import { useEffect, useRef, useState } from "react";
import { handlePageChange, handlePageSizeChange } from "../Prospect/utils/paginationFunctions";
import { EyeOutlined, LeftOutlined, MinusOutlined, PlusCircleOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { getRequest } from "../../../utils/handler/apiHandler";
import { ClientList as ClientListType } from "./types/ClientList";
import { getFormattedDate } from "../../../utils/helpers/utils";
import dayjs from "dayjs";
import { ReactComponent as EyeIcon } from '../../../utils/icons/view_icon.svg';
import FilterDropdown from "./FilterDropDown";
import "./table.css"

const INITIAL_SEARCH_MODEL_HEIGHT: number = 100;

const rotatedMinusIconStyle = {
    transform: 'rotate(90deg)',
};

export default function ClientList() {
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageCount, setPageCount] = useState<number>(0);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const defaultData: ClientListType[] = [];
    const [data, setData] = useState<ClientListType[]>(defaultData);
    const tableRef = useRef<HTMLDivElement>(null);
    const [tableHeight, setTableHeight] = useState<number>(600);
    const [sortOrder, setSortOrder] = useState<string>('&sort=clientId:desc'); // Default sort
    const [businessNameOptions, setBusinessNameOptions] = useState([]);
    const [clientIdList, setClientIdList] = useState([]);
    const [ownerList, setownerList] = useState([]);
    const [contractList, setContractList] = useState([]);



    

    const handlePageSizeChangeLocal = (current: number, size: number) => {
        handlePageSizeChange(current, size, setPageSize, setCurrentPage);
    };

    const handlePageChangeLocal = (page: number) => {
        handlePageChange(page, setCurrentPage);
    };

    

    

    useEffect(() => {
        fetchAllRequestedQuoteTableData();
    }, [currentPage, pageSize, sortOrder]);

    const fetchAllRequestedQuoteTableData = async (): Promise<void> => {
        try {
            setLoading(true);
            const getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/allClientsOnBoard?page=${currentPage - 1}&size=${pageSize}${sortOrder ? sortOrder : ""}`;
            const res = await getRequest(getApi);
            if (res.status === 200) {
                setData(res.data.data);
                if ('pageCount' in res.data) {
                    setPageCount(res.data.pageCount);
                }
                if ('totalRecord' in res.data) {
                    setTotalRecord(res.data.totalRecord);
                }
            } else {
                console.error('Error fetching Requested Quotes Table List:', res.msg);
            }
        } catch (error) {
            console.error('Error fetching Requested Quotes data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleBusinessNameSelect = (value: any) => {
        // Apply filter logic using the selected business name
        console.log('Selected Legal Business Name ID:', value);
        // Call API with the selected value if needed
    };

    const getColumns = (): ColumnsType<any> => [
        {
            title: "Client ID",
            dataIndex: "clientId",
            key: "clientId",
            filters: clientIdList.map((option: any) => ({
                text: option,
                value: option,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    width={200}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/getClientIds?page=${page}&size=10&number=${name}`}}
                    setBusinessNameOptions={setClientIdList}
                />
            ),
            width: "10%",
        },
        {
            title: "Legal Business Name",
            dataIndex: 'legalBusinessName',
            filters: businessNameOptions.map((option: any) => ({
                text: option.name,
                value: option.id,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/legalBussinessNames?page=${page}&size=10&name=${name}`}}
                    setBusinessNameOptions={setBusinessNameOptions}
                />
            ),
            key: 'legalBusinessName',
            width: '14%',
        },
        {
            title: "Onboarding Date",
            dataIndex: "onBoardingDate",
            key: "onBoardingDate",
            render: (date: string) => date ? getFormattedDate(date) : "",
            width: "14%",
            sorter: { multiple: 4 },
        },
        {
            title: "Owned By",
            dataIndex: "ownedBy",
            key: "ownedBy",
            filters: ownerList.map((option: any) => ({
                text: option.name,
                value: option.ssoId,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/owners?page=${page}&size=10&name=${name}`}}
                    setBusinessNameOptions={setownerList}
                />
            ),
            width: "10%",
        },
        {
            title: "Credit Limit",
            dataIndex: "creditLimit",
            key: "creditLimit",
            sorter: { multiple: 2 },
            render: (amount: number) => `₹ ${amount}`,
            width: "10%",
        },
        {
            title: "Contract No",
            dataIndex: "contractNumber",
            key: "contractNumber",
            filters: contractList.map((option: any) => ({
                text: option,
                value: option,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/getContractNumber?page=${page}&size=10&request=${name}`}}
                    setBusinessNameOptions={setContractList}
                />
            ),
            width: "10%",
        },
        {
            title: "Contract Execution Date",
            dataIndex: "contractExecutionDate",
            key: "contractExecutionDate",
            render: (date: string) => date ? dayjs(date).format("DD MMM YYYY") : "",
            width: "14%",
            sorter: { multiple: 3 },
        },
        {
            title: "Contract Validity",
            dataIndex: "contractValidity",
            key: "contractValidity",
            render: (date: string) => date ? dayjs(date).format("DD MMM YYYY") : "",
            width: "12%",
            sorter: { multiple: 4 },
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <Tooltip title="View Details">
                    <a onClick={() => navigate(`${record.clientId}`)}>
                        <EyeIcon style={{ marginRight: '0.6rem' }} /> View Details
                    </a>
                </Tooltip>
            ),
            width: "14%",
        },
    ];

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<ClientListType> | SorterResult<ClientListType>[]
    ) => {
        let sortString = '';
        let filterString = ""

        if (Array.isArray(sorter)) {
            // Handle multiple sorts
            sortString = sorter
                .filter(s => s.order) // Filter out columns without sorting
                .map(s => `${s.field}:${s.order === 'ascend' ? 'asc' : 'desc'}`) // Map each sorter to the format field:asc/desc
                .join(',');
        } else if (sorter.order) {
            // Handle single sort
            const sortField = sorter.field;
            const sortOrder = sorter.order === "ascend" ? "asc" : "desc";
            sortString = `${sortField}:${sortOrder}`;
        }

        if (sortString && sortString !== "") {
            sortString = "&sort=" + sortString
        }
        if (filters?.legalBusinessName?.length > 0) {
            filterString = filterString+`&legalEntityIds=${encodeURIComponent(filters?.legalBusinessName)}`
        }
        if (filters?.clientId?.length > 0) {
            filterString = filterString+`&clientIds=${encodeURIComponent(filters?.clientId)}`
        }
        if (filters?.ownedBy?.length > 0) {
            filterString = filterString+`&ownerIds=${encodeURIComponent(filters?.ownedBy)}`
        }
        if (filters?.contractNumber?.length > 0) {
            filterString = filterString+`&contractNumbers=${encodeURIComponent(filters?.contractNumber)}`
        }
        
        // Set the sort order string for the API call
        setSortOrder(sortString + filterString);
    };

    return (
        <div className="parent-container">
            <div className="container-layout">
                <Row justify="space-between" align="top" style={{ marginBottom: 20 }}>
                    <Col>
                        <Typography style={{ fontSize: 20, fontWeight: 700 }}>Clients</Typography>
                    </Col>
                    <Col>
                        <Button onClick={() => navigate('onboard-client')} type="primary" size="large">
                            <PlusCircleOutlined /> Onboard New Client
                        </Button>
                    </Col>
                </Row>

                <div style={{ backgroundColor: '#F6FAFF', height: '100%' }}>
                    <div ref={tableRef} style={{ height: 0.80 * tableHeight }}>
                        <Spin spinning={loading} size="large">
                            <Table
                                className="lev-table"
                                columns={getColumns()}
                                dataSource={data || []}
                                pagination={{
                                    pageSize: pageSize,
                                    position: ["bottomRight"],
                                    showSizeChanger: false,
                                    showTotal: (total, range) => (
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop: '-4px' }}>
                                            <div style={{ fontSize: '1rem' }}>
                                                <span style={{ fontSize: 14, fontWeight: 400 }}>Showing</span>
                                                <Select defaultValue={10} style={{ width: 60, margin: '0 5px' }} onChange={(value) => handlePageSizeChangeLocal(1, value)}>
                                                    <Select.Option value="10">10</Select.Option>
                                                    <Select.Option value="20">20</Select.Option>
                                                    <Select.Option value="50">50</Select.Option>
                                                    <Select.Option value="100">100</Select.Option>
                                                </Select>
                                                <span style={{ fontSize: 15, fontWeight: 400 }}>per page</span>
                                                <span style={{ fontSize: 15, fontWeight: 400, marginRight: '10px', marginLeft: '10px', color: 'grey' }}><MinusOutlined style={rotatedMinusIconStyle} /></span>
                                                <span style={{ fontSize: 14, fontWeight: 400 }}>{`Results: ${range[0]}-${range[1]} of ${total}`}</span>
                                            </div>
                                        </div>
                                    ),
                                    onChange: handlePageChangeLocal,
                                    current: currentPage,
                                    total: totalRecord,
                                    itemRender: (current, type, originalElement) => {
                                        if (type === 'prev') {
                                            return <LeftOutlined style={{ marginLeft: '4rem', fontSize: 12, fontWeight: 500 }} />;
                                        }
                                        if (type === 'next') {
                                            return <RightOutlined style={{ fontSize: 12, fontWeight: 500 }} />;
                                        }
                                        if (type === 'page') {
                                            return (
                                                <span style={{ cursor: 'pointer', fontSize: 12, fontWeight: 500 }} onClick={() => handlePageChangeLocal(current)}>
                                                    {current}
                                                </span>
                                            );
                                        }
                                        if (type === 'jump-next' || type === 'jump-prev') {
                                            return <a style={{ color: 'black', fontSize: 12, fontWeight: 500 }}><strong> ... </strong></a>;
                                        }
                                        return originalElement;
                                    },
                                }}
                                onChange={handleTableChange} // Handle sort
                                scroll={{ x: 1700, y: 0.80 * tableHeight }}
                                size="middle"
                            // components={{
                            //     header: {
                            //       wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
                            //     },
                            //   }}
                            />
                        </Spin>
                    </div>
                </div>
            </div>
        </div>
    );
}
