import { Tooltip, Select } from 'antd';
import { EditOutlined, EyeOutlined, BarsOutlined, FilterOutlined, MenuOutlined, ArrowUpOutlined, ArrowDownOutlined, CaretUpOutlined, CaretDownOutlined, SearchOutlined, EyeFilled } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import CustomSelectFilter from '../../Prospect/utils/CustomSelectFilter';
import '../../Prospect/utils/CustomSelectFilter.css'; // Import custom CSS for styling
import { ReactComponent as SortFilterIcon } from './../../../../utils/icons/Vector.svg'; // Importing SVG file
import { ReactComponent as EyeIcon } from './../../../../utils/icons/view_icon.svg'; // Importing SVG file
import UnionFilterIcon from "../../Prospect/utils/SVGs/UnionFilterIcon";
import SortUpperFadedIcon from '../../Prospect/utils/SVGs/SortUpperFadedIcon';
import SortLowerFadedIcon from '../../Prospect/utils/SVGs/SortLowerFadedIcon';
import { getFormattedDateWithoutChangingTimezone } from '../../../../utils/helpers/utils';

const { Option } = Select;

export const getDocumentReviewRequestsColumns = (
  statusList: any[],
  businessSegmentsList: any[],
  selectedStatus: string[],
  selectedBusinessSegments: string[],
  setSelectedStatus: (selectedStatus: string[]) => void,
  setSelectedBusinessSegments: (selectedBusinessSegments: string[]) => void,
  displayProspectSearchFilterScreen: boolean,
  setDisplayProspectSearchFilterScreen: (displayProspectSearchFilterScreen: boolean) => void,
  setDisplayOwnerSearchFilterScreen: (displayOwnerSearchFilterScreen: boolean) => void,
  setDisplayUpdatedByFilterScreen: (displayUpdatedBySearchFilterScreen: boolean) => void,
  editProspect: (payload: any) => void,
  agingSortingOrder: 'ascending' | 'descending' | null,
  handleUpdatedAtOrder: () => void,
  requestedAtOrder: 'ascending' | 'descending' | null,
  handleRequestedAtOrder: () => void,
  setMainCurrentPage: (currentPage: number) => void,
  selectedProspectsList: any[],
  selectedOwnersList: any[],
  selectedUpdatedByList: any[],
  allowedActionsForDocumentReview: Set<string>,
): ColumnsType<any> => {
  
  const AgingSortIcon = agingSortingOrder === 'ascending' ? <CaretUpOutlined style={{ fontSize: '1.3rem'}} /> : <CaretDownOutlined style={{ fontSize: '1.3rem'}}/>;
  const FILTER_ICON_COLOR = '#1777FF';
  const filterIcon = () => (
    <UnionFilterIcon fill={selectedStatus.length===0 ? '#2C2C2C' : FILTER_ICON_COLOR} style={{ marginLeft: "12px" }} />
  );
  const segmentFilterIcon = (filtered:any) => (
    <UnionFilterIcon fill={selectedBusinessSegments.length===0 ? '#2C2C2C' : FILTER_ICON_COLOR} style={{ marginLeft: "12px" }} />
  );
  const sortRequestedAtFilterIcon = () => (
    requestedAtOrder === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : requestedAtOrder === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : <SortFilterIcon  style={{ marginLeft: "12px", marginTop:'5px' }} />
  );

  const sortUpdatedAtFilterIcon = () => (
    agingSortingOrder === 'descending' ? <SortUpperFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : agingSortingOrder === 'ascending' ?  <SortLowerFadedIcon iconFill={ FILTER_ICON_COLOR}  style={{ marginLeft: "12px", marginTop:'5px' }} />
      : <SortFilterIcon  style={{ marginLeft: "12px", marginTop:'5px' }} />
  );

  const handleProspectSearchIconClick = () => {
    setDisplayProspectSearchFilterScreen(!displayProspectSearchFilterScreen);
  };
  const handleOwnerSearchIconClick = () => {
    setDisplayOwnerSearchFilterScreen(true);
  };
  const handleUpdatedByIconClick = () => {
    setDisplayUpdatedByFilterScreen(true);
  };
  
  return [
    {
      title: 'Request no.',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      width: 100,
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{}}>Prospect Name</span>
          <SearchOutlined onClick={handleProspectSearchIconClick} style={{ marginLeft: '12px', cursor: 'pointer', color: selectedProspectsList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
        </div>
      ),
      dataIndex: 'prospectName',
      key: 'prospectName',
      width: 160,
      render: (text: string) => (
        <Tooltip title={text}>
          <div style={{ width: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
        title: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{}}>Prospect Owner</span>
            <SearchOutlined onClick={handleOwnerSearchIconClick} style={{ marginLeft: '12px', cursor: 'pointer', color: selectedOwnersList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
          </div>
        ),
        dataIndex: 'prospectOwner',
        key: 'prospectOwner',
        width: 140,
        render: (text: string) => (
          <Tooltip title={text}>
            <div style={{ width: 130, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {text}
            </div>
          </Tooltip>
        ),
    },
    {
        dataIndex: 'requestedAt',
        key: 'requestedAt',
        width: 170,
        render: (text: string) => text != null ? `${getFormattedDateWithoutChangingTimezone(text)} ` : <span style={{fontSize: 12}}>NA</span>,
        sortDirections: ['ascend', 'descend'],
        title: (
          <div onClick={handleRequestedAtOrder} style={{ cursor: 'pointer', display:'flex' }}>
            <span style={{marginRight:'0.5rem'}}>Requested at</span> 
            {sortRequestedAtFilterIcon()}
          </div>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'docStatus',
      key: 'docStatus',
      width: 160,
      filterIcon: filterIcon, 
      filterDropdown: (
        <div style={{ padding: 8, width: 180 }}>
          <CustomSelectFilter
            itemsList={statusList}
            selectedItems={selectedStatus}
            setSelectedItems={setSelectedStatus}
            placeholder="Status"
            setMainCurrentPage={setMainCurrentPage}
          />
        </div>
      ),
      onFilter: (value: any, record: any) => record.stage.id === value,
      render: (text: any, record:any) => {
        return (
          <div style={{display: "flex", alignItems: "center"}}>
            <Tooltip title={text}>
              <div style={{ color: record?.docStatusColor, fontWeight: '500', backgroundColor: record?.docStatusbackgroundColor, padding: '0 8px', borderRadius: '4px', display: 'inline-block', maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {text}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        width: 170,
        render: (text: string) => text != null ? `${getFormattedDateWithoutChangingTimezone(text)}` : <span style={{fontSize: 12}}>NA</span>,
        sortDirections: ['ascend', 'descend'],
        title: (
          <div onClick={handleUpdatedAtOrder} style={{ cursor: 'pointer', display:'flex' }}>
            <span style={{marginRight:'0.5rem'}}>Updated at</span> 
            {sortUpdatedAtFilterIcon()}
          </div>
        ),
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{}}>Updated by</span>
          <SearchOutlined onClick={handleUpdatedByIconClick} style={{ marginLeft: '12px', cursor: 'pointer', color: selectedUpdatedByList.length!==0 ? FILTER_ICON_COLOR : 'black'}} />
        </div>
      ),
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 140,
      render: (text: string) => (
        <Tooltip title={text}>
          <div style={{ width: 130, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 120,
      align: "left",
      render: (text, record) => (
        <div style={{display: "flex", justifyContent: "flex-start"}}>
          {allowedActionsForDocumentReview?.has('viewDetails') ?
              <Tooltip placement="bottom" title={"View prospect"}>
                <a onClick={() => editProspect(`/dashboard/prospect/${record.prospectId}?tab=3`)}>
                  <EyeIcon style={{marginRight:'0.6rem'}}/>
                  View Details
                </a>
              </Tooltip>
              :
              <Tooltip placement="bottom" title={"No Access"}>
                <EyeIcon style={{marginRight:'0.6rem', color : 'grey'}}/>
                <span style={{ color : 'grey'}}>View Details</span>
              </Tooltip>
          }



         
        </div>

      ),
    },
    
  ];
};
