import React, { useState, useEffect } from 'react';
import { Form, Checkbox, Tooltip, Card } from 'antd';
import { MenuResponse } from './types';
import { titleCaseNew } from '../../../../utils/helpers/titleCase';

import { ReactComponent as ArrowDown } from '../../Prospect/utils/SVGs/ArrowDown.svg';
import { ReactComponent as ArrowRight } from '../../Prospect/utils/SVGs/ArrowRight.svg';
import './rbacStyle.css';

interface MenuRendererProps {
  menuData: MenuResponse[];
  checkedActions: { [subMenuId: number]: { [action: string]: boolean } };
  onCheckboxChange: (subMenuId: number, action: string, checked: boolean) => void;
}

const MenuRenderer: React.FC<MenuRendererProps> = ({ menuData, checkedActions, onCheckboxChange }) => {
  // State to manage expanded menus
  const [expandedMenus, setExpandedMenus] = useState<{ [key: string]: boolean }>({});

  // Initialize the state to expand the first menu by default
  useEffect(() => {
    if (menuData.length > 0) {
      setExpandedMenus({ [menuData[0].menu]: true });
    }
  }, [menuData]);

  // Toggle the expanded state for a menu
  const handleMenuToggle = (menuName: string) => {
    setExpandedMenus(prevState => ({
      ...prevState,
      [menuName]: !prevState[menuName]
    }));
  };

  // Render menu items
  const renderMenu = () => (
    menuData.map(menuItem => (
      <div key={menuItem.menu}>
        <h3 
          style={{ 
            marginBottom: '20px', 
            backgroundColor: "#A7C6ED", 
            height: '2.5rem', 
            padding: '10px 20px', 
            borderRadius: '5px' 
          }} 
          onClick={() => handleMenuToggle(menuItem.menu)}
        >
          {titleCaseNew(menuItem.menu) + " Menu"}
          {expandedMenus[menuItem.menu] ? <ArrowDown style={{ marginLeft: '8px' }} /> : <ArrowRight style={{ marginLeft: '8px' }} />}
        </h3>
        {expandedMenus[menuItem.menu] && menuItem.submenu.map((subMenu, index) => {
          let actions: string[] = [];
          let allowedActions: string[] = [];
  
          try {
            actions = subMenu?.actions ? JSON.parse(subMenu.actions) : [];
          } catch (e) {
            console.error('Error parsing actions:', e);
          }
  
          try {
            allowedActions = subMenu?.allowedActions ? JSON.parse(subMenu.allowedActions) : [];
          } catch (e) {
            console.error('Error parsing allowedActions:', e);
          }
  
          return (
            <Card 
              key={subMenu.id || index}  // Ensure a unique key for each Card, fallback to index if id is not available
              bordered={true} 
              style={{ margin: '20px', padding: "0px 20px" }}
            >
              <div>
                <h4 style={{ marginBottom: '10px' }}>{subMenu.menuName}</h4>
                <Form>
                  <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                    {Array.isArray(actions) && actions.map((action, actionIndex) => (
                      <div 
                        key={action + actionIndex}  // Ensure a unique key for each action, combining action and index
                        style={{ width: '16rem' }}
                      >
                        <Form.Item valuePropName="checked">
                          <Checkbox
                            checked={checkedActions[subMenu.id]?.[action] || false}
                            onChange={(e) => onCheckboxChange(subMenu.id, action, e.target.checked)}
                            className='custom-checkbox'
                          >
                            <Tooltip title={action}>
                              <p style={{ width: "11rem", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{action}</p>
                            </Tooltip>
                          </Checkbox>
                        </Form.Item>
                      </div>
                    ))}
                  </div>
                </Form>
              </div>
            </Card>
          );
        })}
      </div>
    ))
  );
  

  return (
    <div>
      {renderMenu()}
    </div>
  );
};

export default MenuRenderer;
