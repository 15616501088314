import React, { useEffect, useState } from 'react';
import DocumentHeader from './Document/DocumentHeader';
import DocumentBody from './Document/DocumentBody';
import { getRequest } from '../../../../utils/handler/apiHandler';
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../app/hooks";
import { userSelector } from "../../../../features/auth/userSlice";
import _ from "lodash";

import getDynamicAction from "../../../../utils/helpers/dynamicAction";
import DocumentAction from './Document/DocumentAction';

interface DocumentsProps {
    segment: string;
    creditRequestStatus: number;
    allowedActionsForProspect: Set<string>;
    stageId: number
}

const Documents: React.FC<DocumentsProps> = ({ segment, creditRequestStatus, allowedActionsForProspect, stageId }) => {
    const [documents, setDocuments] = useState<any[]> ([]);
    const [showCancelRequest, setShowCancelRequest] = useState<boolean>(false);
    const [reviewForRequestAllowed, setReviewForRequestAllowed] = useState<boolean>(false);
    const [docUploadStatus,setDocUploadStatus] = useState<any>({});
    const [loader, setLoader] = useState(false);
    const [documentStatusComplete, setDocumentStatusComplete] = useState<boolean>(false);
    const [showSubmitFeedback, setShowSubmitFeedback] = useState<boolean>(false);
    const [showCompleteReview, setShowCompleteReview] = useState<boolean>(false);
    const [reloadDocumentTab, setReloadDocumentTab] = useState<number>(1);
    const [buttonFlag, setButtonFlag] = useState<boolean>(false);

    const navigate = useNavigate();
    const url = useLocation();
    const prospectId = url.pathname.split("/").pop()
    const userRole = useAppSelector(userSelector)?.role;
    
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                setLoader(true);
                const res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/new?prospectId=${prospectId}`);
                if (res.status === 404 || res.status === 400) {
                    navigate("/dashboard/prospect");
                    return;
                }
                if(res.data?.data){
                    setDocuments(res.data?.data);
                }
                else{
                    setDocuments([]);
                }
                setShowSubmitFeedback(res?.data?.showSubmitFeedback);
                setShowCancelRequest(res.data?.showCancelRequest);
                setShowCompleteReview(res.data?.showCompleteReview);
                setDocUploadStatus(res.data?.docUploadStatus);
                setReviewForRequestAllowed(res.data?.reviewForRequestAllowed);

                res.data?.docUploadStatus && setDocumentStatusComplete(res.data?.docUploadStatus["name"] === "Review Completed");
                setLoader(false);

                !_.isEmpty(res.data) && getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/prospectDoc/showRaiseAndBypassCreditRequest?prospectId=${prospectId}`).then(res => {
                    setButtonFlag(res.data)
                })

            } catch (error) {
                console.error("Error fetching documents:", error);
            }
        };
        
        fetchDocuments();
        
    }, [reloadDocumentTab]);
    

    return ( 
        <React.Fragment>
            <div className="container-inner">
                <div className="container-inner-section">
                    <DocumentHeader allowedActionsForProspect={allowedActionsForProspect} documents={documents} 
                        buttonLabel = {documents.length===0 ? 'Add Docs' : 'Modify Docs'} docUploadStatus={docUploadStatus} userRole={userRole} 
                        setReloadDocumentTab={setReloadDocumentTab}/>
                </div>
                <div className="container-inner-section">
                    <DocumentBody stageId={stageId} allowedActionsForProspect={allowedActionsForProspect} userRole={userRole}
                     documents={documents} loader={loader} showCancelRequest={showCancelRequest} showCompleteReview={showCompleteReview} 
                     showSubmitFeedback={showSubmitFeedback} docUploadStatus={docUploadStatus} reviewForRequestAllowed={reviewForRequestAllowed}
                      setReloadDocumentTab={setReloadDocumentTab}/>
                </div>
                {
                documentStatusComplete && <div className="container-inner-section">
                    <DocumentAction stageId={stageId} segment={segment} allowedActionsForProspect={allowedActionsForProspect} creditRequestStatus={creditRequestStatus} reviewForRequestAllowed={reviewForRequestAllowed} buttonFlag={buttonFlag} />
                </div>
                }
            </div>
        </React.Fragment>
    );
}

export default Documents;