import { useState } from "react";
import useActionEffects from "./useActionEffects";
import { Button, Col, Form, Input, Row, message } from "antd";
import MenuSelect from "../Submenu/MenuSelect";
import SubmenuSelect from "./SubmenuSelect";
import { ReactComponent as CrossIcon } from '../../../SVGs/cross_icon.svg';

const AddActions = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedMenu, setSelectedMenu] = useState<string | null>(null);
  const [selectedSubmenu, setSelectedSubmenu] = useState<string | null>(null);
  const [actions, setActions] = useState<string[]>(['']); // Array to hold action strings

  const { menuData, setMenuData, submenuData, fetchSubmenuData } = useActionEffects(setLoading);

  const handleMenuChange = (parentId: string) => {
    setSelectedMenu(parentId);
    fetchSubmenuData(parentId);
  };

  const handleSubmenuChange = (submenuId: string) => {
    setSelectedSubmenu(submenuId);
  };

  const handleActionChange = (index: number, value: string) => {
    const newActions = [...actions];
    newActions[index] = value;
    setActions(newActions);
  };

  const addActionField = () => {
    setActions([...actions, '']); // Add a new empty action input
  };

  const removeActionField = (index: number) => {
    const newActions = actions.filter((_, i) => i !== index);
    setActions(newActions); // Remove the action at the specified index
  };

  const onFinish = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/menus/addActionsForSubMenu`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subMenuId: selectedSubmenu,
          actions: actions.filter(action => action).map(action => action.trim()), // Filter out empty actions
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to add actions');
      }

      message.success('Actions added successfully');
      setTimeout(() => window.location.reload(), 3000);
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ margin: '20px' }}>
      <Form>
        <MenuSelect
          menus={menuData}
          selectedMenu={selectedMenu}
          onMenuChange={handleMenuChange}
        />
      </Form>
      {selectedMenu && (
        <Form>
          <SubmenuSelect
            submenus={submenuData}
            selectedSubmenu={selectedSubmenu}
            onSubmenuChange={handleSubmenuChange}
          />
        </Form>
      )}
      {selectedSubmenu && (
        <Form
          layout="vertical"
          onFinish={onFinish}
          style={{ margin: '20px' }}
        >
          {actions.map((action, index) => (
            <Row gutter={16} key={index}>
              <Col span={11}>
                <Form.Item
                  label={`Action ${index + 1}`}
                  rules={[{ required: true, message: 'Please input the action name!' }]}
                >
                  <Input
                    value={action}
                    onChange={e => handleActionChange(index, e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                {actions.length > 1 && (
                  <Button type="link" onClick={() => removeActionField(index)} style={{ color: 'red', marginTop: '30px' }}>
                    <CrossIcon style={{ marginRight: '10px' }} />
                    Remove
                  </Button>
                )}
              </Col>
            </Row>
          ))}
          <Button type="dashed" onClick={addActionField} style={{ width: '100%' }}>
            Add More Action
          </Button>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} style={{ marginTop: '20px' }}>
              Submit Actions
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default AddActions;
