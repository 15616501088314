import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Input, Tabs, Tag, Tooltip, Typography } from 'antd';
import ProspectDetail from "./components/ProspectDetail";
import ProspectHistory from "./components/ProspectHistory";
import { ReactComponent as TransferIcon } from './utils/SVGs/transfer_icon.svg';
import { ReactComponent as AddIcon } from './utils/SVGs/add_icon.svg';
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { getRequest, putRequest } from "../../../utils/handler/apiHandler";
import { menuSelector } from "../../../features/menu/menuSlice";
import { useAppSelector } from "../../../app/hooks";
import { userSelector } from "../../../features/auth/userSlice";
import getDynamicAction from "../../../utils/helpers/dynamicAction";
import ChangeStateModal from "../../../utils/helpers/ChangeStateModal";
import { ReactComponent as TickIcon } from './utils/SVGs/tick_icon.svg';
import { ReactComponent as CrossIcon } from './utils/SVGs/cross_icon.svg';
import { ReactComponent as SaveIcon } from './utils/SVGs/save_icon.svg';
import { ReactComponent as EditIcon } from './utils/SVGs/edit_prospect_icon.svg';
import { getStageStyles } from "./utils/stageUtils";
import "../../../styles/Pages.sass"
import ReactQuill from "react-quill";
import ConfirmCancelPopup from "../../../utils/ConfirmPopupCustom";
import { convert } from 'html-to-text';
import { ADMIN, SALES_HEAD, SALES_MANAGER } from "../constants";
import Documents from "./components/Documents";
import CreditAssessment from "./components/CreditAssessment";
import {getAllowedActions} from "../../../utils/helpers/allowedAction";
import NoAccess from "./utils/NoAccess";

const TabPane = Tabs.TabPane;
const { Title } = Typography;
interface Stage {
  name: string;
  color: string;
  backgroundColor: string;
}
const WON_STAGE_ID = 11, LOST_STAGE_ID = 10;

const ProspectPageLayout = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [prospectName, setProspectName] = useState<any>();
  const [owner, setProspectOwner] = useState<any>();
  const [stage, setProspectStage] = useState<Stage>({
    name: "",
    color: "",
    backgroundColor: ""
  });
  const [stageId, setStageId] = useState<number>();
  const [stageOrder, setStageOrder] = useState<number>();
  const [disableEditOrAddSection, setDisableEditOrAddSection] = useState<boolean>(false);
  const [changeStateModalVisible, setChangeStateModalVisible] = useState(false);
  const [editable, setEditable] = useState(true);
  const [prospectNameEditable, setProspectNameEditable] = useState(false);
  const [newProspectName, setNewProspectName] = useState("");
  const [noteText, setNoteText] = useState("");
  const url = useLocation();
  const prospectId = url.pathname.split("/").pop();
  const menus = useAppSelector(menuSelector).menu;
  const menuActions = useAppSelector(menuSelector).menuActions;

  const [refreshNoteData, setRefreshNoteData] = useState<boolean>(false);
  const [isConfirmNoteCancelVisible, setIsConfirmNoteCancelVisible] = useState(false);
  const [isAddTextModalVisible, setIsAddTextModalVisible] = useState(false);
  
  const user = useAppSelector(userSelector);

  const allowedActionsForProspectList: string[] | undefined = menuActions?.['Prospect'];
  const allowedActionsForProspect = new Set(allowedActionsForProspectList);
  const cardRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const initialTab = query.get('tab');

  // State to manage the active tab
  const [activeKey, setActiveKey] = useState(initialTab || '1');

   // Effect to set the initial active tab based on the query parameter
   useEffect(() => {
    if (initialTab) {
      setActiveKey(initialTab);
    }
  }, [initialTab]);

  useEffect(() => {
      setLoader(true)
      getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospect/${prospectId}`).then(res => {
          if(res.status === 404 || res.status === 400) {
              navigate("/dashboard/prospect");
              return
          }
          if(res.status !== 403)
          {
            setProspectName(res.data.name);

            const colors = getStageStyles(res.data.stage.id);
            setProspectStage({
              name: res.data.stage.name,
              color: colors.color,
              backgroundColor: colors.backgroundColor
            });
            
            setStageId(res.data.stage.id);
            setStageOrder(res.data.stage.order);
            setProspectOwner(JSON.parse(res.data.owner).name);
            setLoader(false)

          }
        });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        handleNoteCancel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [noteText]);

  const onStateModalClose = () => {
    setChangeStateModalVisible(false);
  }
  const onStateModalSave = () => {
    setChangeStateModalVisible(false);
    window.location.reload();            
  }

  const updateNote = async (): Promise<void> => {
    try{
      setLoader(true);
      const payload = {"notes":[{data : noteText}]}
      await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospect/${prospectId}`,
      payload);
      setNoteText("");
      setRefreshNoteData(true);
      setIsAddTextModalVisible(false);
    }catch( error) {
      console.error('Error in updating prospect note:', error);
    }finally{
      setLoader(false);
    }
  };

  const handleChange = (content: any) => {
    setNoteText(content);
    setIsConfirmNoteCancelVisible(false);
  };

  const onSave = () => {
    updateNote();
    setNoteText('');
    setIsAddTextModalVisible(false);
  }

  const getNoteLength = (note : string) => {
    const finalText  = convert(note);
    if(finalText == '\n') return 0;
    else return finalText.length;
  }

  const handleConfirmNoteCancelModalSave = () => {
    setIsConfirmNoteCancelVisible(false);
    onSave();
  };

  const handleConfirmNoteCancelModalDontSave = () => {
    setIsConfirmNoteCancelVisible(false);
    setNoteText('');
  }

  const handleConfirmNoteCancelModalCancel = () => {
    setIsConfirmNoteCancelVisible(false);
    setIsAddTextModalVisible(true);
  };

  const handleNoteCancel = ()=>{
    if(getNoteLength(noteText) > 0){
      setIsConfirmNoteCancelVisible(true);
      setIsAddTextModalVisible(false);
    }
    else setIsAddTextModalVisible(false);
  }

  const handleProspectNameUpdate = async (): Promise<void> => {
    if(newProspectName) {
      try{
        const payload = {"name": newProspectName};
        await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospect/${prospectId}`,
        payload);
        setNewProspectName("");
        setProspectNameEditable(false);
        setProspectName(newProspectName);
      }catch( error) {
        console.error('Error in updating prospect name:', error);
      }
    }
    
  };

  if( !allowedActionsForProspect?.has('viewProspect') )  return <NoAccess/>;

  return (
      <React.Fragment>
        <div className="parent-container">
          <div className="container-layout">
              {/* HEADER SECTION */}
              <div className="container-header">
                  <div className="container-header-title">
                    <div className="container-header-title-section-one" style={{height: 30}}>
                      <LeftOutlined style={{margin: 0, padding: 0}} onClick={_ => navigate("/dashboard/prospect")} />
                      <Tooltip title={prospectName} placement="bottomLeft">
                        <Title level={4} style={{maxWidth: 300, overflow: "hidden", width: loader ? 500 : "auto"}} className={loader ? "shimmer-ui" : ""}>
                        {!prospectNameEditable && prospectName}
                        </Title>
                      </Tooltip>
                      {
                        prospectNameEditable && 
                        <div style={{display: "flex"}}>
                          <Input status={newProspectName ? "" : "error"} defaultValue={prospectName} style={{width: 300}} placeholder="Enter prospect name..." onChange={e=> setNewProspectName(e.target.value)}/>
                          <Button style={{marginLeft: 5}} ghost danger className="button-override danger" icon={<CrossIcon />} onClick={_=> {setProspectNameEditable(!prospectNameEditable); setNewProspectName("")}}>Cancel</Button>
                          <Button style={{marginLeft: 5}} ghost className={ newProspectName !=="" ? "button-override primary" : "button-override disable" } disabled={newProspectName===""} type="primary" icon={<TickIcon />} onClick={_=> handleProspectNameUpdate()}>Save</Button>
                          </div>
                        }
                      {
                        allowedActionsForProspect?.has('editName') && !prospectNameEditable &&
                        <EditIcon style={{display: loader ? "none" : "", marginLeft: 10, width: 20, height: 20, color: "#2C66E3", cursor: "pointer"}} onClick={_=> { setProspectNameEditable(!prospectNameEditable); setNewProspectName(prospectName) }}/>
                      }
                      <Tag bordered={false} color={stage.backgroundColor} style={{ padding: "0 8px", fontWeight: 600, fontSize: 12, height: 20, color: stage.color }} className={loader ? "shimmer-ui" : ""}>{stage.name}</Tag>
                    </div>
                    <div className="container-header-title-section-two">
                      <Typography style={{width: "auto", marginRight: 5}}>Owned by:</Typography>
                      <Typography className={loader ? "shimmer-ui" : ""} style={{width: "60%", height: 20}}>{
                        owner
                      }</Typography>

                      {/* TRANSFER OWNERSHIP COMPONENT */}
                      {/* <Tooltip title="Transfer ownership" placement="rightTop" color={"#2C66E3"}>
                        <TransferIcon style={{marginLeft: 10, cursor: "pointer"}} />
                      </Tooltip> */}
                      
                    </div>
                  </div>
                  <div className="container-header-action">
                      <> 
                        {
                        allowedActionsForProspect?.has('addNote') &&
                        <Button
                          disabled={changeStateModalVisible}
                          type="primary"
                          ghost
                          onClick={_ => setIsAddTextModalVisible(true)}
                          className={changeStateModalVisible ? "button-override disable" : "button-override primary"}
                        >
                          <span style={{ marginRight:'0.4rem' }}>
                            <AddIcon />
                          </span>
                          Add Note
                        </Button>
                        }
                        {
                        allowedActionsForProspect?.has('changeState') &&
                        <Button
                          type="primary"
                          ghost
                          disabled={stageId==LOST_STAGE_ID || stageId==WON_STAGE_ID || isAddTextModalVisible}
                          className={stageId==LOST_STAGE_ID || stageId==WON_STAGE_ID || isAddTextModalVisible ? "button-override disable" : "button-override primary"}
                          onClick={_ => setChangeStateModalVisible(true)}
                          style={{marginLeft: 10}}
                          // className="button-override primary"
                        >
                          
                          <span style={{ marginRight:'0.4rem' }}>
                            <TransferIcon />
                          </span>
                          Change State
                        </Button>
                        }
                      </>
                    <ChangeStateModal
                      visible = {changeStateModalVisible}
                      currentState = {stage.name}
                      currentStateId = {stageId || 0}
                      currentStateOrder = {stageOrder || 0}
                      prospectId={prospectId}
                      onSave = {onStateModalSave}
                      onEditorClose = {onStateModalClose}
                    />

                  </div>
              </div>
              {/* TAB SECTION */}
              <div className="container-body">
                  <Tabs activeKey={activeKey} onChange={setActiveKey} className="tab" onTabClick={(e) => navigate(`?tab=${e}`)}>
                    <TabPane tab={<span className="tab-heading">Prospect Detail</span>} key="1">
                      <ProspectDetail 
                        allowedActionsForProspect={allowedActionsForProspect} 
                        disableEditOrAddSection={disableEditOrAddSection} 
                        setDisableEditOrAddSection={setDisableEditOrAddSection} 
                        stageId={stageId}
                        prospectName={prospectName}
                      />
                    </TabPane>
                    {
                      allowedActionsForProspect?.has('prospectHistoryTab') && 
                      <TabPane tab={<span className="tab-heading">Prospect History</span>} key="2">
                        <ProspectHistory 
                          allowedActionsForProspect={allowedActionsForProspect} 
                          refreshNoteData = {refreshNoteData}
                          setRefreshNoteData = {setRefreshNoteData}
                        />
                      </TabPane>
                    }
                    {
                      allowedActionsForProspect?.has('creditAssessmentTab') && 
                      <TabPane tab={<span className="tab-heading">Credit Assessment</span>} key="3">
                        <CreditAssessment 
                          stageId={stageId}
                          allowedActionsForProspect={allowedActionsForProspect} 
                        />
                      </TabPane>
                     } 
                  </Tabs>
              </div>
          </div>

          <Card ref={cardRef} className="notes-container" style={{ display: isAddTextModalVisible ? "block" : "none" }}>
            <div style={{ paddingBottom: 20, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <Typography className="primary-heading">Add Note</Typography>
              <div style={{borderRadius: 99, height: 30, width: 30, display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"}} onClick={_ => {handleNoteCancel()}}>
                <CrossIcon />
              </div>
            </div>
            <ReactQuill
                theme="snow"
                value={noteText}
                onChange={(value) => handleChange(value)}
                placeholder='Type note here'
                style={{display : "flex", flexDirection : "column-reverse", width: "550px", height : "350px"}}
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    ['code-block'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    [{ 'align': [] }],
                    ['link', true],
                    ['clean'],
                    [{ 'header': [1, 2, false] }],
                  ],
                }}
              />
              <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center", paddingTop: 16}}>
                <Button icon={<SaveIcon />} key="confirm" type="primary" style={{background: getNoteLength(noteText) > 0 ? "" : "grey", fontSize: 12, borderRadius: 4}} onClick={_ => {getNoteLength(noteText) > 0 ? updateNote() : null }}>
                  {editable ? "Save" : "Edit"}
                </Button>
              </div>
          </Card>
        </div>
        <ConfirmCancelPopup
                  visible={isConfirmNoteCancelVisible}
                  title={'Save note'}
                  message={'Do you want to save the note before going back'}
                  dontSaveButtonText={`Don't save note`}
                  saveButtonText={`Save note`}
                  onCancel={handleConfirmNoteCancelModalCancel}
                  onDontSave={handleConfirmNoteCancelModalDontSave}
                  onConfirm={handleConfirmNoteCancelModalSave}
                  />

      </React.Fragment>
  );
};
export default ProspectPageLayout;