// RoleSelect.tsx

import React from 'react';
import { Form, Select } from 'antd';
import { Role } from './types';

const { Option } = Select;

interface RoleSelectProps {
  roles: Role[];
  selectedRole: string | null;
  onRoleChange: (roleId: string) => void;
}
const filterOption = (input:any, option:any) => {
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const RoleSelect: React.FC<RoleSelectProps> = ({ roles, selectedRole, onRoleChange }) => {
  return (
  <Form.Item label="Select Role">
    <Select
      showSearch
      style={{ width: 300 }}
      placeholder="Select a role"
      onChange={onRoleChange}
      value={selectedRole || undefined}
      filterOption={filterOption} // Enable search functionality
    >
      {roles.map(role => (
        <Option key={role.id} value={role.id}>
          {role.name}
        </Option>
      ))}
    </Select>
  </Form.Item>
  );
}

export default RoleSelect;
