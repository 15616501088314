import { Button, Tag } from 'antd';
import { ReactComponent as AddIcon } from '../.././utils/SVGs/add_icon.svg';
import { ReactComponent as EditIcon } from '../.././utils/SVGs/edit_icon.svg';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import {getDisableModifyButtonFlag} from './documentUtils'
import AddDocumentSelectModal from './AddDocumentSelectModal';

interface DocumentHeaderProps{
  allowedActionsForProspect: Set<string>;
  documents: any[];
  buttonLabel: string;
  docUploadStatus: any;
  userRole: string;
  setReloadDocumentTab: (reload:number)=>void;
}

const DocumentHeader: React.FC<DocumentHeaderProps> = ({allowedActionsForProspect, documents, buttonLabel, docUploadStatus, userRole, 
    setReloadDocumentTab}) => {
  const [addDocumentSelectModalVisible, setAddDocumentSelectModalVisible] = useState<boolean> (false);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const navigate = useNavigate();
  
  // Using userRole instead of RBAC actions since conditions to disable depends on userRole as well as current document status
  const disableAddModifyDocsButton =  getDisableModifyButtonFlag(userRole, docUploadStatus?.name);
  
  const onAddDocumentSelectModalSave = () => {
    setAddDocumentSelectModalVisible(false);
    setReloadDocumentTab(Math.random());          
  } 
  const onAddDocumentSelectModalClose = () => {
    setAddDocumentSelectModalVisible(false);
  }
  
  return ( 
            <div style={{display: 'flex', justifyContent:'space-between'}}>
                <div style={{display:'flex'}}>
                  <p style={{fontSize:'16px', fontWeight:'700'}}>Documents</p>
                  {
                    docUploadStatus && allowedActionsForProspect?.has('documentReviewRequestHyperlink') ?
                    <a onClick={()=>navigate("/dashboard/credit-document-review-request")}>
                      <Tag style={{marginLeft: 10, padding:'4px 8px', border: "none", color: docUploadStatus?.color, 
                        backgroundColor: docUploadStatus?.backgroundColor, fontWeight: 500}}>{docUploadStatus?.name}</Tag>
                    </a>
                    :
                    <p style={{marginLeft:'1.8rem', padding:'4px 8px',borderRadius:'6px', height:'1.8rem', 
                        fontSize:'14px', fontWeight:'500', color: docUploadStatus?.color,
                        backgroundColor: docUploadStatus?.backgroundColor}} >
                        {docUploadStatus?.name}
                    </p>
                  }
                </div>
                {
                  allowedActionsForProspect?.has('addModifyDocs') && 
                  <Button
                    disabled={disableAddModifyDocsButton}
                    type="primary"
                    ghost
                    onClick={_ => setAddDocumentSelectModalVisible(true)}
                    className={ disableAddModifyDocsButton ? "button-override disable" : "button-override primary"}
                  >
                      <span style={{ marginRight:'0.4rem' }}>
                        {buttonLabel==='Add Docs' ?<AddIcon /> : <EditIcon/>}
                      </span>
                      {buttonLabel}
                  </Button>
                }
                
                {
                  addDocumentSelectModalVisible && 
                  <AddDocumentSelectModal
                      visible = {addDocumentSelectModalVisible}
                      selectedItems={selectedItems}
                      documents={documents}
                      placeholder={`${buttonLabel == 'Add Docs' ? 'Add Documents' : 'Modify Documents'}`}
                      setSelectedItems={setSelectedItems}
                      onSave = {onAddDocumentSelectModalSave}
                      onEditorClose = {onAddDocumentSelectModalClose}
                />
                }
                
            </div>
       
    );
}

export default DocumentHeader;