import { Tabs } from "antd";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RoleAssignmentPage from "./RBAC/RoleAssignmentPage";
import AddActions from "./RBAC/Actions/AddActions";
import AddMenu from "./RBAC/Menu/AddMenu";
import AddSubmenu from "./RBAC/Submenu/AddSubmenu";

const TabPane = Tabs.TabPane;

const RBACPageLayout= ()=>{
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const initialTab = query.get('tab');
  // State to manage the active tab
  const [activeKey, setActiveKey] = useState(initialTab || '1');


    return (
        <Tabs activeKey={activeKey} onChange={setActiveKey} className="tab" 
            onTabClick={(e) => navigate(`?tab=${e}`)} style={{margin:'20px'}}
            >
            <TabPane tab={<span className="tab-heading">Assign Actions</span>} key="1">
                <RoleAssignmentPage />
            </TabPane>
            <TabPane tab={<span className="tab-heading">Add Menu</span>} key="2">
                <AddMenu/>
            </TabPane> 
            <TabPane tab={<span className="tab-heading">Add Submenu</span>} key="3">
                <AddSubmenu/>
            </TabPane>
            <TabPane tab={<span className="tab-heading">Add Actions</span>} key="4">
                <AddActions/>
            </TabPane>
        </Tabs>

    );
}

export default RBACPageLayout;