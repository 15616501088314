// MenuSelect.tsx

import React from 'react';
import { Form, Select } from 'antd';
import { Menu } from '../types';

const { Option } = Select;

interface MenuSelectProps {
  menus: Menu[];
  selectedMenu: string | null;
  onMenuChange: (menuId: string) => void;
}
const filterOption = (input:any, option:any) => {
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const MenuSelect: React.FC<MenuSelectProps> = ({ menus, selectedMenu, onMenuChange }) => {
  return (
  <Form.Item label="Select Menu">
    <Select
      showSearch
      style={{ width: 300 }}
      placeholder="Select a Menu"
      onChange={onMenuChange}
      value={selectedMenu || undefined}
      filterOption={filterOption} // Enable search functionality
    >
      {menus.map(menu => (
        <Option key={menu.id} value={menu.id}>
          {menu.menuName}
        </Option>
      ))}
    </Select>
  </Form.Item>
  );
}

export default MenuSelect;
