import { ArrowRightOutlined, CloseOutlined, DeleteFilled, DeleteOutlined, EyeInvisibleFilled, EyeOutlined, LoadingOutlined, MinusCircleOutlined, PlusOutlined, RightOutlined, SyncOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Select, Space, Spin, Switch, Tooltip, Typography, Upload } from "antd";
import { useEffect, useState } from "react";
import { modalButtonStyle } from "./OnboardNewClient";
import { getRequest, postRequest, putRequest } from "../../../utils/handler/apiHandler";
import axios from "axios";
import { useAppSelector } from "../../../app/hooks";
import { authSelector } from "../../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ViewDocumentModal from "./ViewDocumentModal";

export default function AgreementDetailsForm({ setCurrentStep, docTypes, prospectDetails, prospectId, getDocTypes, disabled }: any) {
    const [fileList, setFileList] = useState<any[]>([]);
    const [form] = Form.useForm()
    const auth = useAppSelector(authSelector);
    // const [letterOfComfortileList, setLetterOfComfortileList] = useState<any[]>([]);
    const [othersToShow, setOthersToShow] = useState<number[]>([])
    const [designations, setDesignations] = useState([])
    const [submitting, setSubmitting] = useState(false)
    const navigate = useNavigate();
    const [showSuccess, setShowSuccess] = useState(false)
    const [showAddNewModal, setShowAddNewModal] = useState(false)
    const [newField, setNewField] = useState("")
    const [submittingNew, setSubmittingNew] = useState(false)
    const [deletingIndex, setDeletingIndex] = useState<number | null>(null)
    const [previewFile, setPreviewFile] = useState<null | {
        "fileName": string,
        "type": string,
        "url": string,
    }>(null)
    const [ongoingUploads, setOngoingUploads] = useState(0);
    const [uploadingAgreement, setUploadingAgreement] = useState<number | null>(null)

    const antIconUpload = <LoadingOutlined style={{ fontSize: 16, color: "black", marginLeft: 6 }} spin />;

    const handleDelete = (file: any, name: number) => {
        setFileList(fileList.map((i: any) => {
            if (name == i.key) {
                return {
                    key: i.key,
                    files: i.files.filter((x: any) => x.uid != file.uid),
                    urls: i.urls.filter((x: any, key: number) => key !== i.files.findIndex((y: any) => y.uid != file.uid))
                }
            } else {
                return i
            }
        }));

    }

    useEffect(() => {
        console.log("docType", docTypes)
        const filteredDocTypes = docTypes.filter((x: any) => (x.name != "MLA" && x.name != "MFMS"))
        console.log(filteredDocTypes, "filteredDocTypes")
        const agreements = filteredDocTypes.map((i: any, key: number) => {
            const oldRemark = form.getFieldValue(["agreements", key, "remarks"])
            const oldValidity = form.getFieldValue(["agreements", key, "validity"])
            return {
                "name": i.name,
                "validity": oldValidity ? oldValidity : i.validity && dayjs(i.validity, "YYYY-MM-DD"),
                "remarks": oldRemark ? oldRemark : i.remarks,
                "clientAgreementDocumentId": i.id,
            }
        })
        form.setFieldValue("agreements", agreements)
    }, [docTypes])


    const handleSubmit = async (values: any) => {
        setSubmitting(true)

        console.log("values", values)
        const payload = {

            "spocRequests": values.spocRequests.map((i: any) => {
                return {
                    "name": i.name,
                    "designation": i.designation.label === "Other" ? i.designationName : i.designation.label,
                    "isOtherDesignation": i.designation.label === "Other",
                    "contactNumber": parseInt(i.contactNumber),
                    "isAuthorisedSignatory": true,
                    "emailId": i.emailId
                }
            }),
            otherClause: values.otherClause
            // creditLimit: prospectDetails?.creditLimit,
        }
        console.log("payload", payload)


        let docParams = values?.agreements.map((i: any, key: number) => {
            if (fileList.find((x: any) => x.key == key)?.files?.length > 0) {
                return {
                    "clientAgreementDocumentId": i.clientAgreementDocumentId,
                    "validity": i.validity ? dayjs(i.validity).format("YYYY-MM-DD") : null,
                    "remarks": i.remarks,
                    "uploadedDocUrl": fileList.find((x: any) => x.key == key).files.map((file: any, index: number) => {
                        const url = fileList.find((x: any) => x.key == key).urls[index]
                        const fileName = url.substring(url.lastIndexOf('/') + 1).split('.')[0]; // Get file name without extension
                        const fileType = url.split('.').pop();

                        return {
                            "fileName": fileName,
                            "url": url,
                            "type": fileType
                        }
                    })
                }
            }
        })

        let cancelParams = values?.agreements.map((i: any, key: number) => {

            if (!(fileList.find((x: any) => x.key == key)?.files?.length > 0)) {
                return i.clientAgreementDocumentId
            }
        })



        cancelParams = cancelParams.filter((i: any) => i)
        docParams = docParams.filter((i: any) => i)
        console.log("docParams", docParams)
        console.log("cancelParamas", cancelParams)


        if (docParams.length > 0) {
            await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/uploadAgreementDocDetails`, docParams).then((res: any) => {
                console.log(res)
            })
        }

        if (cancelParams.length > 0) {
            await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/cancelAgreementDoc?agreementDocIds=${cancelParams.join(',')}`, {}).then((res: any) => {
                console.log(res)
            })
        }


        await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/updateDetails/${prospectDetails?.clientOnBoardingId}`, payload).then(res => {
            console.log(res)
            if (res.status == 200) {
                setShowSuccess(true)
            }
            setSubmitting(false)

        })
        // setShowSuccess(true)

    }

    useEffect(() => {
        getDesignations()
        getDocTypes()
    }, [])

    const getDesignations = () => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/master/designations`).then(res => {
            console.log("res.data", res.data)
            setDesignations(res.data);
        })
    };

    const handleUploadRequest = async (file: any, name: number) => {
        const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/uploadAgreementDoc?prospectId=${prospectId}&isAddressDoc=false`;
        const formData = new FormData();
        formData.append('file', file.file);
        setUploadingAgreement(name)
        setOngoingUploads((prev) => prev + 1);

        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log(response)
                console.log("response?.data", response?.data)

                if (response.status === 200) {

                    setFileList((prevState) => {
                        if (prevState.find((i: any) => i.key == name)) {
                            return prevState.map((i: any) => {
                                if (i.key == name) {
                                    return {
                                        ...i,
                                        urls: [...i.urls, response?.data?.DocumentUrl],
                                        files: [...i.files, file.file]
                                    }
                                } else {
                                    return i
                                }
                            })
                        } else {
                            return [...prevState, {
                                key: name,
                                urls: [response?.data?.DocumentUrl],
                                files: [file.file]
                            }]
                        }
                    })
                    
                }
                else {
                    console.log("error in uploading file")
                }
            })
        } catch (error) {
            console.error('Upload error:', error);
        } finally {
            // Decrement ongoing uploads counter
            setOngoingUploads((prev) => {
                const newOngoingUploads = prev - 1;
                if (newOngoingUploads === 0) {
                    // Disable loader when all uploads complete
                    setUploadingAgreement(null);
                }
                return newOngoingUploads;
            });
        }
    };

   

    useEffect(() => {
        setNewField("")
    }, [showAddNewModal])


    const handleAddNewDoc = () => {
        console.log("add new", newField)
        setNewField("")
        setShowAddNewModal(false)
        addDocTypes()
    }

    const addDocTypes = async () => {
        setSubmittingNew(true)
        let typesToAdd = [
            {
                "id": 6,
                "name": newField,
                "isOtherDocType": true,
                "allowCancel": true,
                "active": true
            }
        ]

        if (typesToAdd.length > 0) {
            await postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/addAgreementDoc?clientOnBoardingId=${prospectDetails?.clientOnBoardingId}`, typesToAdd).then(res => {
                console.log(res)
                setSubmittingNew(false)
                setShowAddNewModal(false)
                getDocTypes()

            })
        }
    }


    return (
        <>

            <Form className="client-onboarding-form" initialValues={{
                
                "spocRequests": [{
                    contactNumber: "",
                    designation: null,
                    emailId: "",
                    name: ""
                }]
            }} onFinish={handleSubmit} form={form} layout="vertical" name="client-details">
                <Form.List name="agreements">
                    {(fields, { add, remove }) => (
                        <>

                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: 40 }}>
                                <div>
                                    <Typography.Text strong style={{ fontSize: 16 }}>Agreements</Typography.Text>
                                </div>
                                <Form.Item style={{ margin: 0 }}>
                                    <Button block style={modalButtonStyle} onClick={() => setShowAddNewModal(true)}>
                                        <PlusOutlined /> Add New Agreement
                                    </Button>
                                </Form.Item>
                            </div>

                            <div style={{
                                position: "relative",
                                background: "white", padding: "20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
                            }}>

                                <Row style={{ display: "flex", alignItems: "center" }} gutter={12}>
                                    <Col span={8}>
                                        <Typography.Text style={{ fontSize: 12, fontWeight: "bold", color: "#464646", marginLeft: 20 }}>Agreements</Typography.Text>
                                    </Col>
                                    <Col span={4}>
                                        <Typography.Text style={{ fontSize: 12, fontWeight: "bold", color: "#464646", marginLeft: 20 }}>View / Upload</Typography.Text>
                                    </Col>
                                    <Col span={6}>
                                        <Typography.Text style={{ fontSize: 12, fontWeight: "bold", color: "#464646" }}>Validity <span style={{ color: "lightgray" }}>(optional)</span></Typography.Text>
                                    </Col>
                                    <Col span={6}>
                                        <Typography.Text style={{ fontSize: 12, fontWeight: "bold", color: "#464646" }}>Remarks</Typography.Text>
                                    </Col>
                                </Row>
                                {fields.map(({ key, name, ...restField }) => (

                                    <Row style={{ display: "flex", alignItems: "center", background: "#E6F2FD", borderRadius: 8, padding: 8, marginTop: 8 }} gutter={12}>

                                        <Col span={8}>

                                            {/* <Typography style={{ fontSize: 12 }}>{form.getFieldsValue()?.agreements?.[key]?.name}</Typography> */}
                                            <Form.Item
                                                {...restField}
                                                style={{ margin: 0, padding: 0 }}
                                                name={[name, 'name']}>
                                                <Input disabled={key < docTypes.filter((x: any) => x.name !== "MLA" && x.name !== "MFMS").length} placeholder="Enter name"
                                                    style={{ width: "100%", background: "transparent", color: "#404040", border: "none" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                style={{ margin: 0 }}
                                                {...restField}
                                                name={[name, 'file']}>
                                                <Upload
                                                    style={{ width: "100%", display: "block" }}
                                                    accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
                                                    customRequest={(file) => handleUploadRequest(file, name)}
                                                    beforeUpload={(file) => {
                                                        { handleUploadRequest({ file }, name) }
                                                        return false
                                                    }}
                                                    fileList={fileList.find((i: any) => i.key == name)?.files}
                                                    onRemove={(file) => handleDelete(file, name)}
                                                    listType="text"
                                                    multiple={true}
                                                    itemRender={(originNode, file, fileList2, actions) => <div style={{ display: "flex", justifyContent: "space-between", marginTop: 8 }}>
                                                        <div style={{ width: 100 }}>
                                                            <Typography.Text ellipsis>
                                                                <Tooltip title={file.name}>
                                                                    {file.name}
                                                                </Tooltip>
                                                            </Typography.Text>
                                                        </div>
                                                        <div style={{ display: "flex", gap: 10 }}>
                                                            {["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(file.type as string) ? <div onClick={() => {
                                                                const itemIndex = fileList2.findIndex((i) => i.uid === file.uid);
                                                                setPreviewFile({
                                                                    "fileName": file.name,
                                                                    "type": file.type as string,
                                                                    "url": fileList.find((i: any) => i.key == name)?.urls[itemIndex],
                                                                })
                                                            }} style={{ cursor: "pointer" }}>
                                                                <EyeOutlined />
                                                            </div> :
                                                                <EyeInvisibleFilled style={{ color: "grey" }} />
                                                            }

                                                            <Button disabled={uploadingAgreement == name} onClick={actions.remove} size="small" type="text" style={{ cursor: "pointer" }}>
                                                                <DeleteFilled style={{ color: "red" }} />
                                                            </Button>
                                                        </div>


                                                    </div>}
                                                >

                                                    <Button
                                                        type="link"
                                                        disabled={!!uploadingAgreement}
                                                        loading={uploadingAgreement == name}
                                                        // style={{ border: "1px solid rgba(44, 102, 227, 1)", color: "rgba(44, 102, 227, 1)" }}
                                                        icon={uploadingAgreement == name ? <Spin indicator={antIconUpload} /> : <UploadOutlined />}
                                                    >Upload</Button>
                                                </Upload>

                                            </Form.Item>
                                        </Col>
                                        <Col style={{ height: 34 }} span={6}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'validity']}>
                                                <DatePicker allowClear placeholder="Select Validity" style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col style={{ height: 34 }} span={docTypes.filter((x: any) => x.name !== "MLA" && x.name !== "MFMS")[name]?.documentTypeMaster.id == 6 ? 5 : 6}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'remarks']}>
                                                <Input placeholder="Enter Remarks" style={{ width: "100%" }} />
                                            </Form.Item>

                                        </Col>
                                        {docTypes.filter((x: any) => x.name !== "MLA" && x.name !== "MFMS")[name]?.documentTypeMaster.id == 6 &&
                                            <Col style={{ height: 34, display: "flex", justifyContent: "center" }} span={1}>
                                                {deletingIndex == name ? <SyncOutlined spin /> : <DeleteFilled style={{ color: "red" }} onClick={async () => {
                                                    setDeletingIndex(name)

                                                    await putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/cancelAgreementDoc?agreementDocIds=${docTypes?.filter((x: any) => x.name !== "MLA" && x.name !== "MFMS")[name].id}`, {}).then((res: any) => {
                                                        console.log(res)
                                                    })
                                                    getDocTypes()
                                                    setDeletingIndex(null)
                                                    setFileList((prev)=>prev.filter((i)=>i.key !== name))
                                                }} />}
                                            </Col>
                                        }
                                        {/* <Col span={8}>
                                            <DeleteFilled style={{ color: "red" }} onClick={() => remove(name)} />
                                        </Col> */}
                                    </Row>
                                ))}
                            </div>

                        </>
                    )}
                </Form.List>

                <Form.List name="spocRequests">
                    {(fields, { add, remove }) => (
                        <>

                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: 28 }}>
                                <div>
                                    <Typography.Text strong style={{ fontSize: 16 }}>Authorised Signatories</Typography.Text>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Form.Item style={{ margin: 0 }}>
                                        <Button block style={modalButtonStyle} onClick={() => add()}>
                                            <PlusOutlined /> Add New Signatory
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>

                            {fields.map(({ key, name, ...restField }) => (
                                <div style={{
                                    position: "relative",
                                    background: "white", padding: "20px 20px 0px 20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
                                }}>
                                    <Row gutter={12}>
                                        <Col span={6}>
                                            <Form.Item
                                                {...restField}
                                                rules={[{ required: true, message: 'Please input Name!' }]}
                                                name={[name, 'name']} label="Name">
                                                <Input placeholder="Enter Name" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <div style={{ width: "100%", display: othersToShow.includes(key) ? "flex" : "block", gap: 8 }}>
                                                <Form.Item
                                                    rules={[{ required: true, message: 'Please input designation!' }]}
                                                    name={[name, "designation"]} label="Designation">
                                                    <Select placeholder="Enter Designation" labelInValue onChange={(value) => {
                                                        if (value.label == "Other") {
                                                            if (!othersToShow.includes(key)) {
                                                                setOthersToShow([...othersToShow, key])
                                                            }
                                                        } else {
                                                            setOthersToShow(othersToShow.filter((i: any) => i !== key))
                                                        }
                                                    }
                                                    }>
                                                        {designations.map((i: any) => {
                                                            return <Select.Option value={i.id}>
                                                                {i.name}
                                                            </Select.Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                                {othersToShow.includes(key) && <Form.Item
                                                    rules={[{ required: true, message: 'Please input name!' }]}
                                                    style={{ flex: 1 }} name={[name, "designationName"]} label=" ">
                                                    <Input />
                                                </Form.Item>}
                                            </div>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                rules={[
                                                    { required: true, message: 'Please input Contact No.!' },
                                                    { pattern: /^[0-9]*$/, message: 'Only numbers are allowed!' },
                                                    { len: 10, message: 'Contact No. must be 10 digits!' }
                                                ]}
                                                name={[name, 'contactNumber']} label="Contact Number">
                                                <Input maxLength={10} placeholder="Enter Contact No." />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                {...restField}
                                                rules={[{
                                                    type: 'email', message: 'Please input valid email!'
                                                }]}
                                                name={[name, 'emailId']} label="Email">
                                                <Input placeholder="Enter Email" />
                                            </Form.Item>
                                        </Col>
                                        <div style={{ position: "absolute", right: 16, top: 10 }}>
                                            <DeleteFilled style={{ color: "red" }} onClick={() => remove(name)} />
                                        </div>
                                    </Row>
                                </div>
                            ))}

                        </>
                    )}
                </Form.List>


                <Typography.Text strong style={{ fontSize: 16, paddingTop: 28, display: "block" }}>Other Clauses</Typography.Text>
                <div style={{
                    background: "white", padding: "20px 20px 0px 20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)", marginTop: 20
                }}>
                    <Row gutter={12}>
                        <Col span={24}>
                            <Form.Item label="Any other Clauses" name="otherClause">
                                <Input.TextArea placeholder="Enter description for other clauses" rows={6} />
                            </Form.Item>
                        </Col>
                    </Row>

                </div>

                <div style={{ textAlign: "right", marginTop: 28 }}>
                    <Button loading={submitting} disabled={submitting} htmlType="submit" size="large" type="primary">Submit <RightOutlined /></Button>
                </div>

            </Form>

            <Modal footer={
                <div style={{ display: "flex", gap: 12, marginTop: 24 }}>
                    <Button size="large" style={{ ...modalButtonStyle, width: "100%" }} onClick={() => {
                        navigate('/dashboard/client-onboarding')
                        setShowSuccess(false)
                    }}>
                        <CloseOutlined /> Close
                    </Button>
                </div>
            }
                centered style={{ textAlign: "center" }}
                styles={{
                    footer: {
                        paddingInline: "10px",
                        marginBottom: 16
                    }
                }}
                closable={false} title={<div style={{ marginTop: 20 }}><img width={65} src={`${__dirname}assets/success-icon.png`} /></div>} open={showSuccess}>

                <Typography style={{ fontSize: 14 }}>Client Onboarded Successfully</Typography>

            </Modal>



            <Modal title="Agreement Name" footer={
                <div style={{ display: "flex", gap: 12, marginTop: 24 }}>
                    <Button loading={submittingNew} disabled={submittingNew || !newField} size="large" style={{ ...modalButtonStyle, width: "100%" }} onClick={handleAddNewDoc}>
                        Submit
                    </Button>
                </div>
            }
                centered style={{ textAlign: "center" }}
                styles={{
                    footer: {
                        paddingInline: "10px",
                        marginBottom: 16
                    },
                    header: {
                        textAlign: "left"
                    }
                }}
                onCancel={() => { setShowAddNewModal(false); setNewField("") }}
                closable={true}
                open={showAddNewModal}
                destroyOnClose
            >

                <Typography style={{ fontSize: 14, marginTop: 40 }}>
                    <Input placeholder="Enter agreement name" size="large" onChange={(e) => setNewField(e.target.value)} />
                </Typography>

            </Modal>
            <ViewDocumentModal setPreviewFile={setPreviewFile} previewFile={previewFile} />

        </>

    )
}