import { Badge, Col, Collapse, Divider, Input, Modal, Row, Skeleton, Space, Tag, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { getRequest } from "../../../utils/handler/apiHandler";
import { CaretRightOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import { useCases } from "./OnboardingDetail";
import ReadMoreText from "./ReadMoreText";
const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: "white",
    borderRadius: 8,
    boxShadow: "0px 4px 24px 0px #0000000D",
    border: 'none',
};

export default function VersionHistory({ setShowVersionHostory, showVersionHistory, clientOnBoardingId, prospectDetails }:any) {
    const [data, setData] = useState<any>([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const observer:any = useRef(null);

    useEffect(() => {
        getData(page);
    }, [page]);

    const getData = async (page:any) => {
        try {
            setLoading(true);
            const response = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/versionHistory?clientOnBoardingId=${clientOnBoardingId}&page=${page}`);
            const newData = response.data.data;
            setData((prevData:any) => [...prevData, ...newData]);
            setHasMore(newData.length > 0);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data", error);
            setLoading(false);
        }
    };

    const lastItemRef = useRef();
    useEffect(() => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        });

        if (lastItemRef.current) observer.current.observe(lastItemRef.current);
    }, [loading, hasMore]);

    return (
        <div style={{ textAlign: "left", height: 400, overflow: "scroll", paddingBottom: 100 }}>
            <Typography.Text strong style={{ marginBottom: 20, display: "block" }}>Version History</Typography.Text>

            <Collapse
                bordered={false}
                expandIcon={({ isActive }) => !isActive ? <div style={{ display: "flex", gap: 8, color: "#1777FF" }}><EyeOutlined /> <span>View Details</span></div> : <div style={{ display: "flex", gap: 8, color: "#1777FF" }}><EyeInvisibleOutlined /><span>Hide Details</span></div>}
                expandIconPosition="end"
                style={{ background: "#F6FAFF" }}
            >
                {data.map((item:any) => (
                    <Collapse.Panel 
                        key={item.logId} 
                        header={
                            <Typography.Text style={{ display: "block" }}>
                                Updated at: {moment(item?.createdAt).format('h:mm A, D MMM YYYY')} | Updated by: {item?.createdBy && JSON.parse(item?.createdBy)?.name}
                            </Typography.Text>
                        } 
                        style={panelStyle}
                    >
                        <HistoryDetails item={item} prospectDetails={prospectDetails} />
                    </Collapse.Panel>
                ))}
                {hasMore && data.length > 0 && (
                    <div ref={lastItemRef as any} style={{ height: 1 }} />
                )}
            </Collapse>
            {loading && <div>Loading more...</div>}
        </div>
    )
}

const HistoryDetails = ({ item, prospectDetails }: any) => {
    const changeDetails = item?.changeDetails !== "" ? JSON.parse(item?.changeDetails) : {}
    return (
        <div>
            <Typography.Text strong>Basic Details</Typography.Text>
            <Divider style={{ marginTop: 8, marginBottom: 16 }} />

            <Row style={{ marginBottom: 20 }} gutter={[0, 12]}>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Prospect</Typography.Text>
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text>{prospectDetails?.prospect?.prospectId} - {prospectDetails?.prospect.name}</Typography.Text>
                    </div>
                </Col>

                <Col span={6}>
                    <div>
                        <Typography.Text strong>Credit Limit</Typography.Text>
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text>₹ {prospectDetails?.creditLimit}</Typography.Text>
                    </div>
                </Col>

                <Col span={6}>
                    <div>
                        <Typography.Text strong>Business Segment</Typography.Text>
                        {changeDetails.hasOwnProperty('businessSegment') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('businessSegment') ? "#FFA800" : "black"}}>{item?.businessSegment?.name}</Typography.Text>
                    </div>
                </Col>

                <Col span={6}>
                    <div>
                        <Typography.Text strong>Entity Name</Typography.Text>
                        {changeDetails.hasOwnProperty('entityName') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('entityName') ? "#FFA800" : "black"}}>{item?.entityName}</Typography.Text>
                    </div>
                </Col>

                <Col span={6}>
                    <div>
                        <Typography.Text strong>Legal Business Name</Typography.Text>
                        {changeDetails.hasOwnProperty('legalBusinessName') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}

                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('legalBusinessName') ? "#FFA800" : "black"}}>{item?.legalBusinessName}</Typography.Text>
                    </div>
                </Col>

                <Col span={6}>
                    <div>
                        <Typography.Text strong>Primary Contact No</Typography.Text>
                        {changeDetails.hasOwnProperty('primaryContactNumber') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}

                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('primaryContactNumber') ? "#FFA800" : "black"}}>{item?.primaryContactNumber}</Typography.Text>
                    </div>
                </Col>

                <Col span={6}>
                    <div>
                        <Typography.Text strong>Primary Email ID</Typography.Text>
                        {changeDetails.hasOwnProperty('primaryEmailId') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('primaryEmailId') ? "#FFA800" : "black"}}>{item?.primaryEmailId}</Typography.Text>
                    </div>
                </Col>

                <Col span={6}>
                    <div>
                        <Typography.Text strong>Entity PAN no.</Typography.Text>
                        {changeDetails.hasOwnProperty('entityPan') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('entityPan') ? "#FFA800" : "black"}}>{item?.entityPan}</Typography.Text>
                    </div>
                </Col>


                <Col span={6}>
                    <div>
                        <Typography.Text strong>Entity CIN no.</Typography.Text>
                        {changeDetails.hasOwnProperty('entityCin') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('entityCin') ? "#FFA800" : "black"}}>{item?.entityCin}</Typography.Text>
                    </div>
                </Col>

            </Row>

            <Typography.Text strong>Contract Details</Typography.Text>
            <Divider style={{ marginTop: 8, marginBottom: 16 }} />

            <Row style={{ marginBottom: 20 }} gutter={[0, 12]}>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Contract No.</Typography.Text>
                        {changeDetails.hasOwnProperty('contractNumber') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('contractNumber') ? "#FFA800" : "black"}}>{item?.contractNumber}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Contract Execution Date</Typography.Text>
                        {changeDetails.hasOwnProperty('contractExecutionDate') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('contractExecutionDate') ? "#FFA800" : "black"}}>{item?.contractExecutionDate}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Contract Validity</Typography.Text>
                        {changeDetails.hasOwnProperty('contractValidity') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('contractValidity') ? "#FFA800" : "black"}}>{item?.contractValidity}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Term of Contract</Typography.Text>
                        {changeDetails.hasOwnProperty('termOfContract') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('termOfContract') ? "#FFA800" : "black"}}>{item?.termOfContract}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Agreement Type</Typography.Text>
                        {changeDetails.hasOwnProperty('agreementType') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('agreementType') ? "#FFA800" : "black"}}>{item?.agreementType}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Billing Frequency</Typography.Text>
                        {changeDetails.hasOwnProperty('billingType') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('billingType') ? "#FFA800" : "black"}}>{item?.billingType}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Primary Use Case</Typography.Text>
                        {changeDetails.hasOwnProperty('primaryUseCase') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('primaryUseCase') ? "#FFA800" : "black"}}>{(useCases.includes(item?.primaryUseCase) || item?.primaryUseCase==null)  ? "" : "Others - "}{item?.primaryUseCase}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Exit Clause</Typography.Text>
                        {changeDetails.hasOwnProperty('exitClause') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('exitClause') ? "#FFA800" : "black"}}>{item?.exitClause}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Lock In Period</Typography.Text>
                        {changeDetails.hasOwnProperty('lockInPeriod') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('lockInPeriod') ? "#FFA800" : "black"}}>{item?.lockInPeriod == "Managed" && "Applicable"}{item?.lockInPeriod == "Not Managed" && "Not Applicable"}</Typography.Text>
                    </div>
                </Col>
                {item?.lockInPeriod == "Managed" && <Col span={6}>
                    <div>
                        <Typography.Text strong>Lock In Period Months</Typography.Text>
                        {changeDetails.hasOwnProperty('lockInPeriodMonths') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('lockInPeriodMonths') ? "#FFA800" : "black"}}>{item?.lockInPeriodMonths}</Typography.Text>
                    </div>
                </Col>}
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Exit Clause Description</Typography.Text>
                        {changeDetails.hasOwnProperty('exitClauseDescription') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <ReadMoreText changed={changeDetails.hasOwnProperty('exitClauseDescription')} text={item?.exitClauseDescription} />
                </Col>
            </Row>

            <Typography.Text strong>Contracted Services</Typography.Text>
            <Divider style={{ marginTop: 8, marginBottom: 16 }} />

            <Row style={{ marginBottom: 20 }} gutter={[0, 12]}>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Road Tax.</Typography.Text>
                        {changeDetails.hasOwnProperty('roadTax') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('roadTax') ? "#FFA800" : "black"}}>{item?.roadTax}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Services</Typography.Text>
                        {changeDetails.hasOwnProperty('services') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('services') ? "#FFA800" : "black"}}>{item?.services}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Insurance</Typography.Text>
                        {changeDetails.hasOwnProperty('insurance') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('insurance') ? "#FFA800" : "black"}}>{item?.insurance}</Typography.Text>
                    </div>
                </Col>
            </Row>

            <Typography.Text strong>SPOC Detail</Typography.Text>
            <Divider style={{ marginTop: 8, marginBottom: 16 }} />

            <Row style={{ marginBottom: 20 }} gutter={[0, 12]}>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Foreclosure Penalty %</Typography.Text>
                        {changeDetails.hasOwnProperty('foreclosurePenalty') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('foreclosurePenalty') ? "#FFA800" : "black"}}>{item?.foreclosurePenalty}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Delay Payment Charges</Typography.Text>
                        {changeDetails.hasOwnProperty('delayPaymentChargesValue') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('delayPaymentChargesValue') ? "#FFA800" : "black"}}>{item?.delayPaymentType} - {item?.delayPaymentChargesValue}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Security Deposit Value</Typography.Text>
                        {changeDetails.hasOwnProperty('securityDepositCharges') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('securityDepositCharges') ? "#FFA800" : "black"}}>{item?.securityDepositType} - {item?.securityDepositCharges}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Down Payment Value (%)</Typography.Text>
                        {changeDetails.hasOwnProperty('downPaymentValue') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('downPaymentValue') ? "#FFA800" : "black"}}>{item?.downPaymentType} - {item?.downPaymentValue}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Processing Fees Value</Typography.Text>
                        {changeDetails.hasOwnProperty('processingFeesValue') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('processingFeesValue') ? "#FFA800" : "black"}}>{item?.processingFeesValue}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Buy Back</Typography.Text>
                        {changeDetails.hasOwnProperty('buyBack') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('buyBack') ? "#FFA800" : "black"}}>{item?.buyBack}</Typography.Text>
                    </div>
                </Col>
            </Row>

            <Typography.Text strong>Parent Entity Basic Details</Typography.Text>
            <Divider style={{ marginTop: 8, marginBottom: 16 }} />

            <Row style={{ marginBottom: 20 }} gutter={[0, 12]}>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Parent Entity Name</Typography.Text>
                        {changeDetails.hasOwnProperty('parentEntityName') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('parentEntityName') ? "#FFA800" : "black"}}>{item?.parentEntityName}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Parent Legal Business Name</Typography.Text>
                        {changeDetails.hasOwnProperty('parentLegalBusinessName') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('parentLegalBusinessName') ? "#FFA800" : "black"}}>{item?.parentLegalBusinessName}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Entity PAN no.</Typography.Text>
                        {changeDetails.hasOwnProperty('parentEntityPan') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('parentEntityPan') ? "#FFA800" : "black"}}>{item?.parentEntityPan}</Typography.Text>
                    </div>
                </Col>
                <Col span={6}>
                    <div>
                        <Typography.Text strong>Entity CIN no.</Typography.Text>
                        {changeDetails.hasOwnProperty('parentEntityCin') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Typography.Text style={{color: changeDetails.hasOwnProperty('parentEntityCin') ? "#FFA800" : "black"}}>{item?.parentEntityCin}</Typography.Text>
                    </div>
                </Col>

            </Row>

            <Row style={{ marginBottom: 20 }} gutter={[0, 12]}>
                <Col span={24}>
                    <div>
                        <Typography.Text strong>Other Clauses</Typography.Text>
                        {changeDetails.hasOwnProperty('otherClause') && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                    </div>
                    <div style={{ marginTop: 4 }}>
                        <Input.TextArea disabled style={{color: changeDetails.hasOwnProperty('otherClause') ? "#FFA800" : "black", background: "transparent", border: "none"}} rows={6} value={item?.otherClause} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}
// [
//     {
//       key: '1',
//       label: 'This is panel header 1',
//       children: <p>ola</p>,
//       style: panelStyle,
//     },
//     {
//       key: '2',
//       label: 'This is panel header 2',
//       children: <p>ola</p>,
//       style: panelStyle,
//     },
//     {
//       key: '3',
//       label: 'This is panel header 3',
//       children: <p>ola</p>,
//       style: panelStyle,
//     },
//   ]