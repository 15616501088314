export const getAllowedActions = (menus: any, pageUrl: string, subMenuUrl: string): Set<string> => {
    /* Get action item start */
    console.log(`in getAllowedActions menus=%o`, menus);
    const actionItem = new Set<string>();

    menus.forEach((menu: any) => {
        if (menu.menu === subMenuUrl) {
            const submenu = menu?.submenu;
            submenu.forEach((subMenu: any) => {
                if (subMenu.url === `/${pageUrl}`) {
                    const allowedActions = (subMenu?.allowedActions) ? JSON.parse(subMenu?.allowedActions) : [];
                    allowedActions.forEach((allowedAction: any) => actionItem.add(allowedAction));
                }
            });
        }
    });

    console.log(`returning action for pageUrl=%o, subMenuUrl=%o, actionItem=%o`, pageUrl, subMenuUrl, actionItem);
    return actionItem;
    /* Get action item end */
}

export const getAllAllowedActions = (menus: any): { [key: string]: string[] } => {
    const actionMap: { [key: string]: string[] } = {};
  
    menus.forEach((menu: any) => {
      const submenu = menu?.submenu;
      submenu.forEach((subMenu: any) => {
        const submenuName = subMenu.menuName; 
        const allowedActions = (subMenu?.allowedActions) ? JSON.parse(subMenu?.allowedActions) : [];
        
        if (!actionMap[submenuName]) {
          actionMap[submenuName] = []; 
        }
        actionMap[submenuName] = [...new Set([...actionMap[submenuName], ...allowedActions])]; 
      });
    });
    return actionMap;
  };
  