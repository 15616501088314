import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { keycloakConfig } from './utils/keycloak.config';
import { setAuth } from "./features/auth/authSlice";
import { setUser } from "./features/auth/userSlice";
import Login from './pages/Login';
import ProtectedRoute from './components/ProtectedRoute';
import DashboardLayout from './pages/Dashboard/DashboardLayout';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { authSelector } from './features/auth/authSlice'; 
import CreateProspect from './pages/Dashboard/Prospect/CreateProspect';
import CreateUser from './pages/Dashboard/IAM/CreateUser';
import ProspectsList from './pages/Dashboard/Prospect/ProspectList';
import EditProspect from "./pages/Dashboard/Prospect/ProspectPageLayout";
import EditUser from "./pages/Dashboard/IAM/EditUser";
import UserList from './pages/Dashboard/IAM/UserList';
import CreditCreditRequest from './pages/Dashboard/Credit/CreateCreditRequest';
import CreateQuote from './pages/Dashboard/Quote/CreateQuote';
import CreateRole from './pages/Dashboard/IAM/CreateRole';
import CreditRequestList from './pages/Dashboard/Credit/CreditRequestList';
import EditCreditRequest from './pages/Dashboard/Credit/EditCreditRequest';
import CreateCreditRequestApproval from './pages/Dashboard/Credit/CreditRequestApproval';
import CreateHPA from './pages/Dashboard/Vaahan/CreateHPA';
import VaahanHPTList from './pages/Dashboard/Vaahan/VaahanHPTList';
import CreateHPT from './pages/Dashboard/Vaahan/CreateHPT';
import VaahanHpaList from './pages/Dashboard/Vaahan/VaahanHpaList';
import FleetList from './pages/Dashboard/Asset/FleetList';
import CreateFleet from './pages/Dashboard/Asset/CreateFleet';
import { getRequest } from './utils/handler/apiHandler';
import { setMenu, setMenuActions } from './features/menu/menuSlice';
import SampleQuoteLayout from './pages/Dashboard/Quote/SampleQuoteList';
import RequestedQuote from './pages/Dashboard/Quote/components/QuoteDetailLayout';
import DocumentReviewRequest from './pages/Dashboard/Credit/DocumentReviewRequest';
import jwtDecode from 'jwt-decode';
import './styles/App.sass'
import axios from 'axios';
import CreditAssessmentRequest from './pages/Dashboard/Credit/CreditAssessmentRequest';
import RoleAssignmentPage from './pages/Dashboard/IAM/RBAC/RoleAssignmentPage';
import { getAllAllowedActions } from './utils/helpers/allowedAction';
import UploadRvRmty from './pages/Dashboard/MasterData/UploadRvRmty';
import RBACPageLayout from './pages/Dashboard/IAM/RBACPageLayout';

import ClientList from './pages/Dashboard/Client/ClientList';
import OnBoardNewClient from './pages/Dashboard/Client/OnboardNewClient';
import ClientDetails from './pages/Dashboard/Client/ClientDetails';

const { clientId, baseURL } = keycloakConfig;


function App() {
  const auth = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const page = useLocation().pathname.split('/').pop();
  const navigate = useNavigate();
  // Handle expired token logic
  let token: any = "";
  if(auth?.accessToken) 
    token = jwtDecode(auth?.accessToken);
  const timeStamp1 = token?.exp *1000;
  const timeStamp2 = Date.now();
  const signout = async () => {
    try {
      await axios.post(`${baseURL}/realms/GensolEvLease/protocol/openid-connect/logout`, {
        client_id: clientId,
        refresh_token: auth.refreshToken,
      },{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }).then(res => {
        if(res.status === 204) {
          dispatch(setUser({
            name: "",
            email: "",
            role: "",
            ssoId: "",
          }));
          dispatch(setAuth({
            isAdmin: false,
            isFinance: false,
            isAuth: false,
            accessToken: "",
            refreshToken: "",
            idToken: "",
          }));
        }
      });
    } catch (err) {
      console.log("Error: ", err);
    }
  }
  if(timeStamp1 < timeStamp2) {
    signout()
  }

  const getMenu = () => {
    getRequest(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/menus/newMenu`).then(res => {
      if (res.data) {
        dispatch(setMenu({ menu: res.data }));
        dispatch(setMenuActions(getAllAllowedActions(res.data))); 
      }
    });
  };

  useEffect(() => {
    getMenu();
  }, []);

  if(page === "display")
    window.location.replace("/dashboard/prospect");
  else if(page === "view-quote")
    window.location.replace("/dashboard/quote");
  else if(page === "credit-approval")
    window.location.replace("/dashboard/credit");

  return (
    <div className="App">
      { !navigator.onLine && <div className='online-status'>Offline</div> }
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<DashboardLayout/>}/>}>
          <Route path="" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<ProspectsList />}/>}/>
          <Route path="prospect" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<ProspectsList/>}/>}/>
          <Route path="prospect/create-prospect" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<CreateProspect />}/>}/>
          <Route path="prospect/:id" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<EditProspect />}/>}/>
          <Route path="users" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<UserList/>}/>}/>
          <Route path="users/:username" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<EditUser/>}/>}/>
          <Route path="add-role" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<CreateRole/>}/>}/>
          <Route path="users/create-user" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<CreateUser/>}/>}/>
          <Route path="quote" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<SampleQuoteLayout/>}/>}/>
          <Route path="quote/:id" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<RequestedQuote/>}/>}/>
          <Route path="quote/create-quote" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<CreateQuote />} />} />
          <Route path="credit" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<CreditRequestList />}/>}/>
          <Route path="credit/credit-request" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<CreditCreditRequest />}/>}/>
          <Route path="credit/:id" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<EditCreditRequest />}/>}/>
          <Route path="credit/credit-approval/:id" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<CreateCreditRequestApproval />}/>}/>
          <Route path="credit/credit-approval" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<CreditRequestList />}/>}/>
          <Route path="credit-document-review-request" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<DocumentReviewRequest />}/>}/>
          <Route path="credit-assessment-request" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<CreditAssessmentRequest />}/>}/>
          <Route path="asset" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<FleetList/>}/>}/>
          <Route path="asset/create-fleet" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<CreateFleet/>}/>}/>
          <Route path="vaahan-hpa" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<VaahanHpaList/>}/>}/>
          <Route path="vaahan-hpa/create-hpa" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<CreateHPA/>}/>}/>
          <Route path="vaahan-hpt" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<VaahanHPTList/>}/>}/>
          <Route path="vaahan-hpt/create-hpt" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<CreateHPT/>}/>}/>
          <Route path="rv-rmty" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<UploadRvRmty/>}/>}/>
          <Route path="rbac" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<RBACPageLayout/>}/>}/>

          <Route path="client-onboarding" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<ClientList />}/>}/>
          <Route path="client-onboarding/onboard-client" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<OnBoardNewClient />}/>}/>
          <Route path="client-onboarding/:id" element={<ProtectedRoute isAuthenticated={auth.isAuth} outlet={<ClientDetails />}/>}/>

          
          <Route path="*" element={<h5>404</h5>} />
        </Route>
        <Route path="*" element={<h1 style={{}}>404</h1>} />
      </Routes>
    </div>
  );
}

export default App;
