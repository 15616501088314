import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { authSelector, setAuth } from "../features/auth/authSlice";
import { keycloakConfig } from "../utils/keycloak.config";
import { useNavigate } from "react-router";
import Keycloak from 'keycloak-js';
import jwtDecode from "jwt-decode";
import '../styles/Pages.sass';
import { getRequest } from "../utils/handler/apiHandler";
import { menuSelector, setMenu, setMenuActions } from "../features/menu/menuSlice";
import { getAllAllowedActions } from "../utils/helpers/allowedAction";

const { baseURL, realms, clientId, responseType } = keycloakConfig;

function Login() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(authSelector);
  const navigate = useNavigate();

  let initOptions = {
    url: baseURL,
    realm: realms,
    clientId: clientId,
    onLoad: 'login-required', // check-sso | login-required
    KeycloakResponseType: responseType,
  }

  let kc = new Keycloak(initOptions);

  const login = () => {
    kc.init({
      onLoad: initOptions.onLoad,
      KeycloakResponseType: 'code',
      silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html", 
      checkLoginIframe: false,
      pkceMethod: 'S256'
    }).then((auth) => {
      if (!auth) {
        window.location.reload();
      } else {
        let token = jwtDecode(kc.token);
        let roles = token.realm_access.roles;
        let isAdmin = roles.includes("GEVL-ADMIN");
        let isFinance = roles.includes("GEVL-FINANCE");

        dispatch(setAuth({ accessToken: kc.token, refreshToken: kc.refreshToken, isAdmin, isFinance, isAuth: kc.authenticated}));
        getMenu();
      }
    }, () => {
      console.error("Authenticated Failed");
    });
  }

  const getMenu = () => {
    getRequest(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/menus/newMenu`).then(res => {
      if(res.data) {
        dispatch(setMenu({
          menu: res.data
        }));
        dispatch(setMenuActions(getAllAllowedActions(res.data))); 
        console.log(`res.data[0]=%o, res.data[0].menu=%o`,res.data[0],res.data[0].menu);
        navigate("/dashboard/" + res.data[0].menu);
      }
    })
  }
  const menu = useAppSelector(menuSelector).menu;
  useEffect(() => {
    if(auth.isAuth) {
      console.log(`menu=%o, menu[0]=%o`,menu, menu[0]);
      navigate("/dashboard/" + menu[0].menu); 
    }
    login();
  }, [])
  return (
    <></>
  )
}

export default Login