import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';

interface ItemDetail {
  id: string;
  name: string;
}

interface SelectSearchProps {
  name: any;
  options: any;
  placeholder: string;
  onSelect?: (clientId: string) => void; // Callback function to handle client selection
}

const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const SelectSearch: React.FC<SelectSearchProps> = ({ name, placeholder, options, onSelect }) => {
    const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
    
    useEffect(() => {
        // Make your API call here using selectedClientId
        if (onSelect && selectedId) {
          onSelect(selectedId);
        }
    }, [selectedId, onSelect]);
    
    const handleSelectChange = (value: string) => {
        setSelectedId(value)
    };

  return (
    <Form.Item
      name={name}
      noStyle
    >
      <Select
        style={{ width: '100%'}}
        showSearch
        placeholder={placeholder}
        optionFilterProp="children"
        filterOption={filterOption}
        options={options.map((item: ItemDetail) => ({
          value: item.id,
          label: item.name,
        }))}
        onChange={handleSelectChange}
      />
    </Form.Item>
  );
};

export default SelectSearch;
