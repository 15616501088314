// SubmenuSelect.tsx

import React from 'react';
import { Form, Select } from 'antd';
import { Submenu } from '../types';

const { Option } = Select;

interface SubmenuSelectProps {
  submenus: Submenu[];
  selectedSubmenu: string | null;
  onSubmenuChange: (submenuId: string) => void;
}
const filterOption = (input:any, option:any) => {
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const SubmenuSelect: React.FC<SubmenuSelectProps> = ({ submenus, selectedSubmenu, onSubmenuChange }) => {
  return (
  <Form.Item label="Select Submenu">
    <Select
      showSearch
      style={{ width: 300 }}
      placeholder="Select a Submenu"
      onChange={onSubmenuChange}
      value={selectedSubmenu || undefined}
      filterOption={filterOption} // Enable search functionality
    >
      {submenus.map(submenu => (
        <Option key={submenu.id} value={submenu.id}>
          {submenu.menuName}
        </Option>
      ))}
    </Select>
  </Form.Item>
  );
}

export default SubmenuSelect;
