import { EyeInvisibleFilled, EyeInvisibleOutlined, EyeOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Empty, Modal, Space, Typography } from "antd";

export default function ShowCertificates({setShowCertificates,showCertificates, prospectDetails, handleDownlaod, setPreviewFile}:any) {
    return <Modal
            destroyOnClose
            centered style={{ textAlign: "center" }}
            footer={null}
            onCancel={() => { setShowCertificates(null); }}
            closable={true}
            open={!!showCertificates}
            styles={{
                content: {
                    background: "#F6FAFF",
                },
                body: {
                    background: "#F6FAFF",
                    margin: 0,
                    padding: 0
                }
            }}
        >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: 20 }}>
                <div>
                    <Typography.Text strong>
                        View Certificates
                    </Typography.Text>
                </div>
            </div>
            <div style={{ maxHeight: 300, overflow: "scroll" }}>
                {prospectDetails?.addressDetails && showCertificates && JSON.parse('[' + prospectDetails?.addressDetails?.find((i: any) => i.id == showCertificates)?.url.slice(1, -1) + ']').map((url: any) => url.trim())?.length == 0 && <Empty />}
                {prospectDetails?.addressDetails && showCertificates && JSON.parse('[' + prospectDetails?.addressDetails?.find((i: any) => i.id == showCertificates)?.url.slice(1, -1) + ']').map((url: any) => url.trim())?.map((item: any) => (
                    <div style={{ background: "white", padding: 12, display: "flex", justifyContent: "space-between", borderRadius: 12, marginTop: 12, boxShadow: "0px 4px 24px 0px #0000000D", alignItems: "center" }}>
                        <div style={{ maxWidth: 150 }}>
                            <Typography.Text ellipsis>
                                {item.substring(item.lastIndexOf('/') + 1).replace(/%20/g, ' ')}
                            </Typography.Text>
                        </div>

                        <Space size="small">
                            <Button size="small" type="text" style={{ color: "#2C66E3" }} onClick={() => handleDownlaod(item)}><SaveOutlined /> Download</Button>
                            {["pdf", "jpeg", "png", "jpg"].includes(item.substring(item.lastIndexOf('/') + 1).replace(/%20/g, ' ').split('.').pop()) ? <Button onClick={() => setPreviewFile({
                                "fileName": item.substring(item.lastIndexOf('/') + 1).replace(/%20/g, ' '),
                                "type": item.substring(item.lastIndexOf('/') + 1).replace(/%20/g, ' ').split('.').pop(),
                                "url": item,
                            })} size="small" type="text" style={{ color: "#2C66E3" }}>
                                {["pdf", "jpeg", "png", "jpg"].includes(item.substring(item.lastIndexOf('/') + 1).replace(/%20/g, ' ').split('.').pop()) ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                <Typography.Text style={{ color: "#2C66E3" }}>Preview</Typography.Text></Button> : <div>
                                <EyeInvisibleFilled style={{ color: "grey" }} />
                            </div>}
                        </Space>
                    </div>
                ))}
            </div>
            <br />




        </Modal>
}