import { Modal } from "antd";

export default function ViewDocumentModal({previewFile,setPreviewFile}:any) {
    return <Modal
        destroyOnClose
        centered={false}
        open={!!previewFile}
        onCancel={() => setPreviewFile(null)}
        footer={null}
        width={"50%"}
        styles={{
            body: {
                display: "flex",
                justifyContent: "center",
                height: "60vh"
            }
        }}
    >
        <div style={{ width: "100%", minHeight: 150, overflow: "auto" }}>
            <div style={{ color: '#464646', fontWeight: '700', fontSize: '12px', padding: '1rem' }}>
                {previewFile?.fileName}
            </div>
            <iframe
                title={previewFile?.fileName}
                src={previewFile?.url}
                style={{
                    width: "100%",
                    height: "100%",
                    border: "none"
                }}

            />
        </div>

    </Modal>
}