import React, { useEffect, useState } from "react";
import { ReactComponent as NoDocuments } from '../../Prospect/utils/SVGs/NoDocuments.svg';
import { getRequest } from "../../../../utils/handler/apiHandler";
import { useLocation } from "react-router-dom";
import CreditAssessmentFormLayout from "./CreditAssessmentFormLayout";
import CreditAssessmentDetailsLayout from "./CreditAssessmentDetailsLayout";
import { Tag, Typography } from "antd";

interface CreditRequestDetailsProps {
    allowedActionsForProspect: Set<string>;
    stageId: number;
}
const CreditRequestDetails: React.FC<CreditRequestDetailsProps> = ({ allowedActionsForProspect, stageId }) => {
    const prospectId = useLocation().pathname.split("/").pop();
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        try {
            getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/prospectId?id=${prospectId}`).then(res => {
                setData(res.data);
            });
        } catch (err) {

        }
    }, []);
    return ( 
        <React.Fragment>
            { (data && (data?.creditRequestStatusMaster?.id === 1 || data?.creditRequestStatusMaster?.id === 8 || data?.creditRequestStatusMaster?.id === 7)) && <CreditAssessmentDetailsLayout allowedActionsForProspect={allowedActionsForProspect} /> }

            { (data && (data.creditRequestStatusMaster.id !== 1 && data?.creditRequestStatusMaster?.id !== 7 && data?.creditRequestStatusMaster?.id !== 8)) && <CreditAssessmentFormLayout view={false} allowedActionsForProspect={allowedActionsForProspect} /> }

            { !data &&
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <NoDocuments />
                <p style={{fontSize:'20px', fontWeight:'500', color:'#5C5C5C', marginLeft:'2rem'}}> No Credit Request Available </p>
            </div> }
        </React.Fragment>
     );
}

export default CreditRequestDetails;