import axios from "axios";
import { useState } from "react";
import type { UploadProps } from 'antd';
import { Col, message, Row, Tag, Upload } from 'antd';
import { Button, Modal, Spin, Typography } from "antd";
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { useAppSelector } from "../../../../app/hooks";
import { authSelector } from "../../../../features/auth/authSlice";
import { ReactComponent as UploadIcon } from '../../SVGs/upload_icon.svg';
import _ from 'lodash';

const { Dragger } = Upload;
const antIcon = <LoadingOutlined style={{ fontSize: 16, marginLeft: 6 }} spin />;

interface UploadModalProps {
    title: string;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    file: any;
    setFile: (file: any) => void;
    upload: () => void;
}

const UploadSanctionModal: React.FC<UploadModalProps> = ({ title, showModal, setShowModal, file, setFile, upload }) => {
    return (
        <Modal
            title={<Typography style={{background: "#F6FAFF", fontSize: 14, fontWeight: 500, color: "#404040"}}>{title}</Typography>}
            centered
            open={showModal}
            afterClose={() => setFile(null)}
            onOk={() => { setShowModal(false); setFile(null) }}
            onCancel={() => { setShowModal(false); setFile(null) }}
            closable={true}
            className="preview-modal"
            footer={<>
                <div className="edit-section">
                    <Button
                        ghost
                        type="primary"
                        disabled={_.isNull(file)}
                        className={_.isNull(file) ? "button-override disable" : "button-override primary"}
                        onClick={upload}
                        icon={<UploadIcon />}
                        >
                        Upload
                    </Button>
                </div>
            </>}
        >
            <Dragger
                maxCount={1}
                accept="pdf"
                fileList={file && [file]}
                // beforeUpload={(file) => { setFile(file); return false }}
                beforeUpload={(file) => { 
                    const allowedFileTypes = ['application/pdf', 'application/pdf']
                    setFile(file); 
                    const isPdf = allowedFileTypes.includes(file.type);
                    if (!isPdf) {
                        message.error('This file format is not accepted!');
                        setFile(null);
                    }
                    return isPdf || Upload.LIST_IGNORE;
                }}
            >
                <p className="ant-upload-drag-icon">
                <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                <Typography>Supported file format - <Tag style={{height: 22, background: "rgba(0, 0, 0, 0.02)", borderStyle: 'dashed',}}>pdf</Tag></Typography>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                </div>
                </p>
            </Dragger>
        </Modal>
    );
}

export default UploadSanctionModal;