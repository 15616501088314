import { Button, Checkbox, Input, Tooltip } from "antd";
import { useState } from "react";
import { getRequest } from "../../../utils/handler/apiHandler";
import debounce from 'lodash/debounce';
import { CheckOutlined } from "@ant-design/icons";

export default function FilterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters,fetchApiUrl,setBusinessNameOptions, width=240 }:any) {

    const [searchLoading, setSearchLoading] = useState(false);
    const [searchPage, setSearchPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    const handleSearch = (value: any) => {
        setSearchValue(value);
        debouncedSearch(value, 0); // Reset the page when a new search starts
    };

    const handlePopupScroll = (e: any) => {
        const target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            fetchBusinessNameOptions(searchValue, searchPage + 1); // Load more options
        }
    };

    const fetchBusinessNameOptions = async (name: string, page: number = 0) => {
        setSearchLoading(true);
        try {
            const api = fetchApiUrl(name, page)
            const res = await getRequest(api);
            if (res.status === 200) {
                setBusinessNameOptions((prev:any) => page === 0 ? res.data.data : [...prev, ...res.data.data]);
                setSearchPage(page);
            } else {
                console.error('Error fetching Legal Business Names:', res.msg);
            }
        } catch (error) {
            console.error('Error fetching Legal Business Names:', error);
        } finally {
            setSearchLoading(false);
        }
    };

    const debouncedSearch = debounce(fetchBusinessNameOptions, 500);


    return <div style={{ padding: 4 }}>
        <Input.Search loading={searchLoading} onSearch={handleSearch} />
        <div style={{ maxHeight: 200, width: width, overflow: "scroll", marginTop: 4 }} onScroll={handlePopupScroll}>
            {filters?.map((i:any, key:number) =>
                <div key={key} className="item-item">
                    <Checkbox
                        checked={selectedKeys.find((y:any) => y == i.value) ? true : false}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSelectedKeys([...selectedKeys, i.value as number])
                            } else {
                                setSelectedKeys(selectedKeys.filter((x:any) => x !== i.value))
                            }
                            console.log(e.target.checked)
                        }}
                        // checked={selectedItemsInternal.includes(item[searchKey])}
                        // onChange={() => handleItemSelection(item[searchKey] as string)}
                        className="custom-checkbox"
                    />
                    <Tooltip title={i?.text}>
                        <span style={{ marginLeft: '8px', maxWidth: 190, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {i.text}
                        </span>
                    </Tooltip>
                </div>
            )}
        </div>
        {filters.length > 0 && <div style={{ display: "flex", gap: 8, paddingTop:4 }}>

            <Button
                block
                onClick={() => { setSelectedKeys([]); clearFilters; confirm({ closeDropdown: true }); }}
                size="small"
            >
                Reset
            </Button>
            <Button
                block
                type="primary"
                size="small"
                onClick={() => {
                    confirm({ closeDropdown: true });
                }}
                icon={<CheckOutlined />}
            >
                Confirm
            </Button>

        </div>}
    </div>
}