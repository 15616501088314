export const titleCase = (str: string) => {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }
  export const titleCaseNew = (val: any) => {
    let str = val.split("_").join(" ");
    return str.toLowerCase().split(' ').map(function(word:string) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }