import { useState } from 'react';
import { message } from 'antd';
import { putRequest } from '../../../../../utils/handler/apiHandler';
import { MenuResponse } from '../types';

interface UseRoleHandlersProps {
  selectedRole: string | null;
  menuData: MenuResponse[];
  checkedActions: { [subMenuId: number]: { [action: string]: boolean } };
  initialCheckedActions: { [subMenuId: number]: { [action: string]: boolean } };
  setCheckedActions: React.Dispatch<React.SetStateAction<{ [subMenuId: number]: { [action: string]: boolean } }>>;
  setLoading: (isLoading:boolean) => void;
  fetchMenuData: (roleId: string) => void;
}

  const useRoleHandlers = ({
    selectedRole,
    menuData,
    checkedActions,
    initialCheckedActions,
    setCheckedActions,
    setLoading,
    fetchMenuData
  }: UseRoleHandlersProps) => {

  const handleCheckboxChange = (subMenuId: number, action: string, checked: boolean) => {
    setCheckedActions(prevState => ({
      ...prevState,
      [subMenuId]: {
        ...prevState[subMenuId],
        [action]: checked,
      }
    }));
  };

  const isUpdated = (selectedActions: string[], initialSelectedActions: string[]): boolean => {
    if (selectedActions.length !== initialSelectedActions.length) {
      return true;
    }
  
    // Sort both arrays
    const sortedSelectedActions = [...selectedActions].sort();
    const sortedInitialSelectedActions = [...initialSelectedActions].sort();
  
    // Compare sorted arrays
    for (let i = 0; i < sortedSelectedActions.length; i++) {
      if (sortedSelectedActions[i] !== sortedInitialSelectedActions[i]) {
        return true;
      }
    }
  
    return false;
  };

  const handleSubmit = async () => {
    if (!selectedRole) return;

    const assignMenus = menuData.reduce((acc, menuItem) => {
      for (const subMenu of menuItem.submenu) {
        const selectedActions = Object.keys(checkedActions[subMenu.id] || {}).filter(action => checkedActions[subMenu.id][action]);
        const initialSelectedActions = Object.keys(initialCheckedActions[subMenu.id] || {}).filter(action => initialCheckedActions[subMenu.id][action]);
    
        if (!isUpdated(selectedActions, initialSelectedActions)) {
          continue; // Skip this iteration and move to the next subMenu
        }
        
        // Include an empty array if no actions are selected
        const actions = selectedActions.length > 0 ? JSON.stringify(selectedActions) : JSON.stringify([]);
    
        const menuId = subMenu.parentMenu.id;
        if (!acc[menuId]) acc[menuId] = [];
        acc[menuId].push({
          id: subMenu.id,
          actions,
        });
      }
      return acc;
    }, {} as { [menuId: number]: { id: number; actions: string }[] });
    
    try {
      setLoading(true);
      const res = await putRequest(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/menus/updateMenusByRole`, {
        roleId: selectedRole,
        assignMenus,
      });
      if (res.status !== 200 && res.status !== 204) {
        message.error('Failed to update menus');
      } 
      fetchMenuData(selectedRole);
    } catch (error) {
      console.error('Error updating menus:', error);
      message.error('Error updating menus');
    } finally{
      setLoading(false);
    }
  };

  return {
    handleCheckboxChange,
    handleSubmit,
  };
};

export default useRoleHandlers;
