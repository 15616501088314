import { Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Documents from "./Documents";
import CreditRequestDetails from "../../Credit/components/CreditRequestDetails";
import { useLocation } from "react-router-dom";
import { getRequest } from "../../../../utils/handler/apiHandler";
import _ from "lodash";
import SanctionHistory from "../../Credit/components/CreditHistory";
import NoAccess from "../utils/NoAccess";

import { useAppSelector } from "../../../../app/hooks";
import { menuSelector } from "../../../../features/menu/menuSlice";

const TabPane = Tabs.TabPane;
const { Title } = Typography;

function CreditAssessment(props: any) {
    const query = new URLSearchParams(location.search);
    const subTab = query.get("subTab");
    const [segment, setSegment] = useState<any>("");
    const [creditRequestStatus, setCreditRequestStatus] = useState<any>(null);
    const [apiToggle, setApiToggle] = useState(false);
    const prospectId = useLocation().pathname.split("/").pop();
    const menuActions = useAppSelector(menuSelector).menuActions;
    const allowedActionsForCreditAssessmentList: string[] | undefined = menuActions?.['Credit Assessment'];
    const allowedActionsForCreditAssessment = new Set(allowedActionsForCreditAssessmentList);
    const allowedActionsForProspect = props.allowedActionsForProspect;

    useEffect(() => {
        try {
            getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/prospectId?id=${prospectId}`).then(res => {
                if(!_.isEmpty(res.data)) { 
                    setCreditRequestStatus(res.data?.creditRequestStatusMaster?.id);
                }
                else setCreditRequestStatus(0);
            });
        } catch (err) {
            console.log(err);
        }
    }, [apiToggle]);

    useEffect(() => {
        getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospect/${prospectId}`).then(res => {
            setSegment(res.data.segment.name)
        })
    }, []);

    if( !props.allowedActionsForProspect?.has('creditAssessmentTab') )  return <NoAccess/>;


    return (
        <React.Fragment>
            <Title style={{fontSize: 16}} level={5}>Credit Assessment</Title>
                <Tabs defaultActiveKey={subTab || "1"} className="sub-tab" onTabClick={(e) => setApiToggle(!apiToggle)}>
                    {
                        allowedActionsForProspect?.has('documentsSubTab') &&
                        <TabPane tab={<span className="sub-tab-heading">Documents</span>} key="1">
                        <Documents
                            stageId={props.stageId}
                            segment={segment}
                            creditRequestStatus={creditRequestStatus}
                            allowedActionsForProspect={props.allowedActionsForProspect}
                        />
                        </TabPane>
                    }
                    {
                        allowedActionsForProspect?.has('creditRequestSubTab') &&
                        <TabPane tab={<span className="sub-tab-heading">Credit Request</span>} key="2">
                            <CreditRequestDetails stageId={props.stageId} allowedActionsForProspect={props.allowedActionsForProspect} />
                        </TabPane>
                    }
                    {
                        allowedActionsForProspect?.has('creditHistorySubTab') &&
                        <TabPane tab={<span className="sub-tab-heading">Credit History</span>} key="3">
                            <SanctionHistory />
                        </TabPane>
                    }
                </Tabs>
        </React.Fragment>
    );
}

export default CreditAssessment;