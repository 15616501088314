// useRoleEffects.ts

import { useState, useEffect } from 'react';
import { getRequest } from '../../../../../utils/handler/apiHandler';
import { Role, MenuResponse } from '../types';

const useRoleEffects = (checkedActions: { [subMenuId: number]: { [action: string]: boolean } },
  setCheckedActions: React.Dispatch<React.SetStateAction<{ [subMenuId: number]: { [action: string]: boolean } }>>,
  setInitialCheckedActions: React.Dispatch<React.SetStateAction<{ [subMenuId: number]: { [action: string]: boolean } }>>
    , setLoading: (isLoading:boolean) => void
  ) => {
    const [roles, setRoles] = useState<Role[]>([]);
    const [menuData, setMenuData] = useState<MenuResponse[]>([]);
    
    useEffect(() => {
      const fetchRoles = async () => {
        try {
          const res = await getRequest(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/roles/excludeAdmin`);
          if (res.status === 404 || res.status === 400) {
            return;
          }
          setRoles(res.data);
        } catch (error) {
          console.error('Error fetching roles:', error);
        }
      };
  
      fetchRoles();
    }, []);
  
    const fetchMenuData = async (roleId: string) => {
      setLoading(true);
      try {
        const res = await getRequest(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/menus/allActions?roleId=${roleId}`);
        if (res.status === 404 || res.status === 400) {
          setMenuData([]);
          setLoading(false);
          return;
        }
        const menus = res.data;
        setMenuData(menus);
  
        // Initialize checkedActions based on allowedActions
        const initialCheckedActions = menus.reduce((acc:any, menuItem:any) => {
          menuItem.submenu.forEach((subMenu:any) => {
            try {
              const allowedActions = subMenu.allowedActions ? JSON.parse(subMenu.allowedActions) as string[] : []; 
              acc[subMenu.id] = allowedActions.reduce((actionsAcc, action) => ({
                ...actionsAcc,
                [action]: true,
              }), {});
            } catch (e) {
              console.error('Error parsing allowedActions:', e);
            }
          });
          return acc;
        }, {} as { [subMenuId: number]: { [action: string]: boolean } });
        setCheckedActions(initialCheckedActions);
        setInitialCheckedActions(initialCheckedActions);
      }  catch (error) {
        console.error('Error fetching menu data:', error);
        setMenuData([]);
      } finally {
        setLoading(false);
      }
    };
  
    return {
      roles,
      menuData,
      checkedActions,
      fetchMenuData,
    };
  };
  
  export default useRoleEffects;