import _, { isArray } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Input, InputNumber, Row, Select, Spin, Tooltip, Typography } from "antd";
import { postRequest } from "../../../../utils/handler/apiHandler";
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import FinalQuoteModal from "./FinalQuoteModal";
import { ReactComponent as ReloadIcon } from '../../SVGs/reload_icon.svg';
import { LoadingOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
const { Title } = Typography;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface RequestedQuoteDetailsProps {
    loader: boolean;
    details: any;
    form: any;
    data: any
    isEdit : boolean;
    setEditable: (editable: boolean) => void;
    allowedActionsForSampleQuote: Set<string>;
}

const QuotePriceDetails: React.FC<RequestedQuoteDetailsProps> = ({ loader, details, form, data, isEdit, 
    setEditable, allowedActionsForSampleQuote }) => {
    const [exShowroom, setExShowroom] = useState<any>(0);
    const [gstAmt, setGstAmt] = useState<any>(0);
    const [fameSubsidy, setFameSubsidy] = useState<any>(0);
    const [stateSubsidy, setStateSubsidy] = useState<any>(0);
    const [discount, setDiscount] = useState<any>(0);
    const [fabricationAmt, setFabricationAmt] = useState<any>(null);
    const [fabricationGST, setFabricationGST] = useState<any>(null);
    const [fabricationBaseAmt, setFabricationBaseAmt] = useState<any>(0);
    const [finalQuoteData, setFinalQuoteData] = useState<any>({});
    const [finalModal, setFinalModal] = useState<boolean>(false);
    const [accessoriesList, setAccessoriesList] = useState<any[]>([]);
    const [serviceList, setServiceList] = useState<any[]>([]);
    const [selectedAccessories, setSelectedAccessories] = useState<any[]>([]);
    const [selectedServices, setSelectedServices] = useState<any[]>([]);
    const [accessories, setAccessories] = useState<any>([]);
    const [services, setServices] = useState<any>([]);
    const [rmty, setRmty] = useState<any>(0);
    const [charging, setCharging] = useState<any>(0);
    const [replacementCar, setReplacementCar] = useState<any>(0);
    const [quoteDataLoader, setQuoteDataLoader] = useState<boolean>(false);
    const [firstYrInsuranceAmt, setFirstYrInsuranceAmt] = useState<any>(0);
    const firstRenderRefInsuranceAmount = useRef(true);
    const firstRenderRefBaseAmount = useRef(true);
    const firstRenderRefBaseAmountGst = useRef(true);

    useEffect(() => {
        try {

            if(isEdit){
                setRmty(getRmtyReplacementCarValue("RMTY"));
                setReplacementCar(getRmtyReplacementCarValue("Replacement Vehicle"));
                setCharging(getRmtyReplacementCarValue("Charging"));

                setFabricationAmt(form.getFieldValue("fabricationAmountTotal"));
                setFabricationGST(form.getFieldValue("fabricationGst"));
                setFabricationBaseAmt(form.getFieldValue("fabricationBaseAmountTotal"));
            }

            if(!isEdit){
                const tenureId = !isEdit ?  JSON.parse(data.tenure).id : JSON.parse(data.tenureInMonthDetail).id;
                const mileage = !isEdit ?  data.mileage : data.mileagePerMonth;
                postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/excessMileageByModel?modelId=${JSON.parse(data.model)["id"]}`, {}).then(res => {
                    form.setFieldsValue({
                        excessMileageCharge: res.data.excessMileageCharge
                    });
                });
                postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getRVPercent?tenureId=${tenureId}&makeId=${JSON.parse(data.make)["id"]}&modelId=${JSON.parse(data.model)["id"]}&mileage=${mileage}`, {}).then(res => {
                    form.setFieldsValue({
                        rvPercentage: res.data.rvPercent
                    });
                });
                postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/services`, {}).then(res => {
                    setServiceList(res.data);
                });
        
                if(data){
                    postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getServiceAmount?tenureId=${tenureId}&makeId=${JSON.parse(data.make)["id"]}&modelId=${JSON.parse(data.model)["id"]}&mileage=${mileage}&serviceId=${2}`, {}).then(res => {
                        setRmty(res.data.serviceAmt)
                    });
                }
        
        
                form.setFieldsValue({
                    otherAccessoriesDescription: details.otherAccessoriesDescription
                });
            }
            postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/accessories`, {}).then(res => {
                setAccessoriesList(res.data);
                if(data.accessories) {
                    const reqAccessories = JSON.parse(data.accessories);
                    const commonObjects = res.data.filter((obj1: any) => reqAccessories.some((obj2: any) => obj1.id === obj2.id));
                    let selected: any = []
                    commonObjects.map((e: any) => {
                        selected.push(e.name);
                    })
                    setSelectedAccessories([...selected]);
                    form.setFieldsValue({ accessories: selected,});
                    let input: any = [];
                    commonObjects.map((item: any) => {
                        input.push({ id: item.id, name: item.name, gst: null, totalAmount: null});
                    })
                    if(!isEdit){
                        setAccessories([...input]);
                    }
                }
            });
            setAccessories(!isEdit ? [] : (data.accessories ? JSON.parse(data.accessories) : []));
        } catch(err) {
            console.log(err)
        }
    }, []);



    useEffect(() => {
        if (firstRenderRefInsuranceAmount.current) {
            firstRenderRefInsuranceAmount.current = false;
            return;
          }
        const payload = {
            insuranceType: data.insuranceType,
            exShowroom: exShowroom,
            tenure: !isEdit ?  JSON.parse(data.tenure).name : JSON.parse(data.tenureInMonthDetail).name,
            fameSubsidy: fameSubsidy,
            stateSubsidy: stateSubsidy,
            registeredTo: data.registeredTo,
            isGreenCessApplicable: false,  
            firstYrInsuranceAmt: firstYrInsuranceAmt,
          }
        postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getInsuranceAmtByFirstYrInsAmt`, payload).then(res => {
            form.setFieldsValue({
                insuranceAmt: res.data.insuranceAmt
            });
        });
    }, [firstYrInsuranceAmt])

    useEffect(() => {
        if(data.services)
            postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/services`, {}).then(res => {
                setServiceList(res.data);
    
                const reqService = JSON.parse(data.services);
                const commonObjects = res.data.filter((obj1: any) => reqService.some((obj2: any) => obj1.id === obj2.id));
                let selected: any = []
                commonObjects.map((e: any) => {
                    selected.push(e.name);
                })
                setSelectedServices([...selected]);
                form.setFieldsValue({ services: selected,});
                let input: any = [];
                commonObjects.map((item: any) => {
                    if(item.id === 2)
                        input.push({ id: item.id, name: item.name, totalAmount: rmty || ""});
                    else if(item.id === 6)
                        input.push({ id: item.id, name: item.name, totalAmount: replacementCar || ""});
                    else if(item.id === 8)
                        input.push(!isEdit ?  { id: item.id, name: item.name, totalAmount: data.mileage|| ""} : { id: item.id, name: item.name, totalAmount: charging || ""});
                    else input.push(!isEdit ? { id: item.id, name: item.name, totalAmount: item.defaultAmount || ""} : { id: item.id, name: item.name, totalAmount: getPopulateValuesForServices(item.name) || ""});
                })
                setServices([...input]);
            });
    }, [rmty, replacementCar]);

    useEffect(() => {
        if (firstRenderRefBaseAmount.current) {
            firstRenderRefBaseAmount.current = false;
            return;
          }
        const vehicleId = JSON.parse(data.vehicle)["id"];
        const insurancePayload = {
            insuranceType: data.insuranceType,
            exShowroom: exShowroom,
            tenure: !isEdit ?  JSON.parse(data.tenure).name : JSON.parse(data.tenureInMonthDetail).name,
            fameSubsidy: fameSubsidy,
            stateSubsidy: stateSubsidy,
            registeredTo: data.registeredTo,
            isGreenCessApplicable: false,
            discount: discount,
        }
        if(exShowroom) {
            postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getInsuranceAmt`, insurancePayload).then(res => {
                form.setFieldsValue({
                    insuranceAmt: res.data.insuranceAmt,
                    firstYrInsuranceAmt: res.data.firstYrInsuranceAmt
                });
            });
    
            postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getBaseGSTPrice?amt=${exShowroom || 0}`, {}).then(res => {
                form.setFieldsValue({
                    baseAmt: res.data.baseAmt,
                    gstAmt: res.data.gstAmt
                });
            });
            postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getTcs?amt=${exShowroom || 0}`, {}).then(res => {
                form.setFieldsValue({
                    tcs: res.data.tcsAmt
                });
            });
            postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/managementFeeByVehicleType?amt=${exShowroom || 0}&vehType=${vehicleId}`, {}).then(res => {
                form.setFieldsValue({
                    managementFee: res.data.managementFee
                });
            });
        } else {
            form.setFieldsValue({
                baseAmt: 0,
                gstAmt: 0
            });
        }

        if(data){
            const tenureId = !isEdit ?  JSON.parse(data.tenure).id : JSON.parse(data.tenureInMonthDetail).id;
            const mileage = !isEdit ?  data.mileage : data.mileagePerMonth;
            postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getServiceAmountByType?tenureId=${tenureId}&mileage=${mileage}&serviceId=${6}&exShowRmPrice=${exShowroom || 0}`, {}).then(res => {
                setReplacementCar(res.data.serviceAmt)
            });
        }


    }, [exShowroom]);

    useEffect(() => {
    if (firstRenderRefBaseAmountGst.current) {
            firstRenderRefBaseAmountGst.current = false;
            return;
        }
    postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/calBaseAmt?exshowAmt=${exShowroom}&gstAmt=${~~gstAmt}`, {}).then(res => {
        form.setFieldsValue({
            baseAmt: res.data.baseAmt
        });
    });
    }, [gstAmt]);

    useEffect(() => {
    const payload = {
        insuranceType: data.insuranceType,
        exShowroom: exShowroom,
        tenure: !isEdit ?  JSON.parse(data.tenure).name : JSON.parse(data.tenureInMonthDetail).name,
        fameSubsidy: fameSubsidy,
        stateSubsidy: stateSubsidy,
        registeredTo: data.registeredTo,
        isGreenCessApplicable: false,
        discount: discount,
    }
    if(exShowroom)
    postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getInsuranceAmt`, payload).then(res => {
        form.setFieldsValue({
            insuranceAmt: res?.data?.insuranceAmt,
            firstYrInsuranceAmt: res?.data?.firstYrInsuranceAmt
        });
    });
    }, [fameSubsidy, stateSubsidy, discount]);

    useEffect(() => {
    if(fabricationAmt === null) {
        setFabricationBaseAmt(null);
        form.setFieldsValue({
            fabrication: null,
            fabricationGst: null
        });
    }
    else
    postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getAmtByPercent?amt=${fabricationAmt || 0}&percent=${fabricationGST || 0}`, {}).then(res => {
    setFabricationBaseAmt(res.data.amt);
    form.setFieldsValue({
        fabrication: fabricationAmt || null,
        fabricationGst: fabricationGST || null
    });
    });
    }, [fabricationAmt, fabricationGST]);

    const optionsToRender = (options: any[], identifier: any) => {
        if(isArray(options))
        return options?.map((value: any) => (
            <Option key={value.id} value={value[identifier]}>
            {value?.[identifier]}
            </Option>
        ));
    };

    const handleAccessoriesChange = (event: any, index: any) => {
        let data = [...accessories];
        data[index][event.target.name] = event.target.value;
        data[index][event.target.gst] = event.target.gst;
        postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/getAmtByPercent?amt=${~~data[index]["totalAmount"]}&percent=${~~data[index]["gst"]}`, {}).then(res => {
            data[index]["baseAmount"] = res.data.amt;
            setAccessories(data);
        });
    }

    const handleRemoveAccessories = (v: any, e: any) => {
        setAccessories([...accessories.filter((obj: any) => obj.id !== ~~e.key)]); 
        setSelectedAccessories([...selectedAccessories.filter((item: any) => item !== e.value)])
    }

    const handleAccessoriesInput = (v: any, e: any) => {
        setAccessories([...accessories, { id: ~~e.key, name: e.value, gst: null, totalAmount: null}])
    }

    const handleServicesChange = (event: any, index: any) => {
        let data = [...services];
        data[index][event.target.name] = event.target.value;
        setServices(data);
    }

    const handleServiceInput = (event: any, v: any) => {
        setSelectedServices([...selectedServices, v]);
        const filter = serviceList.filter((item: any) => item.id === ~~event.key);
        if(~~event.key === 2)
            setServices([...services, { id: ~~event.key, name: event.value, totalAmount: ~~rmty}]);
        else if(~~event.key === 6)
            setServices([...services, { id: ~~event.key, name: event.value, totalAmount: ~~replacementCar}]);
        else if(~~event.key === 8)
            setServices([...services, { id: ~~event.key, name: event.value, totalAmount: !isEdit ? data.mileage : data.mileagePerMonth}]);
        else setServices([...services, { id: ~~event.key, name: event.value, totalAmount: ~~filter[0].defaultAmount}]);
    }

    const handleRemoveService = (v: any, e: any) => {
        setServices([...services.filter((obj: any) => obj.id !== ~~e.key)]); 
        setSelectedServices([...selectedServices.filter((item: any) => item !== e.value)])
    }

    const getRmtyReplacementCarValue = (name : string) => {
        const servicesList  = JSON.parse(data?.services);
        for(let i =0; i<servicesList.length; i++){
            if(servicesList[i].name === name && name === "RMTY") return servicesList[i].value;
            if(servicesList[i].name === name && name === "Replacement Vehicle") return servicesList[i].inMonth;
            if(servicesList[i].name === name && name === "Charging") return servicesList[i].inMonth;
        }
    }

    const getPopulateValuesForServices = (name : string) => {
        const servicesList  = JSON.parse(data?.services);
        for(let i =0; i<servicesList.length; i++){
            if(servicesList[i].name === name) return servicesList[i].inMonth;
        }
    }

    const finalFormData = async () => {
        try {
            await form.validateFields(["exShowroom", "gstAmt", "roadTax", "managementFee", "rvPercentage", "costOfFund", "marginRate", "excessMileageCharge"]);
            setQuoteDataLoader(true);

            const formData = form.getFieldsValue();
            const payload = {
                tenureInMonth: !isEdit ?  JSON.parse(data.tenure).name : JSON.parse(data.tenureInMonthDetail).name,
                mileage: data.mileage,
                roadTaxType: data.roadTaxType,
                exShowroom: formData.exShowroom,
                fameSubsidy: formData.fameSubsidy || 0,
                stateSubsidy: formData.stateSubsidy || 0,
                discount: formData.discount || 0,
                roadTax: formData.roadTax,
                insuranceType: data.insuranceType,
                firstYrInsuranceAmt: formData.firstYrInsuranceAmt,
                registeredTo: data.registeredTo,
                managementFee: formData.managementFee,
                fabrication: fabricationAmt === null ? null : fabricationAmt,
                fabricationGst: fabricationAmt === null && fabricationGST === null ? null : fabricationGST === null ? 0 : fabricationGST,
                services: services,
                accessories: accessories,
                paymentType: data.paymentType,
                isGreenCessApplicable: false,
                costOfFund: formData.costOfFund,
                offerRate: formData.marginRate,
                rvPercentage: formData.rvPercentage,
                tcs: formData.tcs,
                gstOnVehicle: formData.gstAmt,
            }
            postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/quoteCalculator`, payload).then(res => {
                setFinalModal(true);
                setQuoteDataLoader(false);
                let quoteData: any = {...res.data};

                let formatAccessories: any = [];
                accessories && accessories.map((e: any, id: any) => {
                    let filterData = quoteData.accessories.filter((obj: any) => {
                        if(obj.name === e.name) { 
                            formatAccessories[id] = { ...{id: e.id}, ...obj,
                            inMonth: obj.baseAmountInMonth,
                            value: obj.totalAmount
                        }
                        delete formatAccessories[id]["baseAmountInMonth"];
                        }
                    });           
                });
                let formatServices: any = [];
                services && services.map((e: any, id: any) => {
                    let filterData = quoteData.services.filter((obj: any) => obj.name === e.name);
                    formatServices[id] = { ...{id: e.id}, ...filterData[0], inMonth: filterData[0].inMonthAmount, value: filterData[0].totalAmount };
                    delete formatServices[id].totalAmount;
                    delete formatServices[id].inMonthAmount;
                })
                quoteData["accessories"] = formatAccessories;
                quoteData["services"] = formatServices;
                setFinalQuoteData(quoteData);
            });
        } catch(err) {
            console.log("Error: ", err);
        }
    }

    useEffect(() => {
        console.log(accessories)
    }, [accessories]);

    return (
        <>
        <Title style={{marginTop: 30, fontWeight: 700}} level={5}>Price Details</Title>
        <Card bordered={false} style={{marginTop: 20}}>
            <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            >
                <Col>
                    <Row style={{display: "flex", gap: 10}}>
                        <Form.Item
                            name="exShowroom"
                            label={<Typography style={{fontSize: 14, fontWeight: 500}}>Ex-Showroom Price</Typography>}
                            style={{width: "32%"}}
                            rules={[{ required: true, message: 'Please enter ex-showroom price!' }]}
                        >
                            <InputNumber
                                placeholder="Ex-Showroom Price"
                                onChange={(value)=> setExShowroom(value)} 
                                className="input-number-override"
                                style={{width: "100%"}}
                            /> 
                        </Form.Item>
                        <Form.Item
                            name="baseAmt"
                            label={<Typography style={{fontSize: 14, fontWeight: 500}}>Base Price</Typography>}
                            style={{width: "32%"}}
                            rules={[{ required: true, message: 'Please enter base price!' }]}
                        >
                            <InputNumber
                                disabled={true}
                                placeholder="Base Price"
                                className="input-number-override"
                                style={{width: "100%"}}
                            /> 
                        </Form.Item>
                        <Form.Item
                            name="gstAmt"
                            label={<Typography style={{fontSize: 14, fontWeight: 500}}>GST @ 5%</Typography>}
                            style={{width: "32%"}}
                            rules={[{ required: true, message: 'Please enter GST @ 5%!' }]}
                        >
                            <InputNumber
                                placeholder="GST @ 5%"
                                onChange={(value)=> setGstAmt(value)} 
                                className="input-number-override"
                                style={{width: "100%"}}
                            /> 
                        </Form.Item>
                    </Row>

                    <Row style={{display: "flex", gap: 10}}>
                        <Form.Item
                            name="fameSubsidy"
                            label={<span style={{fontWeight: 500}}>Fame Subsidy <span className='optional-label'>(Optional)</span></span>}
                            style={{width: "32%"}}
                        >
                            <InputNumber
                                placeholder="Fame Subsidy"
                                onChange={(value)=> setFameSubsidy(value)} 
                                className="input-number-override"
                                style={{width: "100%"}}
                            /> 
                        </Form.Item>
                        <Form.Item
                            name="stateSubsidy"
                            label={<span style={{fontWeight: 500}}>State Subsidy <span className='optional-label'>(Optional)</span></span>}
                            style={{width: "32%"}}
                        >
                            <InputNumber
                                placeholder="State Subsidy"
                                className="input-number-override"
                                onChange={(value)=> setStateSubsidy(value)}
                                style={{width: "100%"}}
                            /> 
                        </Form.Item>
                        <Form.Item
                            name="discount"
                            label={<span style={{fontWeight: 500}}>Discount <span className='optional-label'>(Optional)</span></span>}
                            style={{width: "32%"}}
                        >
                            <InputNumber
                                placeholder="Discount"
                                onChange={(value)=> setDiscount(value)} 
                                className="input-number-override"
                                style={{width: "100%"}}
                            /> 
                        </Form.Item>
                    </Row>

                    <Row style={{display: "flex", gap: 10}}>
                        <Form.Item
                            name="roadTax"
                            label={<span style={{fontWeight: 500}}>Road Tax Amount</span>}
                            style={{width: "32%"}}
                            rules={[{ required: details.roadTaxType === "Not Managed" ? false : true , message: 'Please enter road tax amount!' }]}
                        >
                            <InputNumber
                                disabled={details.roadTaxType === "Not Managed"}
                                placeholder="Road Tax Amount"
                                className="input-number-override"
                                style={{width: "100%"}}
                            /> 
                        </Form.Item>
                        <Form.Item
                            name="insuranceAmt"
                            label={<span style={{fontWeight: 500}}>Insurance Amount</span>}
                            style={{width: "32%"}}
                            rules={[{ required: true, message: 'Please enter insurance amount!' }]}
                        >
                            <InputNumber
                                disabled={true}
                                placeholder="Insurance Amount"
                                className="input-number-override"
                                style={{width: "100%"}}
                            /> 
                        </Form.Item>
                        <Form.Item
                            name="firstYrInsuranceAmt"
                            label={<span style={{fontWeight: 500}}>First Year Insurance Premium <span className='optional-label'>(Optional)</span></span>}
                            style={{width: "32%"}}
                            rules={[{ required: true, message: 'Please enter first year insurance premium!' }]}
                        >
                            <InputNumber
                                disabled={details.insuranceType === "Not Managed"}
                                placeholder="First Year Insurance Premium"
                                onChange={(value) => setFirstYrInsuranceAmt(value)} 
                                className="input-number-override"
                                style={{width: "100%"}}
                            /> 
                        </Form.Item>
                    </Row>

                    <Row style={{display: "flex", gap: 10}}>
                        
                        <Form.Item
                            name="fabrication"
                            label={<span style={{fontWeight: 500}}>Fabrication Amount <span className='optional-label'>(Optional)</span></span>}
                            style={{width: "65%"}}
                            rules={[{ required: true, message: 'Please enter fabrication amount!' }]}
                        >
                            <div style={{display: "flex", gap: 10}}>
                            <InputNumber
                                placeholder="Fabrication Amount"
                                value={fabricationAmt}
                                onChange={(value)=> setFabricationAmt(value)}
                                className="input-number-override"
                                style={{width: "51%"}}
                            /> 
                            <InputNumber
                                placeholder="GST"
                                value={fabricationGST}
                                onChange={(value)=> setFabricationGST(value)}
                                className="input-number-override"
                                style={{width: "24%"}}
                            /> 
                            <InputNumber
                                disabled={true}
                                value={fabricationBaseAmt}
                                placeholder="Base Amount"
                                className="input-number-override"
                                style={{width: "24%"}}
                            /> 
                            </div>
                        </Form.Item>
                    </Row>

                    <Row style={{display: "flex", flexDirection: "column", flexWrap: "nowrap", gap: 10}}>
                        <div style={{display: "flex", gap: 10}}>
                            <div style={{width: "32%"}}>
                        <Form.Item
                            name="accessories"
                            label={<span style={{fontWeight: 500}}>Accessories <span className='optional-label'>(Optional)</span></span>}
                            style={{width: "100%"}}
                        >
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                value={selectedAccessories}
                                className="select-input-override"
                                placeholder="Accessories"
                                onSelect={(v, e) => handleAccessoriesInput(v, e) }
                                onDeselect={(v, e) => { handleRemoveAccessories(v, e)  }}
                            >
                                {optionsToRender(accessoriesList, "name")}
                            </Select>
                        </Form.Item>
                        <form id="accessoriesForm">
                            {accessories.map((item: any, index: any) => {

                            return (
                                <div key={index} style={{marginBottom: 10, maxWidth: "100%"}}>
                                    <div>{item.name}</div>
                                    <div style={{display: "flex"}}>
                                    <Input
                                        name='totalAmount'
                                        type="number"
                                        placeholder='Price'
                                        style={{marginRight: 4}}
                                        onChange={event => handleAccessoriesChange(event, index)}
                                        value={item.totalAmount}
                                    />
                                    <Input
                                        name='gst'
                                        type="number"
                                        placeholder='GST'
                                        style={{marginRight: 4}}
                                        onChange={event => handleAccessoriesChange(event, index)}
                                        value={item.gst}
                                    />
                                    <Input
                                        disabled={true}
                                        name='bastAmount'
                                        type="number"
                                        placeholder='Base Amount'
                                        style={{marginRight: 4}}
                                        onChange={event => handleAccessoriesChange(event, index)}
                                        value={item.baseAmount}
                                    />
                                    </div>
                                </div>
                            )
                            })}
                        </form>
                        </div>
                        {
                        accessories.some((obj: any) => obj.id === 5) &&
                        <Form.Item
                            name="otherAccessoriesDescription"
                            label={<span style={{fontWeight: 500}}>Other Accessories Description</span>}
                            style={{width: "32%"}}
                        >
                            <Input
                                className="input-override"
                                placeholder='Other Accessories'
                                onChange={(e) => form.setFieldValue("otherAccessoriesDescription", e.target.value)}
                            />
                        </Form.Item>
                        }
                        </div>
                    </Row>
                </Col>    
            </Form>
        </Card>

        <Title style={{marginTop: 30, fontWeight: 700}} level={5}>Service Details</Title>
        <Card bordered={false} style={{marginTop: 20}}>
            <Form
            form={form}
            layout="vertical"
            onKeyDown={(e)=> e.key == "Enter" ? e.preventDefault(): ''}
            requiredMark={false}
            >
                <Col>
                    <Row style={{display: "flex", gap: 10}}>


                        <Form.Item
                            name="services"
                            label={<span style={{fontWeight: 500}}>Services <span className='optional-label'>(Optional)</span></span>}
                            style={{width: "32%"}}
                            rules={[{ required: true, message: 'Please enter service!' }]}
                        >
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                value={selectedServices}
                                placeholder="Services"
                                className="select-input-override"
                                onSelect={(v, e) => { handleServiceInput(e, v) }}
                                onDeselect={(v, e) => { handleRemoveService(v, e) }}
                            >
                                {optionsToRender(serviceList, "name")}
                            </Select>
                            <form>
                                {services.map((item: any, index: any) => {
                                return (
                                    <div key={index} style={{marginTop: 10}}>
                                        <div>{item.name}</div>
                                        <div style={{display: "flex"}}>
                                        <Input
                                            name='totalAmount'
                                            placeholder='Price'
                                            style={{marginRight: 4}}
                                            onChange={event => handleServicesChange(event, index)}
                                            value={item.totalAmount}
                                        />
                                        </div>
                                    </div>
                                )
                                })}
                            </form>
                        </Form.Item>

                        <Form.Item
                            name="managementFee"
                            label={<span style={{fontWeight: 500}}>Management Fees</span>}
                            style={{width: "32%"}}
                            rules={[{ required: true, message: 'Please enter Management Fees!' }]}
                        >
                            <InputNumber
                                placeholder="Management Fees"
                                className="input-number-override"
                                style={{width: "100%"}}
                            /> 
                        </Form.Item>
                        <Form.Item
                            name="tcs"
                            label={<span style={{fontWeight: 500}}>TCS <span className='optional-label'>(Optional)</span></span>}
                            style={{width: "32%"}}
                            rules={[{ required: true, message: 'Please enter TCS!' }]}
                        >
                            <InputNumber
                                placeholder="TCS"
                                className="input-number-override"
                                style={{width: "100%"}}
                            /> 
                        </Form.Item>
                    </Row>

                    <Row style={{display: "flex", gap: 10}}>
                        <Form.Item
                            name="rvPercentage"
                            label={<span style={{fontWeight: 500}}>RV %</span>}
                            style={{width: "32%"}}
                            rules={[{ required: true, message: 'Please enter RV %!' }]}
                        >
                            <InputNumber
                                placeholder="RV %"
                                className="input-number-override"
                                style={{width: "100%"}}
                            /> 
                        </Form.Item>
                        <Form.Item
                            name="costOfFund"
                            label={<span style={{fontWeight: 500}}>Cost of Fund %</span>}
                            style={{width: "32%"}}
                            rules={[{ required: true, message: 'Please enter cost of fund %!' }]}
                        >
                            <InputNumber
                                placeholder="Cost of Fund %"
                                className="input-number-override"
                                style={{width: "100%"}}
                            /> 
                        </Form.Item>
                        <Form.Item
                            name="marginRate"
                            label={<span style={{fontWeight: 500}}>Margin Rate %</span>}
                            style={{width: "32%"}}
                            rules={[{ required: true, message: 'Please enter margin rate %!' }]}
                        >
                            <InputNumber
                                placeholder="Margin Rate %"
                                className="input-number-override"
                                style={{width: "100%"}}
                            /> 
                        </Form.Item>
                    </Row>

                    <Row style={{display: "flex", gap: 10}}>
                        <Form.Item
                            name="excessMileageCharge"
                            label={<span style={{fontWeight: 500}}>Excess Mileage Charge(Per KM)</span>}
                            style={{width: "32%"}}
                            rules={[{ required: true, message: 'Please enter excess mileage charge(per KM)!' }]}
                        >
                            <InputNumber
                                placeholder="Excess Mileage Charge(Per KM)"
                                className="input-number-override"
                                style={{width: "100%"}}
                            /> 
                        </Form.Item>
                    </Row>
                </Col>    
            </Form>
        </Card>
        <Title style={{marginTop: 30, fontWeight: 700}} level={5}>Quote Remark <span className='optional-label'>(Optional)</span></Title>
        <Card bordered={false} style={{marginTop: 20}}>
            <Form
            form={form}
            layout="vertical"
            onKeyDown={(e)=> e.key == "Enter" ? e.preventDefault(): ''}
            requiredMark={false}
            >
                <Col>
                    <Row style={{display: "flex", gap: 10}}>
                        <Form.Item
                            name="quoteRemarks"
                            style={{width: "50%"}}
                            rules={[{ required: false, message: 'Please enter quote remark!' }]}
                        >
                            <TextArea rows={2}  placeholder="Quote Remark" />
                        </Form.Item>
                    </Row>
                </Col>    
            </Form>
        </Card>
        <div style={{marginTop: 20, display: "flex", justifyContent: "flex-end"}}>
          {
            ((details?.quoteId === null && details?.quoteStatusId === 4) || (details?.quoteId)) && <div>
                {
                    isEdit && 
                    <Button
                        danger
                        ghost
                        icon={<CrossIcon />}
                        onClick={(_) => {
                            setEditable(false);
                            }}
                        className="button-override danger"
                        style={{ display: isEdit ? "" : "none" }}
                    >
                        Cancel
                    </Button>
                }
                <Button
                    ghost
                    danger
                    type="primary"
                    onClick={_ => { 
                        form.resetFields(["exShowroom", "gstAmt", "roadTax", "managementFee", "costOfFund", "marginRate", "tcs", "fabrication", "firstYrInsuranceAmt", "insuranceAmt", "roadTax", "roadTaxType", "discount", "stateSubsidy", "fameSubsidy", "gstAmt", "baseAmt", "quoteRemarks"])
                    }}
                    icon={<ReloadIcon />}
                    style={{marginLeft: 10}}
                    className="button-override danger"
                >Reset</Button>
                <Button
                    type="primary"
                    onClick={_ => {finalFormData()}}
                    style={{marginLeft: 10}}
                    className="button-override"
                >Proceed { quoteDataLoader ? <Spin indicator={antIcon} /> : <RightArrowIcon style={{marginLeft: 10}}/> }</Button>
            </div>
            }
        </div>
        <FinalQuoteModal data={finalQuoteData} requestData={data} showModal={finalModal} 
            formData={form.getFieldsValue(true)} isEdit={isEdit} setShowModal={setFinalModal} 
            allowedActionsForSampleQuote={allowedActionsForSampleQuote}/>
        </>
    );
}

export default QuotePriceDetails;