import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface Menu {
  menu: any[];
  menuActions: { [key: string]: string[] }; // Changed to an object with string array values
}

// Update this interface to reflect the new payload structure for setMenu
interface SetMenuPayload {
  menu: any[];
}

const initialState: Menu = {
  menu: [],
  menuActions: {}, // Initialize as an empty object
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenu: (state, action: PayloadAction<SetMenuPayload>) => {
      state.menu = action.payload.menu; // Access menu from the payload
    },
    setMenuActions: (state, action: PayloadAction<{ [key: string]: string[] }>) => {
      state.menuActions = action.payload; // Accept object with string arrays
    },
  },
});

export const { setMenu, setMenuActions } = menuSlice.actions;
export const menuSelector = (state: RootState) => state.menu;
export default menuSlice.reducer;
