import React from 'react';
import ProspectBasicDetails from './ProspectBasicDetails';
import ProspectRequirement from './ProspectRequirement';
import NoAccess from '../utils/NoAccess';

function ProspectDetail(props: any) {
    if( !props.allowedActionsForProspect?.has('viewProspect') )  return <NoAccess/>;


    return ( 
        <React.Fragment>
            <div className="container-inner">
                <div className="container-inner-section">
                    <ProspectBasicDetails allowedActionsForProspect={props.allowedActionsForProspect} disableEditOrAddSection={props.disableEditOrAddSection} setDisableEditOrAddSection={props.setDisableEditOrAddSection} />
                </div>
                <div className="container-inner-section">
                    <ProspectRequirement allowedActionsForProspect={props.allowedActionsForProspect} disableEditOrAddSection={props.disableEditOrAddSection} setDisableEditOrAddSection={props.setDisableEditOrAddSection} stageId={props.stageId} prospectName={props.prospectName} />
                </div>
            </div>
        </React.Fragment>
    );
}

export default ProspectDetail;